import { ActivityProject } from "../entity";
import useStorage from "./useStorage";
import { SELECTED_ACTIVITY_PROJECT } from "../config/access-keys";
import { useRecoilState } from "recoil";
import { selectedActivityProjectAtom } from "../store/atoms";
import { useEffect } from "react";
import { SelectedActivityProjectProps } from "../store";

const getIncludeAttribute = (
  value: ActivityProject
): SelectedActivityProjectProps => {
  return {
    id: value.id,
    title: value.title,
    currentPrice: value.current_price,
    originPrice: value.origin_price,
    thumbnailUrl: value.thumbnail_url,
  };
};

const useActivityProjectOperation = (): [
  SelectedActivityProjectProps[],
  (value: ActivityProject) => void,
  (id: number) => void,
  VoidFunction
] => {
  const [valueStorage, setValueStorage] = useStorage<
    SelectedActivityProjectProps[]
  >(SELECTED_ACTIVITY_PROJECT, []);
  const [value, setValue] = useRecoilState(selectedActivityProjectAtom);

  const add = (o: ActivityProject) => {
    const item = getIncludeAttribute(o);
    if (!value.includes(item)) {
      setValue([...value, item]);
      setValueStorage([...value, item]);
    }
  };

  const remove = (id: number) => {
    const it = value.filter((v) => v.id !== id);
    setValue(it);
    setValueStorage(it);
  };

  const clear = () => {
    setValue([]);
    setValueStorage([]);
  };

  useEffect(() => {
    setValue(valueStorage);
  }, []);

  return [value, add, remove, clear];
};

export default useActivityProjectOperation;
