import { FC } from "react";
import { Form, NoticeBar, Space, Toast, Typography } from "react-vant";
import Input from "react-vant/es/input/Input";
import { LoginFormValues } from "../../request";
import Button from "react-vant/es/button/Button";
import GetMessageCode from "../investor-account/GetCode";
import { Volume } from "@react-vant/icons";
import { useRequest } from "ahooks";
import { recover } from "../../requests/investor-account";
import { useNavigate } from "react-router-dom";

const VipLogin: FC = () => {
  const [form] = Form.useForm<LoginFormValues>();
  const navigate = useNavigate();
  const request = useRequest(recover, { manual: true });

  const handleRecover = (values: LoginFormValues) => {
    request.runAsync(values).then((response) => {
      if (response.success) {
        Toast.clear();
        navigate("/investor-account/refresh/result");
      } else Toast.fail(response.message);
    });
  };

  const onFinish = (values: LoginFormValues) => {
    Toast.loading("正在处理");
    handleRecover(values);
  };

  return (
    <>
      <NoticeBar
        leftIcon={<Volume />}
        wrapable
        scrollable={false}
        text={
          "因微信公众号迁移，导致部分用户信息显示异常，若您遇到此问题，请按以下提示进行操作。"
        }
      />
      <Space direction={"vertical"} gap={[0, 0]} className={"py-5 px-3"}>
        <Typography.Title level={3} className={"mb-0"}>
          账户信息更新
        </Typography.Title>
        <Typography.Text type={"secondary"}>
          修复微信公众号迁移账户异常问题
        </Typography.Text>
      </Space>
      <Form
        form={form}
        onFinish={onFinish}
        footer={
          <div className={"p-5"}>
            <Button
              disabled={request.loading}
              nativeType={"submit"}
              size={"large"}
              type={"primary"}
            >
              更新账户
            </Button>
          </div>
        }
      >
        <Form.Item
          name="mobile"
          size={"large"}
          label={"预留手机号"}
          rules={[
            { required: true, message: "请输入预留手机号" },
            { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确" },
          ]}
        >
          <Input
            type="number"
            maxLength={11}
            placeholder={"请输入预留手机号"}
          />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "请输入验证码" },
            { len: 4, message: "短信验证码不正确" },
          ]}
          name="code"
          size={"large"}
          label="验证码"
          suffix={<GetMessageCode form={form} />}
        >
          <Input placeholder="输入验证码" />
        </Form.Item>
      </Form>
      <Typography.Text className={"flex p-5"} type={"secondary"}>
        注意：本操作仅适用于文体汇会员用户，可修复您的钱包账户、支付订单、车位信息、礼品兑换以及其他问题。请务必填写您的预留手机号。
      </Typography.Text>
    </>
  );
};

export default VipLogin;
