import svgIcon from "../../assets/svg";
import { CellProps } from "react-vant/es/cell";

const {
  UserMenuCoupon,
  UserMenuDhm,
  UserMenuKabao,
  UserMenuSecure,
  UserMenuService,
  UserMenuWallet,
  UserMenuOrder,
} = svgIcon;

interface MenuProps extends CellProps {
  link: string;
  tel?: boolean;
}

const menus: MenuProps[][] = [
  [
    {
      title: "全部订单",
      icon: UserMenuOrder,
      link: "/user/orders",
    },
    {
      title: "现金券",
      icon: UserMenuCoupon,
      link: "/promotion-reward",
    },
    {
      title: "福利兑换",
      icon: UserMenuDhm,
      link: "/user/weekend-presents",
    },
  ],
  [
    {
      title: "我的钱包",
      icon: UserMenuWallet,
      link: "/user/wallet",
      value: ":wallet",
    },
    {
      title: "卡包",
      icon: UserMenuKabao,
      link: "/user/kabao",
    },
    {
      title: "账户安全",
      icon: UserMenuSecure,
      link: "/user/security",
    },
  ],
  [
    {
      title: "客服帮助",
      icon: UserMenuService,
      tel: true,
      link: "tel://15377090776",
    },
  ],
];

export default menus;
