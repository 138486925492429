import { ActionSheet } from "react-vant";
import { ActionSheetAction } from "react-vant/es/action-sheet/PropsType";
import { useNavigate } from "react-router-dom";
import { ParkingCar } from "../../../entity";
import { useRequest } from "ahooks";
import { destroy } from "../../../requests/parking-car";

export default function ParkingCarSheet({
  open,
  onClose,
  onDelete,
  row,
}: {
  row: ParkingCar | undefined;
  open: boolean;
  onClose: VoidFunction;
  onDelete: VoidFunction;
}): JSX.Element {
  const navigate = useNavigate();
  const deleteRequest = useRequest(() => destroy(row?.id || 0), {
    manual: true,
  });
  const actions: ActionSheetAction[] = [
    {
      name: "去缴费",
      callback: () => navigate(`/parking/fee/${row?.plate_no}`),
      className: "text-lg text-blue-500",
    },
    {
      name: "解绑",
      callback: () => {
        deleteRequest.run();
        onClose();
        onDelete();
      },
    },
  ];
  return (
    <ActionSheet
      visible={open}
      closeOnClickAction
      actions={actions}
      onCancel={onClose}
      cancelText="取消"
    />
  );
}
