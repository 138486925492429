import Poster from "../../assets/images/activity-sale-poster.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useStorage from "../../hooks/useStorage";
import { Share_Code } from "../../config/access-keys";

const ActivitySalePoster = () => {
  const [searchParams] = useSearchParams();
  const [, setShareCode] = useStorage<string | null>(Share_Code);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setShareCode(searchParams.get("share_code"));
  }, [searchParams]);

  return (
    <img
      src={Poster}
      className={"align-middle"}
      onClick={() => {
        navigate("/activity-sale/entry/" + params.activitySaleId);
      }}
    />
  );
};

export default ActivitySalePoster;
