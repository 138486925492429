import { FC, useEffect, useState } from "react";
import { VoteParticipator } from "../../../entity";
import { data } from "autoprefixer";
import { useRequest } from "ahooks";
import { getRankList } from "../../../requests/vote-participator";
import { useNavigate, useParams } from "react-router-dom";
import Card, { CardHeader } from "react-vant/es/card/Card";
import { CellGroup } from "react-vant/es/cell";
import Cell from "react-vant/es/cell/Cell";
import { Empty, Image, Skeleton, Space, Typography } from "react-vant";
import { isEmpty, uniqueId } from "lodash";
import WinnerFirst from "../../../assets/images/vote_winner_first.png";
import WinnerSecond from "../../../assets/images/vote_winner_second.png";
import WinnerThird from "../../../assets/images/vote_winner_third.png";

const RankListContent: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rankList, setRankList] = useState<VoteParticipator[]>([]);
  const rankListRequest = useRequest(getRankList, {
    manual: true,
  });

  const fetchRankList = (id: string) => {
    rankListRequest.runAsync(id).then((response) => {
      if (data) setRankList(response);
    });
  };

  const rankPrefix = (index: number) => {
    switch (index) {
      case 0:
        return <Image src={WinnerFirst} width={35} />;
      case 1:
        return <Image src={WinnerSecond} width={35} />;
      case 2:
        return <Image src={WinnerThird} width={35} />;
      default:
        return (
          <div style={{ width: 35 }} className={"text-center"}>
            <Typography.Text
              className={"inline-block rounded bg-red-50 text-red-500"}
              size={"md"}
              style={{ width: 25 }}
            >
              {index + 1}
            </Typography.Text>
          </div>
        );
    }
  };

  useEffect(() => {
    if (id) {
      fetchRankList(id);
    }
  }, [id]);

  if (rankListRequest.loading)
    return (
      <Card round className={"mx-3 mt-3"}>
        <Skeleton row={1} rowHeight={30} round={false} className={"my-3"} />
        {new Array(10).fill(null).map(() => (
          <Skeleton key={uniqueId()} avatar row={1} className={"mb-3"} />
        ))}
      </Card>
    );

  return (
    <Card round className={"mx-3 mt-3"}>
      <CardHeader border className={"justify-center"}>
        排行榜
      </CardHeader>
      <CellGroup style={{ minHeight: 500 }} border={false} className={"pb-3"}>
        {isEmpty(rankList) ? (
          <Empty description={"暂无参赛人员~"} />
        ) : (
          rankList.map((participator, index) => (
            <Cell
              key={participator.id}
              size={"large"}
              center
              onClick={() =>
                navigate(`/vote/${id}/participator/${participator.id}`)
              }
              icon={
                <Space gap={[0, 15]}>
                  {rankPrefix(index)}
                  <Image
                    src={participator.photos[0]}
                    radius={3}
                    width={55}
                    height={55}
                  />
                </Space>
              }
              label={`${participator.identity}号`}
              title={participator.username}
              border={false}
              value={
                <Typography.Text className={"text-red-600 text-lg"}>
                  {participator.receive_vote} 票
                </Typography.Text>
              }
            />
          ))
        )}
      </CellGroup>
    </Card>
  );
};

export default RankListContent;
