import { Contact } from "../entity";
import request from "../utils/request";
import { ContactFormValues, Response } from "../request";

const ROUTE = "contacts";

export function getList(): Promise<Contact[]> {
  return request().get(ROUTE);
}

export function create(data: ContactFormValues): Promise<Response> {
  return request().post(ROUTE, data);
}

export function update(id: number, data: ContactFormValues): Promise<Response> {
  return request().put(`${ROUTE}/${id}`, data);
}

export function destroy(id: number): Promise<Response> {
  return request().delete(`${ROUTE}/${id}`);
}
