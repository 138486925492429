import { FormInstance, Toast, Typography } from "react-vant";
import config from "../../config/config";
import { useCountDown } from "react-vant/es/hooks";
import { useRequest } from "ahooks";
import { getMessageCode } from "../../requests/auth";
import { FC } from "react";
import { isNil } from "lodash";

const GetMessageCode: FC<{ form: FormInstance }> = ({ form }) => {
  const { start, reset, current } = useCountDown({
    time: config.messageInterval,
  });
  const messageRequest = useRequest(getMessageCode, {
    manual: true,
    throttleWait: 1000,
  });

  const handleRefreshSeconds = () => {
    if (!messageRequest.loading) handleGetCode();
  };

  const handleGetCode = () => {
    const mobile = form.getFieldValue("mobile");
    if (isNil(mobile))
      return Toast({
        message: "请输入手机号",
        position: "bottom",
      });
    messageRequest.runAsync(mobile).then((response) => {
      if (response.success) {
        reset(config.messageInterval);
        start();
      }
    });
  };

  return current.seconds > 0 ? (
    <Typography.Text disabled type="secondary">
      {current.seconds}s
    </Typography.Text>
  ) : (
    <Typography.Text onClick={handleRefreshSeconds}>获取验证码</Typography.Text>
  );
};

export default GetMessageCode;
