import { useRequest } from "ahooks";
import { Button, Flex, Loading, Space, Typography } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import { getList } from "../../../../requests/parking-car";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Plus } from "@react-vant/icons";
import ParkingCarList from "./ParkingCarList";
import { useRecoilState } from "recoil";
import { parkingCarAtom } from "../../../../store/atoms";
import { isEmpty } from "lodash";

export default function ParkingCarSection({
  onSelected,
}: {
  onSelected: (value: string) => void;
}): JSX.Element {
  const navigate = useNavigate();
  const [parkingCarList, setParkingCarList] = useRecoilState(parkingCarAtom);
  const fetchRequest = useRequest(getList, {
    manual: true,
    onSuccess: (data) => setParkingCarList(data),
  });
  useEffect(() => {
    if (isEmpty(parkingCarList)) fetchRequest.run();
  }, []);
  return (
    <Space direction="vertical" block>
      <Flex justify="between">
        <FlexItem>
          <Typography.Title level={5}>常用车牌</Typography.Title>
        </FlexItem>
        <FlexItem>
          <Typography.Text
            onClick={() => {
              navigate("/parking/car");
            }}
            type="secondary"
            className="font-bold"
          >
            管理
          </Typography.Text>
        </FlexItem>
      </Flex>
      {fetchRequest.loading ? (
        <Loading size={35} style={{ minHeight: 50 }} className="ml-3" />
      ) : (
        <Flex gutter={10} style={{ minHeight: 50 }}>
          {parkingCarList.length > 0 ? (
            <ParkingCarList list={parkingCarList} onSelected={onSelected} />
          ) : (
            <FlexItem span={8}>
              <Button
                plain
                block
                icon={<Plus />}
                onClick={() => navigate("/Parking/car")}
              >
                添加
              </Button>
            </FlexItem>
          )}
        </Flex>
      )}
    </Space>
  );
}
