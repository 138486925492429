import { useState } from "react";
import { Button, Space, Typography } from "react-vant";
import LicenseInputControl from "../_home/LicenseInputControl";

export default function ParkingCarLicenseInput({
  onSubmit,
}: {
  onSubmit: (value: string) => void;
}): JSX.Element {
  const [license, setLicense] = useState("");
  const [keyboard, setKeyboard] = useState(false);
  return (
    <>
      <Space direction="vertical" block gap={20} className="mb-10">
        <Typography.Text size="lg" className="font-bold">
          请输入有效车牌
        </Typography.Text>
        <LicenseInputControl
          openKeyboard={keyboard}
          setOpenKeyboard={setKeyboard}
          input={license}
          setInput={setLicense}
        />
      </Space>
      <div className="w-full mx-auto">
        <Button
          onClick={() => {
            setKeyboard(false);
            onSubmit(license);
          }}
          disabled={license.length < 7}
          type="primary"
          size="large"
          className="mb-5"
          block
        >
          保存
        </Button>
      </div>
    </>
  );
}
