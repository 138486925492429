import { Card, Flex, Image, Tag, Typography } from "react-vant";
import { CardBody, CardFooter, CardHeader } from "react-vant/es/card/Card";
import FlexItem from "react-vant/es/flex/FlexItem";
import { useNavigate } from "react-router-dom";
import { UserWeekendPresent } from "../../../entity";
import svgIcon from "../../../assets/svg";

export default function WeekendPresentEntity({
  isRecord,
  userPresent,
}: {
  isRecord?: boolean;
  userPresent: UserWeekendPresent;
}): JSX.Element {
  const navigate = useNavigate();
  const { StateTagOk } = svgIcon;

  return (
    <Card
      round
      onClick={() => navigate(`/user/weekend-presents/view/${userPresent.id}`)}
      className={isRecord ? `mb-2 opacity-60` : "mb-2"}
    >
      <CardHeader
        border
        extra={
          !isRecord && (
            <Tag plain size="mini" type="warning">
              未领取
            </Tag>
          )
        }
      >
        <Flex align="center" gutter={10}>
          <FlexItem>{userPresent.title}</FlexItem>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex align="center" gutter={20} className="pt-3">
          <FlexItem>
            <Image src={userPresent.thumbnail_url} width={50} height={50} />
          </FlexItem>
          <FlexItem>
            <Flex direction="column" gutter={[0, 5]}>
              <FlexItem>
                <Typography.Text>
                  提供商户: {userPresent.merchant_name}
                </Typography.Text>
              </FlexItem>
              <FlexItem>
                <Typography.Text>
                  有效期: {userPresent.valid_from} 至 {userPresent.valid_to}
                </Typography.Text>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
        {isRecord && userPresent.used_at && (
          <Image
            className={"-rotate-6"}
            src={StateTagOk}
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              zIndex: 1000,
              right: 5,
              bottom: 5,
            }}
            height={70}
            width={70}
          />
        )}
      </CardBody>
      <CardFooter />
    </Card>
  );
}
