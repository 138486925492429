import {
  Button,
  Card,
  Input,
  Form,
  Radio,
  Space,
  Toast,
  Typography,
} from "react-vant";
import { RadioGroup } from "react-vant/es/radio";
import { Gender } from "../../utils/enum";
import { ContactFormValues } from "../../request";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { useRequest } from "ahooks";
import { create, update } from "../../requests/contact";
import { getUser } from "../../utils/storage";
import { Contact } from "../../entity";
import { isNil } from "lodash";
import { Plus } from "@react-vant/icons";
import { useState } from "react";

export default function ContactForm({
  row,
  onSubmit,
}: {
  row?: Contact | undefined;
  onSubmit: (values: ContactFormValues) => void;
}): JSX.Element {
  const createRequest = useRequest(create, { manual: true });
  const updateRequest = useRequest(update, { manual: true });
  const [loading, setLoading] = useState(false);
  const { mobile } = getUser();
  const { male, female } = Gender;
  const defaultValues: ContactFormValues = {
    username: row?.username || "",
    gender: row?.gender || Gender.male,
    mobile: row?.mobile || mobile || "",
  };
  const [form] = Form.useForm();
  const onFinished = async (values: ContactFormValues) => {
    setLoading(true);
    const run = () =>
      isNil(row)
        ? createRequest.runAsync(values)
        : updateRequest.runAsync(row.id, values);
    try {
      const data = await run();
      if (data.success) onSubmit(values);
      else
        Toast({
          message: data.errors?.first || data.message,
          position: "bottom",
        });
    } catch (e: any) {
      Toast({ message: e.message, position: "bottom" });
    } finally {
      setTimeout(() => setLoading(false), 500);
    }
  };
  return (
    <Card>
      <CardHeader>常用联系人</CardHeader>
      <CardBody>
        <Form
          initialValues={defaultValues}
          showValidateMessage={false}
          form={form}
          onFinish={onFinished}
          footer={
            <Button
              loading={loading}
              disabled={loading}
              type="primary"
              block
              className="mt-10"
              round
              onClick={form.submit}
            >
              <Space align="center">
                <Plus />
                <Typography.Text type="light">
                  {row ? "保存" : "添加"}
                </Typography.Text>
              </Space>
            </Button>
          }
        >
          <Form.Item
            label="称呼"
            name="username"
            rules={[{ required: true, message: "请填写您的称呼" }]}
          >
            <Input placeholder="请填写您的称呼" clearable />
          </Form.Item>
          <Form.Item label="性别" name="gender">
            <RadioGroup direction="horizontal">
              <Radio name={male} className="mb-0">
                先生
              </Radio>
              <Radio name={female}>女士</Radio>
            </RadioGroup>
          </Form.Item>
          <Form.Item
            name="mobile"
            label="手机号"
            required
            rules={[
              { required: true, message: "请输入您的手机号" },
              { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确" },
            ]}
          >
            <Input
              type="number"
              maxLength={11}
              placeholder="请填写您的手机号"
            />
          </Form.Item>
        </Form>
      </CardBody>
    </Card>
  );
}
