import { Card, Cell, Divider, Image, Space, Typography } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { ShopO } from "@react-vant/icons";
import { implodedTags } from "../../../utils/util";
import { MerchantProject } from "../../../entity";
import { CellGroup } from "react-vant/es/cell";

export default function MerchantProjectViewHead({
  merchantProject,
}: {
  merchantProject: MerchantProject;
}): JSX.Element {
  return (
    <Card>
      <CardBody>
        <Space align="center" block gap={2}>
          <ShopO fontSize={20} />
          <Typography.Text>
            {merchantProject.merchant.merchant_name}
          </Typography.Text>
        </Space>
        <Typography.Title level={2} className="my-2 text-black">
          {merchantProject.title}
        </Typography.Title>
        <div className="flex justify-between mb-3">
          <div className="divide-x">
            {merchantProject &&
              implodedTags(merchantProject.tags).map((tag, index) => (
                <Typography.Text
                  key={tag}
                  className={index < 1 ? "px-2 pl-0" : "px-2"}
                >
                  {tag}
                </Typography.Text>
              ))}
          </div>
        </div>
        <Image width="100%" radius={10} src={merchantProject.banner_url} />
        <Divider className="my-3" />
        <CellGroup border={false}>
          {/*          <Cell
            border={false}
            title={`有效时间：${merchantProject.activity_project.available_in.join(
              "至"
            )}`}
            className="px-0 pb-1 py-0"
          />*/}
          <Cell
            title={`使用地址：武汉市江岸区兴业路59号文体汇${merchantProject.merchant.address}`}
            className="px-0 py-1"
          />
        </CellGroup>
      </CardBody>
    </Card>
  );
}
