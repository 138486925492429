import { Button, Space, Typography } from "react-vant";
import LicenseSearchTip from "./LicenseSearchTip";
import { useState } from "react";
import { useSafeState } from "ahooks";
import LicenseInputControl from "./LicenseInputControl";
import UserLicenseNos from "./UserLicenseNos";

export default function LicenseInput({
  onSubmit,
}: {
  onSubmit: (value: string) => void;
}): JSX.Element {
  const [license, setLicense] = useState("");
  const [disabled, setDisabled] = useSafeState(false);
  const [keyboard, setKeyboard] = useState(false);
  return (
    <>
      <Space direction="vertical" block gap={30} style={{ minHeight: 360 }}>
        <Space direction="vertical" gap={10} block>
          <Typography.Text size="lg" className="font-bold">
            请输入完整车牌
          </Typography.Text>
          <LicenseInputControl
            openKeyboard={keyboard}
            setOpenKeyboard={setKeyboard}
            input={license}
            setInput={setLicense}
          />
        </Space>
        <UserLicenseNos onClick={setLicense} />
        <LicenseSearchTip />
      </Space>
      <div className="w-full mx-auto">
        <Button
          onClick={() => {
            setDisabled(true);
            setKeyboard(false);
            onSubmit(license);
            setDisabled(false);
          }}
          disabled={license.length < 7 || disabled}
          type="primary"
          size="large"
          block
        >
          获取车位信息
        </Button>
      </div>
    </>
  );
}
