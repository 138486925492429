import { createStore } from "hox";
import { useSafeState } from "ahooks";
import { useState } from "react";

export const [useLicenseInputStore, LicenseInputProvider] = createStore(() => {
  const [loading, setLoading] = useSafeState(false);
  const [license, setLicense] = useSafeState("");
  const [keyboard, setKeyboard] = useState(false);

  return {
    loading,
    setLoading,
    license,
    setLicense,
    keyboard,
    setKeyboard,
  };
});
