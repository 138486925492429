import { FC, useState } from "react";
import ContactList from "../../../components/_contact/List";
import { useRecoilState } from "recoil";
import { contactAtom } from "../../../store/atoms";
import Cell from "react-vant/es/cell/Cell";
import { Manager } from "@react-vant/icons";
import { Typography } from "react-vant";
import { isNil } from "lodash";
import { getContactName, getMaskMobile } from "../../../utils/util";

const Contact: FC = () => {
  const [contact, setContact] = useRecoilState(contactAtom);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Cell
        icon={<Manager color={"#8a8a8a"} />}
        className={"mt-5"}
        title={"联系方式"}
        value={
          contact &&
          `${getContactName(contact)} ${getMaskMobile(contact.mobile)}`
        }
        extra={
          isNil(contact) ? (
            <Typography.Text type={"primary"} className={"ml-3"}>
              选择
            </Typography.Text>
          ) : (
            <Typography.Text type={"danger"} className={"ml-3"}>
              取消
            </Typography.Text>
          )
        }
        onClick={() => (isNil(contact) ? setOpen(true) : setContact(null))}
      />
      <Typography.Text className={"m-4 pb-32"} size={"sm"} type={"secondary"}>
        注：该联系方式仅用作获奖通知。
      </Typography.Text>
      <ContactList open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Contact;
