import { Button, Loading, Space, Toast, Typography } from "react-vant";
import { Link } from "../../entity";
import { useRequest } from "ahooks";
import { receive, userReceived } from "../../requests/present-release";
import { Fail } from "@react-vant/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAfter, isBefore, parseISO, subDays } from "date-fns";
import { first, last } from "lodash";

export default function ReceiveButton({ row }: { row: Link }): JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [received, setReceived] = useState(false);
  const [receivedCount, setReceivedCount] = useState(0);
  const fetchRequest = useRequest(() => userReceived(row.entity_no), {
    manual: true,
    onSuccess: (data) => {
      if (data >= row.weekend_present_release.receive_limit) {
        setReceived(true);
      }
      setReceivedCount(data);
      setLoading(false);
    },
  });
  const request = useRequest(() => receive(row.entity_no), {
    manual: true,
    onSuccess: async (data) => {
      if (data.success) {
        fetchRequest.run();
        Toast.success(data.message);
      } else {
        Toast.fail({
          message: data.message,
          icon: <Fail />,
        });
      }
    },
  });
  const beforeRelease = () => {
    return isBefore(
      new Date(),
      parseISO(first(row.weekend_present_release.available_in))
    );
  };
  const afterRelease = () => {
    return isAfter(
      subDays(new Date(), 1),
      parseISO(last(row.weekend_present_release.available_in))
    );
  };
  const handleReceive = () => {
    if (!received) request.run();
  };
  const receiveText = (
    <Typography.Text size="xs" type="secondary">
      {received
        ? "已领取"
        : `可领数量：${receivedCount}/${row.weekend_present_release.receive_limit}`}
    </Typography.Text>
  );
  useEffect(() => {
    setLoading(true);
    fetchRequest.run();
  }, []);
  if (loading) return <Loading />;
  if (received)
    return (
      <Space direction="vertical">
        <Button
          plain
          type="primary"
          style={{ width: "200px" }}
          onClick={() =>
            row.weekend_present_release.channel.back_url ||
            navigate("/user/weekend-presents")
          }
        >
          查看我的礼品
        </Button>
        {receiveText}
      </Space>
    );
  if (row.weekend_present_release.channel.release_count < 1)
    return (
      <Button disabled type="primary" style={{ width: "200px" }}>
        礼品已发放完毕~
      </Button>
    );
  if (beforeRelease())
    return (
      <Button disabled type="primary" style={{ width: "200px" }}>
        活动未开始~
      </Button>
    );
  if (afterRelease())
    return (
      <Button disabled type="primary" style={{ width: "200px" }}>
        活动已结束~
      </Button>
    );
  return (
    <Space direction="vertical">
      <Button
        loading={request.loading}
        disabled={request.loading}
        type="primary"
        className="bg-gradient-to-r from-blue-700 to-blue-500 border-0"
        style={{ width: "200px" }}
        onClick={handleReceive}
      >
        立即领取
      </Button>
      {receiveText}
    </Space>
  );
}
