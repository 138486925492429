export enum ParkingCardState {
  // 审核中
  created,
  // 正常使用
  active,
  // 注销状态
  inActive,
}

export enum CarportType {
  // vip车位
  vip = 1,
  // 月租车位
  monthly,
  // 子母车位
  relation,
}

export enum PayType {
  wallet = 1,
  wxpay = 2,
}

export enum Status {
  inActive = 0,
  cancel = 6,
  active = 10,
}

export enum AuthenticateState {
  prepare,
  authenticating,
  authenticated,
  reject,
}

export enum ResponseErrorCode {
  success = 200,
  validate = 422,
  server = 500,
}

export enum Gender {
  male = 1,
  female = 2,
}

export enum FencingType {
  A = 1,
  B = 2,
  C = 3,
}

export enum FencingGroup {
  A = 1,
  B = 2,
  C = 3,
}

export enum ErrorCode {
  BadRequest = 400,
  UnAuthorized = 401,
  validationException = 422,
  throttleException = 429,
  serverException = 500,
  serviceUnableException = 503,
}

export enum SpecialCardSaleMode {
  default = 1,
  withProject,
}

export enum CouponType {
  default = 1,
  discount,
  super,
}

export enum CouponScene {
  parking = 1,
  checkstand,
  specialCard,
  super,
}

export enum PriceUnit {
  default = 1,
  discount,
}
