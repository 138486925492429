import { FC, useEffect, useState } from "react";
import { useTitle } from "ahooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { findById } from "../../requests/special-card";
import { isNil, toNumber } from "lodash";
import { Image, Toast } from "react-vant";
import { SpecialCard } from "../../entity";

const SpecialCardPoster: FC = () => {
  useTitle("文体汇小超人计划");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [specialCard, setSpecialCard] = useState<SpecialCard>();

  const redirect = (specialCard: SpecialCard) => {
    navigate(`/activity/special-card/${specialCard.id}${location.search}`);
  };

  const handleFetch = async (id: number) => {
    Toast.loading("");
    const response = await findById(id);
    if (isNil(response)) navigate("/404");
    else if (isNil(response?.poster_url)) redirect(response);
    else setSpecialCard(response);
    Toast.clear();
  };

  useEffect(() => {
    if (id) handleFetch(toNumber(id));
  }, [id]);

  if (specialCard)
    return (
      <Image
        src={specialCard.poster_url}
        onClick={() => redirect(specialCard)}
      />
    );

  return <></>;
};

export default SpecialCardPoster;
