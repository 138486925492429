import { Space, Typography } from "react-vant";
import config from "../../../config/config";

export default function LicenseSearchTip(): JSX.Element {
  return (
    <Space direction="vertical" gap={10} block>
      <Typography.Text size="lg" className="font-bold">
        功能说明
      </Typography.Text>
      <Typography.Text type="secondary">
        文体汇VIP、月租车位信息查询，适用于{config.northCarportName}
      </Typography.Text>
    </Space>
  );
}
