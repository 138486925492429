import { Checked } from "@react-vant/icons";
import { Button, Space, Typography } from "react-vant";
import { useSetRecoilState } from "recoil";
import { walletRemainAtom } from "../../../store/atoms";
import { useRequest, useTitle } from "ahooks";
import getOne from "../../../requests/user-wallet";
import { useNavigate } from "react-router-dom";

export default function WalletCreditResult(): JSX.Element {
  useTitle("充值成功");
  const navigate = useNavigate();
  const setWalletRemain = useSetRecoilState(walletRemainAtom);
  useRequest(getOne, {
    onSuccess: (data) => setWalletRemain(data.data.remain_amount),
  });
  return (
    <div className={"px-10"}>
      <Space
        direction="vertical"
        block
        gap={15}
        align="center"
        className="pt-14"
      >
        <Checked fontSize={70} className="text-blue-500" />
        <Typography.Text size="lg" className="font-bold">
          充值成功
        </Typography.Text>
      </Space>
      <Button
        type="primary"
        block
        className={"mt-10"}
        size={"large"}
        onClick={() => navigate("/user/wallet", { replace: true })}
      >
        完成
      </Button>
    </div>
  );
}
