import { Dialog, Input } from "react-vant";
import { useEffect, useState } from "react";

export default function RemarkInput({
  open,
  onClose,
  defaultRemark,
  handleConfirm,
}: {
  open: boolean;
  onClose: VoidFunction;
  defaultRemark: string;
  handleConfirm: (remark: string) => void;
}): JSX.Element {
  const [remark, setRemark] = useState("");
  useEffect(() => setRemark(defaultRemark), [defaultRemark]);

  return (
    <Dialog
      visible={open}
      title="添加备注"
      className="px-5"
      showCancelButton
      onCancel={onClose}
      onConfirm={() => handleConfirm(remark)}
    >
      <Input.TextArea
        value={remark}
        rows={3}
        autoSize
        clearable
        placeholder="请输入内容"
        maxLength={20}
        showWordLimit
        onChange={setRemark}
      />
    </Dialog>
  );
}
