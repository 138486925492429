import { Button, Loading, Space, Typography } from "react-vant";
import LicenseInputControl from "../../../../components/_parking/_home/LicenseInputControl";
import UserParkingCarSection from "./UserParkingCarSection";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import LicenseSearchTip from "./LicenseSearchTip";
import { useLicenseInputStore } from "../store/license-input";

export const ParkingLicenseInput: FC<{
  onSubmit: (value: string) => void;
}> = ({ onSubmit }) => {
  const { licenseNo } = useParams();
  const { loading, setLoading, license, setLicense, keyboard, setKeyboard } =
    useLicenseInputStore();

  useEffect(() => {
    if (licenseNo) setLicense(licenseNo);
    return () => setLicense("");
  }, []);

  return (
    <>
      <Space direction="vertical" block gap={40} style={{ minHeight: 400 }}>
        <Space direction="vertical" block>
          <Typography.Title level={5}>请输入完整车牌</Typography.Title>
          <LicenseInputControl
            openKeyboard={keyboard}
            setOpenKeyboard={setKeyboard}
            input={license}
            setInput={setLicense}
          />
        </Space>
        <UserParkingCarSection onSelected={setLicense} />
        <LicenseSearchTip />
      </Space>
      <Button
        onClick={() => {
          setLoading(true);
          setKeyboard(false);
          onSubmit(license);
          setTimeout(() => setLoading(false), 500);
        }}
        disabled={license.length < 7 || loading}
        type="primary"
        className="w-full"
        size="large"
        block
      >
        {loading ? (
          <Loading size={20} textColor="#ffffff">
            查询中..
          </Loading>
        ) : (
          "查询"
        )}
      </Button>
    </>
  );
};
