import { useRequest, useTitle } from "ahooks";
import { findByOrderNo } from "../../../../requests/order-special-card";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ActionBar,
  Card,
  Image,
  NoticeBar,
  Skeleton,
  Space,
  Tag,
  Toast,
  Typography,
} from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { OrderSpecialCard } from "../../../../entity";
import { isNil, join } from "lodash";
import { getMaskMobile } from "../../../../utils/util";
import { InfoO, Phone } from "@react-vant/icons";
import {
  getGenderDescription,
  getPaidDescription,
  getPayTypeDescription,
} from "../../../../utils/desctriptor";
import copy from "copy-to-clipboard";
import GroupBuyView from "./Components/GroupBuyView";
import {
  findGroupLeader,
  UserGroupBuyContext,
} from "../../../special-card/constant";
import { Status } from "../../../../utils/enum";
import { ContentCellGroup } from "../../../../type";
import ContentCard from "../Components/ContentCard";

const getItems = (order: OrderSpecialCard): ContentCellGroup[] => [
  {
    title: "基本信息",
    children: [
      { title: "卡号", value: order.user_special_card.card_no },
      {
        title: "购买人",
        value: `${
          order.username + getGenderDescription(order.gender)
        } ${getMaskMobile(order.mobile)}`,
      },
      { title: "项目数量", value: order.user_activity_projects.length },
      {
        title: "活动时间",
        value: join(order.user_special_card.sale_in, "至"),
      },
      {
        title: "礼品兑换码",
        center: true,
        onClick: () => {
          if (order.user_special_card.gift_voucher)
            copy(order.user_special_card.gift_voucher, {
              format: "text/plain",
              onCopy: () =>
                Toast({
                  message: "复制成功",
                  position: "bottom",
                }),
            });
        },
        value: order.user_special_card.gift_voucher ? (
          <Space>
            {order.user_special_card.gift_voucher}
            <Tag plain type="danger">
              复制
            </Tag>
          </Space>
        ) : (
          "无"
        ),
      },
    ],
  },
  {
    title: "购买项目",
    children: order.user_activity_projects.map((project) => {
      return {
        className: "px-0",
        titleClass: "ml-2",
        title: project.title,
        center: true,
        label: `有效期：${join(project.available_in, "至")}`,
        icon: (
          <Image
            src={project.thumbnail_url}
            width={50}
            height={50}
            radius={5}
          />
        ),
        onClick: () => {
          window.location.href = `tel://${project.contact_mobile}`;
        },
        rightIcon: <Phone className="text-orange-400" fontSize={30} />,
      };
    }),
  },
  {
    title: "订单信息",
    children: [
      { title: "订单号", value: order.order_no },
      { title: "订单标题", value: order.order.brief },
      { title: "支付时间", value: order.order.paid_at },
      { title: "支付金额", value: `${order.order.total_fee}元` },
      {
        title: "支付方式",
        value: getPayTypeDescription(order.order.pay_type),
      },
      {
        title: "订单状态",
        value: getPaidDescription(order.order.paid_at),
      },
    ],
  },
];

export default function SpecialCardView(): JSX.Element {
  useTitle("订单详情");
  const { orderNo } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderSpecialCard>();
  const [items, setItems] = useState<ContentCellGroup[]>([]);
  const findRequest = useRequest(() => findByOrderNo(orderNo || ""), {
    manual: true,
    onSuccess: (data) => {
      if (!isNil(data)) {
        setOrder(data);
        setItems(getItems(data));
      } else navigate("/404");
    },
  });

  useEffect(() => {
    if (orderNo) findRequest.run();
  }, []);

  if (order)
    return (
      <>
        <div>
          {order?.user_special_card?.gift_voucher && (
            <NoticeBar
              wrapable
              text={
                <Typography.Text className="text-red-500 leading-6 ml-1">
                  您有一份礼品待领取，请复制下方礼品兑换码，前往
                  <Typography.Text
                    className="mx-1 text-blue-500"
                    onClick={() => navigate(`/user/weekend-presents`)}
                  >
                    会员中心-福利兑换
                  </Typography.Text>{" "}
                  中获取。
                </Typography.Text>
              }
              leftIcon={<InfoO />}
            />
          )}
        </div>
        <Space block direction="vertical" className="pb-32 px-2 pt-3" gap={10}>
          {items.map((item) => (
            <ContentCard key={item.title} item={item} />
          ))}
          {order.use_group_buy === Status.active ? (
            <UserGroupBuyContext.Provider
              value={{
                userGroupBuys: order.user_group_buys,
                leader: findGroupLeader(order.user_group_buys),
              }}
            >
              <GroupBuyView />
            </UserGroupBuyContext.Provider>
          ) : (
            <></>
          )}
        </Space>
        <ActionBar>
          <ActionBar.Button
            type="primary"
            onClick={() =>
              navigate(`/activity/special-card/${order.special_card_id}`)
            }
          >
            查看活动
          </ActionBar.Button>
        </ActionBar>
      </>
    );

  return (
    <div className="pt-1">
      <Card className="mb-2">
        <CardBody className="px-0">
          <Skeleton title row={5} />
        </CardBody>
      </Card>
      <Card className="mb-2">
        <CardBody className="px-0">
          <Skeleton title row={5} />
        </CardBody>
      </Card>
      <Card className="mb-2">
        <CardBody className="px-0">
          <Skeleton title row={5} />
        </CardBody>
      </Card>
    </div>
  );
}
