import { Space, Typography } from "react-vant";
import config from "../../../../config/config";

export default function LicenseSearchTip(): JSX.Element {
  return (
    <Space direction="vertical" block>
      <Typography.Title level={5}>功能说明</Typography.Title>
      <div>
        <Typography.Text type="secondary" className="leading-6">
          临停缴费、在场车辆信息查询，适用于{config.eastCarportName}。
          {/*          <Typography.Text type="warning">
            扫码入场车辆，请点击这里
          </Typography.Text> */}
        </Typography.Text>
      </div>
    </Space>
  );
}
