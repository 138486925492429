import { useRequest, useSafeState } from "ahooks";
import { authenticate } from "../utils/util";
import { isNil } from "lodash";
import { getToken } from "../utils/storage";
import { useEffect } from "react";
import { AuthenticateState } from "../utils/enum";

export default function useAuth(): AuthenticateState {
  const [authenticated, setAuthenticated] = useSafeState<AuthenticateState>(
    AuthenticateState.prepare
  );
  const { run } = useRequest(authenticate, {
    manual: true,
    onSuccess: (data) => {
      setAuthenticated(data.state);
    },
  });
  useEffect(() => {
    if (isNil(getToken())) {
      run();
    } else {
      setAuthenticated(AuthenticateState.authenticated);
    }
  }, []);
  return authenticated;
}
