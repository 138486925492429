import React, { FC } from "react";
import { Space, Typography } from "react-vant";
import { useNavigate } from "react-router-dom";

const GroupBuyResultNotice: FC = () => {
  const navigate = useNavigate();

  return (
    <Space wrap direction={"vertical"} gap={2} className={"px-3 py-5"}>
      <Typography.Text type={"secondary"} className={"font-bold"}>
        温馨提示：
      </Typography.Text>
      <Typography.Text type={"secondary"}>
        1、支付成功后可在"武汉文体汇"微信公众号，"会员中心-全部订单"
        中查看拼团进度，或
        <Typography.Text
          type={"warning"}
          className={"ml-1"}
          onClick={() => navigate("/user/orders")}
        >
          点击查看
        </Typography.Text>
      </Typography.Text>

      <Typography.Text type={"secondary"}>
        2、若超时未拼成，订单将自动取消，已支付金额原路退回。
      </Typography.Text>
    </Space>
  );
};

export default GroupBuyResultNotice;
