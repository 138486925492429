import { Card, Skeleton } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";

const Placeholder = () => (
  <>
    <Card>
      <CardBody>
        <Skeleton title row={10} className={"mb-3"} />
        <Skeleton title row={7} />
      </CardBody>
    </Card>
  </>
);

export default Placeholder;
