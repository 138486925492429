import request from "../utils/request";
import { UserGroupBuy } from "../entity";
import { DataResponse } from "../request";

const ROUTE = "user-group-buys";

export function getList(params: {
  group_buy_id: number;
}): Promise<DataResponse<UserGroupBuy[]>> {
  return request().get(ROUTE, { params });
}

export function findByGroupNo(groupNo: string): Promise<UserGroupBuy[]> {
  return request().get(`${ROUTE}/${groupNo}`);
}
