import { FC } from "react";
import { Card, Skeleton, Space } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";

const CheckoutPlaceholder: FC = () => (
  <Space direction={"vertical"} block>
    <Card>
      <CardBody>
        {[...Array(3)].map((o, i) => (
          <Skeleton
            key={i}
            avatarShape={"square"}
            avatar
            avatarSize={60}
            row={1}
            round={false}
            rowHeight={40}
            className={"mb-2"}
          />
        ))}
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <Skeleton row={1} />
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <Skeleton row={5} rowHeight={17} />
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <Skeleton row={3} rowHeight={17} />
      </CardBody>
    </Card>
  </Space>
);

export default CheckoutPlaceholder;
