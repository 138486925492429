import { Card, Divider, Flex, Image, Space, Typography } from "react-vant";
import { CardBody, CardFooter } from "react-vant/es/card/Card";
import FlexItem from "react-vant/es/flex/FlexItem";
import { Order } from "../../../../../entity";
import { useNavigate } from "react-router-dom";
import { FC } from "react";

const DefaultPanel: FC<{ order: Order }> = ({ order }) => {
  const navigate = useNavigate();

  return (
    <Card border round className="mb-2">
      <CardBody
        onClick={() => navigate(`/user/orders/special-card/${order.order_no}`)}
      >
        <Space block justify="between" align="center">
          <Typography.Title level={4} className="mb-0">
            {order.brief}
          </Typography.Title>
          <Typography.Text type="secondary">支付成功</Typography.Text>
        </Space>
        <Divider className="my-3" />
        <Flex align="center" gutter={20}>
          <FlexItem>
            <Image
              src={order.special_card.banner_url}
              width={50}
              height={50}
              radius={5}
            />
          </FlexItem>
          <FlexItem>
            <Flex direction="column">
              <FlexItem>
                <Typography.Text>
                  项目数量: {order.special_card.user_activity_projects_count}
                </Typography.Text>
              </FlexItem>
              <FlexItem>
                <Typography.Text>支付金额: {order.total_fee}元</Typography.Text>
              </FlexItem>
              <FlexItem>
                <Typography.Text>支付时间: {order.paid_at}</Typography.Text>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      </CardBody>

      <CardFooter />
    </Card>
  );
};

export default DefaultPanel;
