import UserMenuOrder from "./user-menu-order.svg";
import UserMenuCoupon from "./user-menu-coupon.svg";
import UserMenuDhm from "./user-menu-duihuanma.svg";
import UserMenuWallet from "./user-menu-wallet.svg";
import UserMenuKabao from "./user-menu-kabao.svg";
import UserMenuService from "./user-menu-service.svg";
import UserMenuSecure from "./user-menu-secure.svg";
import OrderCheckstand from "./order-checkstand.svg";
import OrderSpecialCard from "./order-clubcard.svg";
import OrderCourse from "./order-course.svg";
import OrderElectric from "./order-electric.svg";
import OrderReserve from "./order-reserve.svg";
import OrderParkingFee from "./order-parking.svg";
import Rmb from "./rmb.svg";
import WxPay from "./paytype-wxpay.svg";
import WalletPay from "./paytype-wallet.svg";
import StatusEmpty from "./status-empty.svg";
import StatusNotFound from "./status-404.svg";
import StatusSearchFailed from "./status-search-failed.svg";
import PresentCheckDecagram from "./present-check-decagram.svg";
import SecurityMobile from "./security-mobile.svg";
import SecurityUpdateMobile from "./security-update-mobile.svg";
import ParkingCredit from "./parking-credit.svg";
import ParkingCreditLog from "./parking-credit-log.svg";
import ParkingRent from "./parking-rent.svg";
import ParkingCarport from "./parking-carport.svg";
import ParkingCarportAuthMobile from "./parking-carport-auth-mobile.svg";
import FencingType from "./fencing-type.svg";
import FencingGroup from "./fencing-group.svg";
import FencingBasicInfo from "./fencing-basic-info.svg";
import StateTagOk from "./state-tag-ok.svg";
import StateTagInvalid from "./state-tag-invalid.svg";
import ArrowRight from "./arrow-right.svg";
import Question from "./question.svg";
import ShareArrow from "./share-arrow.svg";

const svgIcon = {
  UserMenuOrder,
  UserMenuCoupon,
  UserMenuDhm,
  UserMenuWallet,
  UserMenuKabao,
  UserMenuService,
  UserMenuSecure,
  OrderCheckstand,
  OrderSpecialCard,
  OrderCourse,
  OrderElectric,
  OrderReserve,
  OrderParkingFee,
  Rmb,
  WxPay,
  WalletPay,
  StatusEmpty,
  StatusNotFound,
  StatusSearchFailed,
  PresentCheckDecagram,
  SecurityMobile,
  SecurityUpdateMobile,
  ParkingCarport,
  ParkingCredit,
  ParkingRent,
  ParkingCreditLog,
  ParkingCarportAuthMobile,
  FencingType,
  FencingGroup,
  FencingBasicInfo,
  StateTagOk,
  StateTagInvalid,
  ArrowRight,
  Question,
  ShareArrow,
};

export default svgIcon;
