import {
  ActionSheet,
  Button,
  Flex,
  Form,
  Image,
  Input,
  NoticeBar,
  Space,
  Tag,
  Toast,
  Typography,
} from "react-vant";
import svgIcon from "../../../assets/svg";
import FlexItem from "react-vant/es/flex/FlexItem";
import { useRecoilValue } from "recoil";
import { carportIdentityAtom } from "../../../store/atoms";
import { first, isNil } from "lodash";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getMaskMobile } from "../../../utils/util";
import { useRequest, useTitle } from "ahooks";
import { auth } from "../../../requests/parking-card";
import { useState } from "react";
import { carportAuthMobileSelector } from "../../../store/selectors";
import { ActionSheetAction } from "react-vant/es/action-sheet/PropsType";
import { setCarportCredential } from "../../../utils/storage";
import MessageCountDown from "../../../components/_parking/_home/MessageCountDown";

interface AuthParams {
  code: number;
}

export default function ParkingAuth(): JSX.Element {
  useTitle("车主身份验证");
  const navigate = useNavigate();
  const location = useLocation();
  const { licenseNo } = useParams();
  const [openSheet, setOpenSheet] = useState(false);
  const carportIdentity = useRecoilValue(carportIdentityAtom);
  const authMobiles = useRecoilValue(carportAuthMobileSelector);
  const [authMobile, setAuthMobile] = useState(first(authMobiles));
  const { ParkingCarportAuthMobile } = svgIcon;
  const [form] = Form.useForm<AuthParams>();
  const actions: ActionSheetAction[] = authMobiles.map((mobile) => {
    return { name: mobile, disabled: authMobile === mobile };
  });
  const authRequest = useRequest(auth, {
    manual: true,
    onSuccess: (data) => {
      if (data.success) {
        const { card_id } = data.data;
        setCarportCredential({
          cardId: card_id,
          mobiles: authMobiles,
        });
        Toast.clear();
        navigate(`/parking/credit/${licenseNo}`, {
          replace: true,
        });
      } else {
        Toast.info({
          message: data.errors?.first || data.message,
          position: "bottom",
        });
      }
    },
  });
  const onFinish = (value: AuthParams) => {
    Toast.loading("");
    authRequest.run({
      code: value.code,
      mobile: authMobile as string,
      card_id: carportIdentity?.cardId as number,
    });
  };
  const onFinishFailed = (errors: any) => {
    Toast.info({
      message: errors.errorFields[0].errors[0],
      position: "bottom",
    });
  };
  if (isNil(carportIdentity)) {
    return (
      <Navigate to="/parking/license-search" state={location.state} replace />
    );
  }
  return (
    <>
      <Space direction="vertical" block gap={50} className="mb-5">
        <NoticeBar
          wrapable
          mode="closeable"
          scrollable={false}
          text="为了您的信息安全，本次操作需要验证车位预留信息"
        />
        <Flex direction="column" align="center" gutter={[0, 20]}>
          <FlexItem>
            <Image src={ParkingCarportAuthMobile} width={80} height={80} />
          </FlexItem>
          <FlexItem>
            <Space direction="vertical" gap={5} align="center">
              <Space onClick={() => setOpenSheet(true)}>
                <Typography.Text size="lg">验证预留手机号</Typography.Text>
                <Typography.Text size="lg">
                  {getMaskMobile(authMobile as string)}
                </Typography.Text>
                {authMobiles.length > 1 && (
                  <Tag type="warning" plain size="mini">
                    更多
                  </Tag>
                )}
              </Space>
              <Typography.Text type="secondary">
                若有多个手机号，只需验证其中一个
              </Typography.Text>
            </Space>
          </FlexItem>
        </Flex>
      </Space>
      <Form
        form={form}
        showValidateMessage={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        footer={
          <div className="w-8/12 mx-auto mt-8">
            <Button nativeType={"submit"} type="primary" block size="large">
              确认
            </Button>
          </div>
        }
      >
        <Form.Item
          required={false}
          rules={[
            { required: true, message: "请输入验证码" },
            { len: 4, message: "短信验证码不正确" },
          ]}
          name="code"
          size={"large"}
          label="验证码"
          suffix={<MessageCountDown mobile={authMobile} />}
        >
          <Input placeholder="输入验证码" />
        </Form.Item>
      </Form>
      <ActionSheet
        visible={openSheet}
        closeable
        closeOnClickAction
        onSelect={(actions) => setAuthMobile(actions.name)}
        onCancel={() => setOpenSheet(false)}
        actions={actions}
        cancelText="取消"
      />
    </>
  );
}
