import { useRecoilState } from "recoil";
import { parkingOrderAtom } from "../../../store/atoms";
import { useEffect } from "react";
import { isNil } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, Space, Typography } from "react-vant";
import ParkingLicenseSearchFailed from "./Components/ParkingOrderSearchFailed";
import { useRequest, useTitle } from "ahooks";
import { findOrderByPlateNo } from "../../../requests/parking-fee";

export default function ParkingOrderSearchResult() {
  useTitle("停车订单查询");
  const { licenseNo } = useParams();
  const navigate = useNavigate();
  const [parkingOrder, setParkingOrder] = useRecoilState(parkingOrderAtom);
  const searchRequest = useRequest(() => findOrderByPlateNo(licenseNo || ""), {
    manual: true,
    onSuccess: (data) => {
      if (data.success) {
        setParkingOrder(data.data);
        navigate(`/parking/fee/checkout/${licenseNo}`, { replace: true });
      } else setParkingOrder(null);
    },
  });

  useEffect(() => {
    searchRequest.run();
  }, []);

  if (searchRequest.loading)
    return (
      <div className="pt-20 text-center">
        <Space direction="vertical" className="mt-10" gap={30}>
          <Loading size={60} className=" text-blue-500" />
          <Typography.Text>正在查询 {licenseNo} 的停车订单 ..</Typography.Text>
        </Space>
      </div>
    );

  return (
    <div className="text-center">
      {isNil(parkingOrder) ? (
        <ParkingLicenseSearchFailed />
      ) : (
        <Space direction="vertical" className="mt-10" gap={30}>
          <Loading size={60} className=" text-blue-500" />
          <Typography.Text>跳转缴费中, 请稍等..</Typography.Text>
        </Space>
      )}
    </div>
  );
}
