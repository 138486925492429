import FlexItem from "react-vant/es/flex/FlexItem";
import { Button } from "react-vant";
import { ParkingCar } from "../../../../entity";

export default function ParkingCarList({
  list,
  onSelected,
}: {
  list: ParkingCar[];
  onSelected: (value: string) => void;
}): JSX.Element {
  return (
    <>
      {list.map((car) => (
        <FlexItem key={car.id} span={list.length > 2 ? 8 : 12}>
          <Button
            plain
            block
            className="rv-user-license-button"
            onClick={() => onSelected(car.plate_no)}
          >
            {car.plate_no}
          </Button>
        </FlexItem>
      ))}
    </>
  );
}
