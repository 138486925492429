import { Button, Space, Typography } from "react-vant";
import { Checked } from "@react-vant/icons";
import { useTitle } from "ahooks";
import { FC } from "react";
import SvgScan from "@react-vant/icons/es/Scan";
import { configWxSdk, handleScanQRCode } from "../../utils/util";
import getSdkConfig from "../../requests/wxsdk";

const VerifyOrderResult: FC = () => {
  useTitle("支付结果");

  const handleScan = () => {
    getSdkConfig(["scanQRCode"]).then((result) => {
      configWxSdk(result.data);
      handleScanQRCode();
    });
  };

  return (
    <>
      <Space
        direction="vertical"
        align="center"
        className="pt-10 pb-14"
        block
        gap={5}
      >
        <Checked fontSize={75} className="text-blue-500" />
        <Typography.Text className={"text-xl"}>操作成功</Typography.Text>
      </Space>
      <div className={"text-center px-8"}>
        <Button
          type={"primary"}
          icon={<SvgScan />}
          className={"px-6"}
          block
          onClick={handleScan}
        >
          继续核销
        </Button>
      </div>
    </>
  );
};

export default VerifyOrderResult;
