import request from "../utils/request";
import {
  DataResponse,
  ParkingOrderBody,
  ParkingPayFormValues,
  TwParkingBody,
} from "../request";

const ROUTE = "parking";

export function findOrderByPlateNo(
  plateNo: string
): Promise<DataResponse<ParkingOrderBody>> {
  return request().get(`${ROUTE}/fee/${plateNo}`);
}

export function pay(
  data: ParkingPayFormValues
): Promise<DataResponse<TwParkingBody>> {
  return request().post(`${ROUTE}/pay`, data);
}
