import { FC, useEffect, useState } from "react";
import Card, { CardBody, CardHeader } from "react-vant/es/card/Card";
import { Space, Typography } from "react-vant";
import { ClockO } from "@react-vant/icons";
import "./index.css";
import { isNil } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useRequest, useTitle } from "ahooks";
import { findById } from "../../requests/vote";
import { Vote as VoteEntity } from "../../entity";
import VoteLoader from "./Components/VoteLoader";
import ParticipatorList from "./Components/ParticipatorList";
import VoteTabbar from "../../components/VoteTabbar";
import Flex from "react-vant/es/flex/Flex";
import FlexItem from "react-vant/es/flex/FlexItem";
import SwipeBanner from "../../components/SwipeBanner";

const Vote: FC = () => {
  useTitle("文体汇投票");
  const { id } = useParams();
  const navigate = useNavigate();
  const [vote, setVote] = useState<VoteEntity | null>(null);

  const request = useRequest(findById, { manual: true });

  const fetchData = () => {
    setTimeout(
      () =>
        request.runAsync(id || "").then((data) => {
          if (data) setVote(data);
          else navigate("/404");
        }),
      500
    );
  };

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  if (request.loading || isNil(vote)) return <VoteLoader />;

  return (
    <div className={"min-h-screen bg-gray-100 pb-24"}>
      <SwipeBanner banners={vote?.banners} />
      <Space direction={"vertical"} block gap={15} className={"p-3"}>
        <Card round>
          <CardHeader className={"justify-center py-3"}>
            {vote.title}
          </CardHeader>
          <CardBody>
            <Flex direction={"column"} wrap={"wrap"} gutter={[0, 10]}>
              <FlexItem className={"flex items-center"}>
                <ClockO className={"text-blue-500 mr-2"} />
                <Typography.Text>
                  投票开始：{vote.available_in[0]}
                </Typography.Text>
              </FlexItem>
              <FlexItem className={"flex items-center"}>
                <ClockO className={"text-blue-500 mr-2"} />
                <Typography.Text>
                  投票结束：{vote.available_in[1]}
                </Typography.Text>
              </FlexItem>
              <FlexItem className={"flex items-center"}>
                <ClockO className={"text-blue-500 mr-2"} />
                <Typography.Text>
                  投票规则：投票期间每个微信号每天限投{vote.grant_limit}次
                </Typography.Text>
              </FlexItem>
            </Flex>
          </CardBody>
        </Card>
        <ParticipatorList voteId={vote.id} />
      </Space>
      <VoteTabbar />
    </div>
  );
};

export default Vote;
