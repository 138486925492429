export default {
  // 短信验证码发送间隔，单位：秒
  messageInterval: 60 * 1000,
  // 车牌查询搜索历史数量限制
  licenseHistoryLimit: 3,
  // 月租车价格
  carportMonthlyCreditPrice: 300,
  // 标准车位价格
  carportSingleCreditPrice: 1200,
  // 子母车位单价
  carportRelationCreditPrice: 1800,
  // 居民区停车场别名
  northCarportName: "文体汇地下停车场(居民区)",
  // 商业区停车场别名
  eastCarportName: "文体汇地下停车场(商业区)",
  fencingFormName: "2022年武汉市击剑俱乐部联赛",
  fencingFee: 200,
  fencingEndAt: "2022-06-08 24:00:00",
};
