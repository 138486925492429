import { useAsyncEffect, useRequest, useSetState } from "ahooks";
import { useNavigate, useParams } from "react-router-dom";
import { getListByActivitySaleId } from "../../requests/merchant-project";
import { useState } from "react";
import { take, toNumber } from "lodash";
import { ActivitySale, MerchantProject } from "../../entity";
import { PaginationQueryParams } from "../../request";
import {
  Button,
  Card,
  Divider,
  Image,
  List,
  Space,
  Typography,
} from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { implodedTags } from "../../utils/util";
import { findById } from "../../requests/activity-sale";
import Placeholder from "./Placeholder";

const ActivitySaleMain = () => {
  const { activitySaleId } = useParams();
  const navigate = useNavigate();
  const [finished, setFinished] = useState(false);
  const [params, setParams] = useSetState<PaginationQueryParams>({
    page: 1,
    take: 8,
    activity_sale_id: activitySaleId,
  });
  const [activitySale, setActivitySale] = useState<ActivitySale>();
  const [merchantProjects, setMerchantProjects] = useState<MerchantProject[]>(
    []
  );
  const activitySaleRequest = useRequest(findById, { manual: true });
  const request = useRequest(getListByActivitySaleId);

  const handleFetch = async (activitySaleId: number) => {
    setActivitySale(await activitySaleRequest.runAsync(activitySaleId));
  };

  const onLoad = async () => {
    const response = await request.runAsync(params);
    setMerchantProjects((value) => {
      const current = [...value, ...response.data];
      setParams((param) => {
        return { page: param.page + 1 };
      });
      if (current.length >= response.meta.total) setFinished(true);
      return current;
    });
  };

  useAsyncEffect(async () => {
    if (activitySaleId) {
      handleFetch(toNumber(activitySaleId));
    }
  }, [activitySaleId]);

  if (activitySale)
    return (
      <>
        <Card>
          <Image src={activitySale.poster_url} className={"pb-3"} />
          <CardBody>
            <List
              finished={finished}
              onLoad={onLoad}
              finishedText="加载完毕~"
              className="pb-24"
            >
              {merchantProjects.map((project) => (
                <div key={project.id}>
                  <div
                    className={"flex justify-start my-3"}
                    onClick={() =>
                      navigate(
                        `/activity-sale/merchant-project/${project.id}?activity_sale_id=${activitySaleId}`
                      )
                    }
                  >
                    <Image
                      src={project.figure_url}
                      width={120}
                      height={120}
                      radius={5}
                    />
                    <Space
                      block
                      direction="vertical"
                      className={"flex-1 px-3 py-1"}
                    >
                      <Typography.Title level={5} className={"mb-0"}>
                        {project.title}
                      </Typography.Title>
                      <Space direction={"vertical"} gap={2}>
                        <div className="divide-x">
                          {project.tags &&
                            take(implodedTags(project.tags), 3).map(
                              (tag, index) => (
                                <Typography.Text
                                  key={tag}
                                  size={"sm"}
                                  className={index < 1 ? "px-2 pl-0" : "px-2"}
                                  type="secondary"
                                >
                                  {tag}
                                </Typography.Text>
                              )
                            )}
                        </div>
                        <Typography.Text size={"sm"} type={"secondary"}>
                          {activitySale.valid_time} -{" "}
                          {activitySale.invalid_time}
                        </Typography.Text>
                      </Space>

                      <Space justify={"between"} align={"center"} block>
                        <Space align={"center"}>
                          <Typography.Text className={"text-red-500 text-xl"}>
                            ¥{activitySale.current_price / 100}
                          </Typography.Text>
                          <Typography.Text className={"text-gray-400"} delete>
                            ¥{activitySale.origin_price / 100}
                          </Typography.Text>
                        </Space>
                        <Button
                          size={"mini"}
                          type={"warning"}
                          className={"px-3 text-sm rounded"}
                        >
                          购买
                        </Button>
                      </Space>
                    </Space>
                  </div>
                  <Divider />
                </div>
              ))}
            </List>
          </CardBody>
        </Card>
      </>
    );
  return <Placeholder />;
};

export default ActivitySaleMain;
