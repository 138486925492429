import { RefundO } from "@react-vant/icons";
import { Button, Space, Toast, Typography } from "react-vant";
import { CellGroup, Cell } from "react-vant/es/cell";
import { getMaskMobile } from "../../utils/util";
import { useEffect, useState } from "react";
import { useRequest, useTitle } from "ahooks";
import { activeVip, findVip } from "../../requests/investor-account";
import { isEmpty } from "lodash";
import { InvestorAccount as InvestorAccountEntity } from "../../entity";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/storage";
import { useRecoilState } from "recoil";
import { walletRemainAtom } from "../../store/atoms";

const defaultVip = {
  true_name: "",
  mobile: "",
  amount: 0,
  active_amount: 0,
} as InvestorAccountEntity;

export default function InvestorAccount(): JSX.Element {
  useTitle("村民权益");
  const navigate = useNavigate();
  const [vip, setVip] = useState<InvestorAccountEntity>(defaultVip);
  const [walletRemain, setWalletRemain] = useRecoilState(walletRemainAtom);
  const vipRequest = useRequest(findVip, {
    manual: true,
    onSuccess: (data) => {
      if (isEmpty(data)) navigate("/investor-account/notfound");
      setVip(data);
      Toast.clear();
    },
  });
  const activeRequest = useRequest(activeVip, {
    manual: true,
    onSuccess: (data) => {
      if (data.success) {
        setWalletRemain(() => {
          return (
            ((walletRemain as number) * 100 +
              (vip.amount - vip.active_amount)) /
            100
          );
        });
        navigate("/investor-account/result");
      } else {
        Toast.fail(data.message);
      }
    },
  });
  useEffect(() => {
    Toast.loading("");
    vipRequest.run();
  }, [getToken()]);
  return (
    <>
      <Space
        direction="vertical"
        gap={0}
        block
        align="center"
        className="pt-14 pb-10"
      >
        <RefundO fontSize={70} className="text-gray-700" />
        <Typography.Text>余额领取</Typography.Text>
      </Space>
      <CellGroup inset>
        <Cell center title="手机号" value={getMaskMobile(vip.mobile)} />
        <Cell center title="姓名" value={vip.true_name} />
        <Cell
          center
          title="未领取金额"
          value={`${(vip.amount - vip.active_amount) / 100}元`}
        />
        <Cell
          center
          border={false}
          title="已领取"
          value={`${vip.active_amount / 100}元`}
        />
        <Cell title=" " label="提示：领取金额将自动充值到钱包余额" />
      </CellGroup>
      <div className="w-8/12 mx-auto mt-8">
        {vip.amount === vip.active_amount ? (
          <Button size="large" plain disabled type="primary">
            已全部领取
          </Button>
        ) : (
          <Button
            loading={activeRequest.loading}
            disabled={activeRequest.loading}
            onClick={() => activeRequest.run()}
            size="large"
            type="primary"
          >
            确认领取
          </Button>
        )}
      </div>
    </>
  );
}
