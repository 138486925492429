import { Outlet } from "react-router-dom";
import Auth from "../pages/Auth";

export default function BasicLayout({
  white = false,
  needLogin = false,
}: {
  white?: boolean;
  needLogin?: boolean;
}): JSX.Element {
  return (
    <div className={`${white ? "bg-white" : "bg-gray-100"} min-h-screen `}>
      <Auth needLogin={needLogin}>
        <Outlet />
      </Auth>
    </div>
  );
}
