import request from "../utils/request";
import {
  PaginationQueryParams,
  PaginationResponse,
  Response,
} from "../request";
import { UserWeekendPresent } from "../entity";

const ROUTE = "user/weekend-presents";

export function getList(
  params: PaginationQueryParams
): Promise<PaginationResponse<UserWeekendPresent>> {
  return request().get(ROUTE, { params });
}

export function getOne(id: number): Promise<UserWeekendPresent> {
  return request().get(`${ROUTE}/${id}`);
}

export function receivePresent(id: number): Promise<Response> {
  return request().put(`${ROUTE}/${id}`);
}

export function useCode(code: string): Promise<Response> {
  return request().post(`user/cdkeys`, { code });
}
