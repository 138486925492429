import "react-vant/es/styles";
import "./styles/app.css";
import "./styles/vant-override.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./router/Router";
import { ConfigProvider } from "react-vant";
import { FC, useEffect } from "react";
import { RecoilRoot } from "recoil";
import useStorage from "./hooks/useStorage";
import { mp_clear_v1 } from "./config/access-keys";
import useClearLoginState from "./hooks/useClearLoginState";
import { Status } from "./utils/enum";
import dayjs from "dayjs";
import Timezone from "dayjs/plugin/timezone";

const App: FC = () => {
  const [mpClearVersion, setMpClearVersion] = useStorage(mp_clear_v1);
  const [clearLoginState] = useClearLoginState();

  const attemptClearLoginState = () => {
    if (!mpClearVersion || mpClearVersion == Status.inActive) {
      clearLoginState();
      setMpClearVersion(Status.active);
      window.location.reload();
    }
  };

  useEffect(() => {
    dayjs.extend(Timezone);
    attemptClearLoginState();
  }, []);

  return (
    <RecoilRoot>
      <ConfigProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ConfigProvider>
    </RecoilRoot>
  );
};

export default App;
