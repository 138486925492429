import { OrderVerifyFormValues, Response } from "../request";
import request from "../utils/request";
import { OrderActivitySale, OrderVerify } from "../entity";

export function getFirstByOrderNo(orderNo: string): Promise<OrderVerify> {
  return request().get("order-verify/" + orderNo);
}

export const create = (data: OrderVerifyFormValues): Promise<Response> => {
  return request().post(`order-verify`, data);
};

export function findUserActivitySaleOrderByOrderNo(
  orderNo: string
): Promise<OrderActivitySale> {
  return request().get(`order-verify/user-order/${orderNo}`);
}
