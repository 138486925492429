import request from "../utils/request";
import { PaginationQueryParams, PaginationResponse } from "../request";
import {
  Order,
  OrderActivityForm,
  OrderCheckstand,
  OrderFencingForm,
  OrderParkingFee,
} from "../entity";

const ROUTE = "user/orders";

export function getList(
  params: PaginationQueryParams
): Promise<PaginationResponse<Order>> {
  return request().get(ROUTE, { params });
}

export function getOne(orderNo: string): Promise<Order> {
  return request().get(`${ROUTE}/${orderNo}`);
}

export function findParkingFeeByOrderNo(
  orderNo: string
): Promise<OrderParkingFee> {
  return request().get(`${ROUTE}/parking-fee/${orderNo}`);
}

export function findCheckstandByOrderNo(
  orderNo: string
): Promise<OrderCheckstand> {
  return request().get(`${ROUTE}/checkstand/${orderNo}`);
}

export function findActivityFormByOrderNo(
  orderNo: string
): Promise<OrderActivityForm> {
  return request().get(`${ROUTE}/activity-form/${orderNo}`);
}

export function findFencingFormByOrderNo(
  orderNo: string
): Promise<OrderFencingForm> {
  return request().get(`${ROUTE}/fencing-form/${orderNo}`);
}
