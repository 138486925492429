import { FC } from "react";
import { Card, Skeleton } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { uniqueId } from "lodash";

const SpecialCardPlaceholder: FC = () => (
  <Card>
    <CardBody>
      <Skeleton row={5} rowHeight={17} title className="mb-3" />

      <Skeleton row={3} className="mb-6" />
      {[...new Array(3)].map(() => (
        <Skeleton
          key={uniqueId()}
          row={3}
          avatar
          avatarShape="square"
          avatarSize={90}
          className="mb-3"
        />
      ))}

      <Skeleton title row={5} rowWidth={["100%"]} />
    </CardBody>
  </Card>
);

export default SpecialCardPlaceholder;
