import request from "../utils/request";
import { OrderSpecialCard } from "../entity";

const ROUTE = "order-special-cards";

export function findByOrderNo(orderNo: string): Promise<OrderSpecialCard> {
  return request().get(`${ROUTE}/default/${orderNo}`);
}

export function findWithUserGroupBuys(
  orderNo: string
): Promise<OrderSpecialCard> {
  return request().get(`${ROUTE}/group-buy/${orderNo}`);
}

export function getSharePoster(orderNo: string): Promise<string> {
  return request().get(`${ROUTE}/group-buy/poster/${orderNo}`);
}
