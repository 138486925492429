import { FC, useEffect } from "react";
import useStorage from "../../hooks/useStorage";
import { WTH_TOKEN, WTH_USER } from "../../config/access-keys";

const ClearToken: FC = () => {
  const [, , removeToken] = useStorage(WTH_TOKEN);
  const [, , removeUser] = useStorage(WTH_USER);

  useEffect(() => {
    removeToken();
    removeUser();
  });

  return <div className={"p-10"}>授权信息已清理</div>;
};

export default ClearToken;
