import { FC, useContext } from "react";
import { Button, Image, Space } from "react-vant";
import SvgShare from "@react-vant/icons/es/Share";
import { UserGroupBuyContext } from "../../../../special-card/constant";
import { useNavigate } from "react-router-dom";
import { isNil } from "lodash";
import useStorage from "../../../../../hooks/useStorage";
import { WTH_USER } from "../../../../../config/access-keys";
import { User, UserGroupBuy } from "../../../../../entity";
import { Status } from "../../../../../utils/enum";

const GroupBuyFooter: FC = () => {
  const navigate = useNavigate();
  const [user] = useStorage<User>(WTH_USER);
  const context = useContext(UserGroupBuyContext);

  if (isNil(context)) return <></>;

  const myGroup = context.userGroupBuys.find(
    (o) => o.user_id === user.id
  ) as UserGroupBuy;

  return (
    <Space block justify={"between"} align={"center"}>
      <Space block gap={5} align={"center"}>
        {context.userGroupBuys.map((o) => (
          <Image key={o.id} round height={30} width={30} src={o.user.avatar} />
        ))}
      </Space>
      {context.leader.status !== Status.inActive ? (
        <></>
      ) : (
        <Button
          onClick={() =>
            navigate(
              "/activity/special-card/checkout/group-buy/result/" +
                myGroup.order_no
            )
          }
          type={"primary"}
          size={"small"}
          icon={<SvgShare />}
        >
          去邀请好友
        </Button>
      )}
    </Space>
  );
};

export default GroupBuyFooter;
