import React, { FC, useContext, useMemo } from "react";
import { CountDown, Space, Typography } from "react-vant";
import { getUnixTime } from "date-fns";
import { UserGroupBuyContext } from "../constant";
import { isNil } from "lodash";
import SvgClock from "@react-vant/icons/es/Clock";
import { Status } from "../../../utils/enum";

const GroupBuyResultTimeRemain: FC = () => {
  const context = useContext(UserGroupBuyContext);

  if (isNil(context) || context.leader.status !== Status.inActive) return <></>;

  const remainTime = () =>
    (context.leader.expire_at - getUnixTime(new Date())) * 1000;

  return useMemo(
    () => (
      <Space direction={"vertical"} block align={"center"} className={"pb-6"}>
        <Space block justify={"center"} align={"center"}>
          <SvgClock className={"text-2xl text-green-500"} />
          <Typography.Text size={"lg"}>剩余时间</Typography.Text>
          <Typography.Text size={"lg"}>
            <CountDown
              className={"inline-block text-red-500 text-lg"}
              time={remainTime()}
              format={"HH小时mm分ss秒"}
            />
          </Typography.Text>
        </Space>

        <Typography.Text>
          还差
          <span className={"font-bold px-1"}>
            {context.leader.finish_count - context.userGroupBuys.length}人
          </span>
          拼团成功
        </Typography.Text>
      </Space>
    ),
    [context.userGroupBuys]
  );
};

export default GroupBuyResultTimeRemain;
