import { Image } from "react-vant";
import { useBannerStore } from "../store";

const Banner = () => {
  const data = useBannerStore((state) => state.data);

  return (
    <Image
      height={240}
      width="100%"
      src={data?.image_url}
      onClick={() => {
        if (data?.click_url) window.location.href = data?.click_url;
      }}
    />
  );
};

export default Banner;
