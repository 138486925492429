import { FC, useContext, useMemo } from "react";
import { isNil } from "lodash";
import { Status } from "../../../../utils/enum";
import { Button, Image, NoticeBar, Space, Toast, Typography } from "react-vant";
import { findGroupLeader, GroupActionContext } from "../../constant";
import { User, UserGroupBuy } from "../../../../entity";
import useStorage from "../../../../hooks/useStorage";
import { WTH_USER } from "../../../../config/access-keys";

const CheckoutShareGroup: FC<{
  shareGroups: UserGroupBuy[];
}> = ({ shareGroups }) => {
  const groupActionContext = useContext(GroupActionContext);
  const [user] = useStorage<User>(WTH_USER);
  const groupLeader = findGroupLeader(shareGroups);

  const handleJoinGroup = (userGroupBuys: UserGroupBuy[]) => {
    if (!isNil(userGroupBuys.find((o) => o.user_id === user.id)))
      return Toast({
        message: "您已在队伍中",
        position: "bottom",
      });
    if (isNil(groupLeader))
      return Toast({
        message: "拼团信息获取失败",
        position: "bottom",
      });
    groupActionContext?.onJoinShareGroup?.(groupLeader.group_no);
  };

  if (isNil(groupLeader) || groupLeader.status !== Status.inActive)
    return <></>;

  return useMemo(
    () => (
      <NoticeBar
        scrollable={false}
        style={{ height: 60 }}
        rightIcon={
          <Button
            type={"warning"}
            className={"rounded"}
            onClick={() => handleJoinGroup(shareGroups)}
          >
            参与拼团
          </Button>
        }
      >
        <Space block align={"center"}>
          <Space block align={"center"} gap={3}>
            {shareGroups.map((o) => (
              <Image
                key={o.id}
                height={35}
                width={35}
                src={o.user.avatar}
                round
              />
            ))}
          </Space>
          <Typography.Text size={"lg"}>
            还差
            <span className={"px-1 text-red-500 font-bold"}>
              {groupLeader.finish_count - shareGroups.length}
            </span>
            人
          </Typography.Text>
        </Space>
      </NoticeBar>
    ),
    [shareGroups]
  );
};

export default CheckoutShareGroup;
