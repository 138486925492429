import { useEffect, useState } from "react";
import store from "store2";

const getDefaultStoreValue = (key: string, defaultValue?: any) => {
  return store.get(key, defaultValue || null);
};

const useStorage = <T>(
  key: string,
  defaultValue?: any
): [T, (value: T) => void, VoidFunction] => {
  const [storedValue, setStoredValue] = useState(
    getDefaultStoreValue(key, defaultValue)
  );

  const setStorageValue = (value: T) => {
    store.set(key, value);
    if (value !== storedValue) setStoredValue(value);
  };

  const removeStorageValue = () => store.remove(key);

  useEffect(() => {
    const value = getDefaultStoreValue(key);
    if (value) setStoredValue(value);
  }, []);

  return [storedValue, setStorageValue, removeStorageValue];
};

export default useStorage;
