import { useRequest, useTitle } from "ahooks";
import { findByOrderNo } from "../../../../requests/order-activity-sale";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  ActionBar,
  Card,
  Dialog,
  Divider,
  Image,
  NoticeBar,
  Skeleton,
  Space,
  Typography,
} from "react-vant";
import KefuImage from "../../../../assets/images/kefu.jpg";
import { CardBody } from "react-vant/es/card/Card";
import { OrderActivitySale } from "../../../../entity";
import { isNil, toNumber } from "lodash";
import {
  appUrl,
  getContactFullName,
  getMaskMobile,
} from "../../../../utils/util";
import {
  getPaidDescription,
  getPayTypeDescription,
} from "../../../../utils/desctriptor";
import { ContentCellGroup } from "../../../../type";
import ContentCard from "../Components/ContentCard";
import ContactPhoneImage from "../../../../assets/images/contact-merchant.png";
import SvgShare from "@react-vant/icons/es/Share";
import SvgScan from "@react-vant/icons/es/Scan";
import QRCode, { QRCodeSVG } from "qrcode.react";
import Poster from "../../../../assets/images/activity-sale-share-poster.png";
import html2canvas from "html2canvas";

const getItems = (order: OrderActivitySale): ContentCellGroup[] => [
  {
    title: "购买项目",
    children: order.user_merchant_projects.map((project) => {
      return {
        className: "px-0",
        titleClass: "ml-2",
        title: project.title,
        center: true,
        label: `有效期：${project.valid_time} 至 ${project.invalid_time}`,
        icon: (
          <Image src={project.figure_url} width={50} height={50} radius={5} />
        ),
        onClick: () => {
          window.location.href = `tel://${project.merchant_project.contact_phone}`;
        },
        rightIcon: <Image src={ContactPhoneImage} width={50} height={26} />,
      };
    }),
  },
  {
    title: "订单信息",
    children: [
      { title: "订单号", value: order.order_no },
      { title: "订单标题", value: order.order.brief },
      {
        title: "购买人",
        value: `${getContactFullName(
          order.user_merchant_projects[0].username,
          toNumber(order.user_merchant_projects[0].gender)
        )} ${getMaskMobile(order.user_merchant_projects[0].phone)}`,
      },
      {
        title: "报名时间",
        value: order.order.paid_at,
      },
      { title: "支付金额", value: `${order.order.total_fee}元` },
      {
        title: "支付方式",
        value: getPayTypeDescription(order.order.pay_type),
      },
      {
        title: "订单状态",
        value: getPaidDescription(order.order.paid_at),
      },
    ],
  },
];

export default function ActivitySaleView(): JSX.Element {
  useTitle("订单详情");
  const [openKefuQr, setOpenKefuQr] = useState(false);
  const [shareCanvas, setShareCanvas] = useState<string>();
  const [openVerifyOrder, setOpenVerifyOrder] = useState(false);
  const [openShareOrder, setOpenShareCanvas] = useState(false);
  const { orderNo } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderActivitySale>();
  const [items, setItems] = useState<ContentCellGroup[]>([]);
  const findRequest = useRequest(() => findByOrderNo(orderNo || ""), {
    manual: true,
    onSuccess: (data) => {
      if (!isNil(data)) {
        setOrder(data);
        setItems(getItems(data));
      } else navigate("/404");
    },
  });

  const handleOpenSharePoster = () => {
    if (shareCanvas) setOpenShareCanvas(true);
    else {
      setOpenShareCanvas(true);
      setTimeout(() => {
        const sharePosterContainer = document.getElementsByClassName(
          "share-poster-container"
        )[0];
        const targetPoster = document.getElementsByClassName(
          "share-poster-hidden"
        )[0];
        html2canvas(targetPoster as HTMLElement)
          .then((canvas) => {
            const img = sharePosterContainer.firstChild
              ?.firstChild as HTMLElement;
            const dataUrl = canvas.toDataURL("image/png");
            img.classList.remove("hidden");
            img.setAttribute("src", dataUrl);
            setShareCanvas(dataUrl);
          })
          .finally(() => {
            targetPoster.remove();
          });
      }, 500);
    }
  };

  useEffect(() => {
    if (orderNo) findRequest.run();
  }, []);

  if (order)
    return (
      <>
        <Space block direction="vertical" className="pb-48 px-2 pt-3" gap={10}>
          {items.map((item) => (
            <ContentCard key={item.title} item={item} />
          ))}
          <NoticeBar
            onClick={() => setOpenKefuQr(true)}
            wrapable
            text={
              <Typography.Text>
                邀请好友购课即可获得5元现金券，活动结束后，可联系文体汇客服提现，
                <Typography.Text className={"text-blue-500"}>
                  查看客服联系方式
                </Typography.Text>
              </Typography.Text>
            }
          />
          <ActionBar safeAreaInsetBottom>
            <ActionBar.Button
              type={"primary"}
              icon={<SvgScan />}
              onClick={() => setOpenVerifyOrder(true)}
            >
              核销课程
            </ActionBar.Button>
            <Divider type={"vertical"} />
            <ActionBar.Button
              type={"danger"}
              icon={<SvgShare />}
              onClick={handleOpenSharePoster}
            >
              邀请返利
            </ActionBar.Button>
          </ActionBar>
        </Space>
        <Dialog
          visible={openVerifyOrder}
          closeable
          closeOnClickOverlay
          onClose={() => setOpenVerifyOrder(false)}
          onConfirm={() => setOpenVerifyOrder(false)}
          showConfirmButton={false}
        >
          <Space
            block
            justify={"center"}
            direction={"vertical"}
            align={"center"}
            className={"pt-14 pb-6"}
          >
            <QRCodeSVG
              size={180}
              className={"mb-3"}
              value={`${appUrl()}/activity-sale/verify-order/${order.order_no}`}
            />
            <Typography.Text>扫描上方二维码核销课程</Typography.Text>
            <Typography.Text type={"danger"}>仅限文体汇商户</Typography.Text>
          </Space>
        </Dialog>
        <Dialog
          visible={openKefuQr}
          closeable
          closeOnClickOverlay
          showConfirmButton={false}
          onClose={() => setOpenKefuQr(false)}
        >
          <img src={KefuImage} alt="客服微信" />
        </Dialog>
        <Dialog
          visible={openShareOrder}
          closeable
          closeOnClickOverlay
          showConfirmButton={false}
          onClose={() => setOpenShareCanvas(false)}
          onConfirm={() => setOpenShareCanvas(false)}
          className={"share-poster-container"}
        >
          <img
            src={shareCanvas}
            alt="推广海报"
            className={"hidden align-middle"}
          />
          <div className={"share-poster-hidden relative"}>
            <img src={Poster} className={"align-middle"} />
            <QRCode
              className={"share-code absolute z-10"}
              style={{ left: "130px", bottom: "85px" }}
              size={60}
              value={`${appUrl()}/activity-sale/${
                order.activity_sale_id
              }?share_code=${order.promotion_reward_code}`}
            />
          </div>
        </Dialog>
      </>
    );

  return (
    <div className="pt-1">
      <Card className="mb-2">
        <CardBody className="px-0">
          <Skeleton title row={5} />
        </CardBody>
      </Card>
      <Card className="mb-2">
        <CardBody className="px-0">
          <Skeleton title row={5} />
        </CardBody>
      </Card>
      <Card className="mb-2">
        <CardBody className="px-0">
          <Skeleton title row={5} />
        </CardBody>
      </Card>
    </div>
  );
}
