import { Card, Space, Typography } from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { useRequest, useTitle } from "ahooks";
import { findById } from "../../requests/special-card-project";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SpecialCardProject } from "../../entity";
import SpecialCardProjectSubmitBar from "./Components/SpecialCardProjectSubmitBar";
import SpecialCardProjectViewHead from "./Components/SpecialCardProjectViewHead";
import HTMLReactParser from "html-react-parser";
import { toNumber } from "lodash";
import Placeholder from "./Components/Placeholder";
import "./Styles/index.css";

export default function SpecialCardProjectView(): JSX.Element {
  useTitle("项目详情");
  const { id } = useParams();
  const navigate = useNavigate();
  const [specialCardProject, setSpecialCardProject] =
    useState<SpecialCardProject>();
  const request = useRequest(findById, {
    manual: true,
  });

  const handleFetch = async (id: number) => {
    const response = await request.runAsync(id);
    if (response) setSpecialCardProject(response);
    else navigate("/404");
  };

  useEffect(() => {
    if (id) handleFetch(toNumber(id));
    else navigate("/404");
  }, [id]);

  if (specialCardProject)
    return (
      <>
        <Space direction="vertical" block gap={10} className="pb-24">
          <SpecialCardProjectViewHead specialCardProject={specialCardProject} />

          <Card>
            <CardHeader>图文详情</CardHeader>
            <CardBody className={"special-card-project-content"}>
              {HTMLReactParser(
                specialCardProject.activity_project.content || ""
              )}
            </CardBody>
          </Card>

          {specialCardProject.activity_project.tips?.length > 0 && (
            <Card>
              <CardHeader border>注意事项</CardHeader>
              <CardBody>
                <Space direction="vertical" gap={10}>
                  {specialCardProject.activity_project.tips.map((tip) => (
                    <Typography.Text key={tip.id}>
                      {tip.content}
                    </Typography.Text>
                  ))}
                </Space>
              </CardBody>
            </Card>
          )}

          <SpecialCardProjectSubmitBar
            specialCardProject={specialCardProject}
          />
        </Space>
      </>
    );

  return <Placeholder />;
}
