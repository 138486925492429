import { Empty, Image } from "react-vant";
import svgIcon from "../../../assets/svg";
import { useTitle } from "ahooks";

export default function Kabao(): JSX.Element {
  useTitle("卡包");
  const { StatusEmpty } = svgIcon;
  return (
    <div className="p-2">
      <Empty description="没有可用的卡券" image={<Image src={StatusEmpty} />} />
    </div>
  );
}
