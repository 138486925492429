import {
  PaginationQueryParams,
  PaginationResponse,
  Response,
  VoteParticipatorForm,
  VoteRaiseForm,
} from "../request";
import request from "../utils/request";
import { VoteParticipator } from "../entity";

const ROUTE = "/vote-participators";

export function getList(
  params: PaginationQueryParams
): Promise<PaginationResponse<VoteParticipator>> {
  return request().get(ROUTE, {
    params,
  });
}

export function findByVoteId(
  voteId: number | string
): Promise<VoteParticipator> {
  return request().get(`${ROUTE}/where-vote/${voteId}`);
}

export function getRankList(id: string): Promise<VoteParticipator[]> {
  return request().get(`${ROUTE}-rank`, {
    params: { vote_id: id },
  });
}

export function getListByVoteId(
  params: PaginationQueryParams
): Promise<PaginationResponse<VoteParticipator>> {
  return request().get(ROUTE, {
    params,
  });
}

export function giveVote(
  id: number | string,
  data: VoteRaiseForm
): Promise<Response> {
  return request().post(`${ROUTE}/raise/${id}`, data);
}

export function findById(
  id: number | string
): Promise<
  VoteParticipator & { current_rank: number; front_distance: number }
> {
  return request().get(`${ROUTE}/${id}`);
}

export function create(data: VoteParticipatorForm): Promise<Response> {
  return request().post(ROUTE, data);
}

export function update(
  id: number | string,
  data: VoteParticipatorForm
): Promise<Response> {
  return request().put(`${ROUTE}/${id}`, data);
}
