import { FC } from "react";
import { Skeleton } from "react-vant";
import Flex from "react-vant/es/flex/Flex";
import FlexItem from "react-vant/es/flex/FlexItem";
import { uniqueId } from "lodash";
import Card from "react-vant/es/card/Card";

const VoteLoader: FC = () => {
  return (
    <>
      <Card style={{ height: 300 }} />
      <div className={"min-h-screen bg-gray-100 px-3 pt-3"}>
        <Card className={"py-3 mb-3"} round>
          <Skeleton title={false} row={5} />
        </Card>
        <Card className={"py-3 mb-3"} round>
          <Skeleton title={false} row={2} />
        </Card>
        <Card>
          <Flex wrap={"wrap"} justify={"between"} gutter={10}>
            {new Array(2).fill(null).map(() => (
              <FlexItem
                key={uniqueId(Date.now().toString())}
                span={12}
                className={"mb-5"}
              >
                <Skeleton
                  title={false}
                  round={false}
                  rowHeight={240}
                  row={1}
                  className={"px-0 mb-3"}
                />
                <Skeleton title={false} row={3} style={{ height: 85 }} />
              </FlexItem>
            ))}
          </Flex>
        </Card>
      </div>
    </>
  );
};

export default VoteLoader;
