import request from "../utils/request";
import { UserWallet } from "../entity";
import { DataResponse, WxpayConfig } from "../request";
import { PayType } from "../utils/enum";

const ROUTE = "user/wallet";

export default function getOne(): Promise<DataResponse<UserWallet>> {
  return request().get(ROUTE);
}

export function addCredit(
  amount: number
): Promise<DataResponse<{ order_no: string; wxpay_config: WxpayConfig }>> {
  return request().post(`${ROUTE}/credit`, {
    amount,
    pay_type: PayType.wxpay,
  });
}
