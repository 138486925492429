import { ActionSheet, Empty, Image, Space, Typography } from "react-vant";
import { Delete } from "@react-vant/icons";
import { isEmpty } from "lodash";
import useActivityProjectOperation from "../../../hooks/useActivityProjectOperation";
import { useMemo } from "react";

export default function SpecialCardSubmitBarSheet({
  open,
  onClose,
}: {
  open: boolean;
  onClose: VoidFunction;
}): JSX.Element {
  const [selected, , removeSelected, clearSelected] =
    useActivityProjectOperation();

  return useMemo(
    () => (
      <ActionSheet visible={open} onCancel={onClose}>
        <Space
          block
          align="center"
          justify="between"
          className="p-3 border-b border-gray-100"
        >
          <Typography.Text size="lg">已选项目</Typography.Text>
          {!isEmpty(selected) && (
            <Typography.Text
              type="secondary"
              className="flex items-center  mr-3"
              onClick={clearSelected}
            >
              <Delete className="text-gray-400 mr-1" />
              清空
            </Typography.Text>
          )}
        </Space>
        {!isEmpty(selected) ? (
          <Space
            block
            direction="vertical"
            className="p-4 pb-10"
            gap={15}
            style={{ minHeight: "200px" }}
          >
            {selected.map((project) => (
              <Space key={project.id} justify="between" block>
                <Space block>
                  <Image
                    src={project.thumbnailUrl}
                    width={60}
                    height={60}
                    radius={5}
                    alt={project.title}
                  />
                  <Space direction="vertical" block>
                    <Typography.Title level={5} className="mb-0">
                      {project.title}
                    </Typography.Title>
                    <Space align="end">
                      <div className="flex items-center">
                        <Typography.Text
                          strong
                          size="xs"
                          className="text-red-500 mr-1"
                        >
                          ¥
                        </Typography.Text>
                        <Typography.Text
                          strong
                          size="lg"
                          className="text-red-500"
                        >
                          {project.currentPrice / 100}
                        </Typography.Text>
                      </div>
                      <Typography.Text
                        type="secondary"
                        size="xs"
                        className="line-through"
                      >
                        ¥{project.originPrice / 100}
                      </Typography.Text>
                    </Space>
                  </Space>
                </Space>
                <Typography.Text
                  className="flex items-center mr-3"
                  type="danger"
                  onClick={() => removeSelected(project.id)}
                >
                  移除
                </Typography.Text>
              </Space>
            ))}
          </Space>
        ) : (
          <Empty
            imageSize={70}
            style={{ minHeight: "200px" }}
            description="空空如也~"
          />
        )}
      </ActionSheet>
    ),
    [selected, open]
  );
}
