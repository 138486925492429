import { Button, Card, Space, Typography } from "react-vant";
import { FC, useMemo, useState } from "react";
import { ArrowUp } from "@react-vant/icons";
import SpecialCardSubmitBarSheet from "./SpecialCardSubmitBarSheet";
import useActivityProjectOperation from "../../../hooks/useActivityProjectOperation";
import FixedBottom from "../../../components/FixedBottom";
import { CardBody } from "react-vant/es/card/Card";

const SpecialCardSubmitBar: FC<{ onSubmit: VoidFunction }> = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [selected] = useActivityProjectOperation();

  const emptySelected = () => selected.length <= 0;

  const submitTip = (
    <Space align="center" justify="between" block onClick={() => setOpen(true)}>
      <div className={"mr-3"}>
        <Typography.Text size="md">已选数量</Typography.Text>
        <Typography.Text strong size="lg" className="text-black ml-1">
          {selected.length}
        </Typography.Text>
      </div>
      <div className="flex items-center">
        <Typography.Text className="mr-1 text-orange-500">查看</Typography.Text>
        <ArrowUp fontSize={20} />
      </div>
    </Space>
  );

  const submitButton = (
    <Button
      disabled={emptySelected()}
      type={"danger"}
      className="rounded font-bold"
      size={"large"}
      style={{ width: 120 }}
      onClick={onSubmit}
    >
      {emptySelected() ? "请选择项目" : "下一步"}
    </Button>
  );

  return useMemo(
    () => (
      <>
        <SpecialCardSubmitBarSheet open={open} onClose={() => setOpen(false)} />

        <FixedBottom safeArea={false}>
          <Card>
            <CardBody>
              <Space
                block
                justify={emptySelected() ? "end" : "between"}
                align={"center"}
              >
                {!emptySelected() && submitTip}
                {submitButton}
              </Space>
            </CardBody>
          </Card>
        </FixedBottom>
      </>
    ),
    [selected, open]
  );
};

export default SpecialCardSubmitBar;
