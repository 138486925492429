import { Flex, Image } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import { getUcenterBanner } from "../../requests/activity-banner";
import { useRequest } from "ahooks";
import { useEffect, useState } from "react";
import { ActivityBanner } from "../../entity";
import { isEmpty } from "lodash";

export default function Banner(): JSX.Element {
  const [banner, setBanner] = useState<ActivityBanner>();
  const { run } = useRequest(getUcenterBanner, {
    manual: true,
    onSuccess: (data) => setBanner(data),
  });
  useEffect(() => run(), []);

  if (isEmpty(banner)) return <div className={"mt-6"} />;

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      className="mt-6"
      style={{ minHeight: 100 }}
    >
      <FlexItem span={22}>
        <Image
          fit="cover"
          src={banner?.image_url}
          height={100}
          onClick={() => {
            window.location.href = banner?.click_url || "";
          }}
        />
      </FlexItem>
    </Flex>
  );
}
