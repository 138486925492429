import { Merchant } from "../entity";
import request from "../utils/request";
import { defaultQueryParams } from "../store/constant";
import { PaginationResponse } from "../request";

export function getAll(): Promise<PaginationResponse<Merchant>> {
  return request().get("merchants", {
    params: defaultQueryParams,
  });
}
