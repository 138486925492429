import request from "../utils/request";
import { ActivitySale } from "../entity";
import { ActivitySalePayForm, DataResponse, WxpayBody } from "../request";

const ROUTE = "activity-sales";

export function findById(id: number | string): Promise<ActivitySale> {
  return request().get(`${ROUTE}/${id}`);
}

export function pay(
  data: ActivitySalePayForm
): Promise<DataResponse<WxpayBody>> {
  return request().post(`${ROUTE}/pay`, data);
}
