import request from "../utils/request";
import { MerchantProject } from "../entity";
import { PaginationQueryParams, PaginationResponse } from "../request";

const ROUTE = "merchant-projects";

export function getListByActivitySaleId(
  query: PaginationQueryParams
): Promise<PaginationResponse<MerchantProject>> {
  return request().get(ROUTE, {
    params: query,
  });
}

export function findById(
  activitySaleId: number,
  id: number
): Promise<MerchantProject> {
  return request().get(`${ROUTE}/${id}`, {
    params: { activity_sale_id: activitySaleId },
  });
}
