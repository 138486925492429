import request from "../utils/request";
import { CarportAuthenticationParams, DataResponse } from "../request";
import { ParkingCard } from "../entity";

const ROUTE = "Parking/card";

// 根据车牌查询车主预留手机号
export function findCardByLicenseNo(licenseNo: string): Promise<
  DataResponse<{
    card_id: number;
    mobile: string;
  }>
> {
  return request().get(`${ROUTE}/license/${licenseNo}`);
}

// 根据用户信息查询已认证车位
export function findFullCardByLicenseNo(
  licenseNo: string
): Promise<ParkingCard | null> {
  return request().get(`${ROUTE}/${licenseNo}`);
}

// 车主认证
export function auth(
  data: CarportAuthenticationParams
): Promise<DataResponse<ParkingCard>> {
  return request().post(`${ROUTE}/auth`, data);
}
