import "vehicle-plate-keyboard/dist/main.css";
import "./index.css";
import LicenseInput from "../../../components/_parking/_home/LicenseInput";
import { Toast } from "react-vant";
import { useRequest, useTitle } from "ahooks";
import { findCardByLicenseNo } from "../../../requests/parking-card";
import { useNavigate } from "react-router-dom";
import {
  getCarportCredential,
  setLicenseNoSearchHistory,
} from "../../../utils/storage";
import { Fail } from "@react-vant/icons";
import { useSetRecoilState } from "recoil";
import { carportIdentityAtom } from "../../../store/atoms";
import { find, first } from "lodash";

export default function LicenseSearch(): JSX.Element {
  useTitle("车位信息查询");
  const navigate = useNavigate();
  const setCarportIdentity = useSetRecoilState(carportIdentityAtom);
  const hasLicense = (cardId: number) => {
    return find(
      getCarportCredential(),
      (credential) => credential.cardId === cardId
    );
  };
  const searchRequest = useRequest(findCardByLicenseNo, {
    manual: true,
    onSuccess: (data) => {
      if (data.success) {
        const { card_id, mobile } = data.data;
        setCarportIdentity({ mobile, cardId: card_id });
        navigate(
          hasLicense(card_id)
            ? `/parking/credit/${first(searchRequest.params)}`
            : `/parking/auth/${first(searchRequest.params)}`
        );
        Toast.clear();
      } else {
        Toast.info({
          icon: <Fail />,
          message: data.message,
        });
      }
    },
  });
  const onSubmit = (value: string) => {
    setLicenseNoSearchHistory(value);
    Toast.loading({
      message: "查询中..",
      forbidClick: true,
    });
    searchRequest.run(value);
  };
  return (
    <div className="pt-6 px-5">
      <LicenseInput onSubmit={onSubmit} />
    </div>
  );
}
