import { FC } from "react";
import { Card, Space } from "react-vant";

const GroupBuyHeader: FC = () => {
  return (
    <Space block justify={"between"} align={"center"}>
      <Card.Header border>最近发布的拼团</Card.Header>
      {/*      <Typography.Text
        type={"secondary"}
        className={"mr-3"}
        onClick={() => navigate("/group-buy")}
      >
        查看更多
        <Arrow className={"inline mb-1"} />
      </Typography.Text>*/}
    </Space>
  );
};

export default GroupBuyHeader;
