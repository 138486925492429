import { Card, Cell, Space, Toast, Typography } from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import config from "../../../config/config";
import CreditDurationCell from "./CreditDurationCell";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { carportCreditMonthAtom, parkingCardAtom } from "../../../store/atoms";
import {
  carportCreditBeginAtSelector,
  carportEndAtAfterCreditSelector,
  carportLicenseNoSelector,
  carportTypeNameSelector,
  defaultCarportSelector,
  friendlyEndAtSelector,
} from "../../../store/selectors";
import { useEffect, useState } from "react";
import { useRequest } from "ahooks";
import { findFullCardByLicenseNo } from "../../../requests/parking-card";
import { isEmpty } from "lodash";
import { clearCarportCredential } from "../../../utils/storage";

export default function CarportInfo(): JSX.Element {
  const navigate = useNavigate();
  const { licenseNo } = useParams();
  const setParkingCard = useSetRecoilState(parkingCardAtom);
  const defaultCarport = useRecoilValue(defaultCarportSelector);
  const carportLicenseNo = useRecoilValue(carportLicenseNoSelector);
  const carportTypeName = useRecoilValue(carportTypeNameSelector);
  const friendlyEndAt = useRecoilValue(friendlyEndAtSelector);
  const setCarportCreditMonth = useSetRecoilState(carportCreditMonthAtom);
  const carportCreditBeginAt = useRecoilValue(carportCreditBeginAtSelector);
  const carportEndAtAfterCredit = useRecoilValue(
    carportEndAtAfterCreditSelector
  );
  const [openSheet, setOpenSheet] = useState(false);
  const parkingCardRequest = useRequest(findFullCardByLicenseNo, {
    manual: true,
    onSuccess: (data) => {
      if (isEmpty(data)) {
        clearCarportCredential();
        navigate("/Parking/license-search", { replace: true });
      }
      setParkingCard(data);
      Toast.clear();
    },
  });

  useEffect(() => {
    Toast.loading("");
    parkingCardRequest.run(licenseNo as string);
  }, []);
  return (
    <Space direction="vertical" gap={10} block>
      <Card round border>
        <CardHeader
          extra={
            friendlyEndAt && <Typography.Text>{friendlyEndAt}</Typography.Text>
          }
        >
          车位编号
        </CardHeader>
        <CardBody>
          <Space justify="between" block>
            <Space direction="vertical">
              <Typography.Text size="xxl" className="font-bold" type="danger">
                {defaultCarport?.alias}
              </Typography.Text>
              <Typography.Text type="secondary">
                {config.northCarportName}
              </Typography.Text>
            </Space>
          </Space>
        </CardBody>
      </Card>
      <Card round border>
        <CardHeader>缴费选项</CardHeader>
        <Cell title="车位类型" border={false} value={carportTypeName} />
        <Cell title="通行车牌" border={false} value={carportLicenseNo} />
        <CreditDurationCell
          open={openSheet}
          onSelected={setCarportCreditMonth}
          setOpen={setOpenSheet}
        />
      </Card>
      <Card round border>
        <CardHeader>到期时间</CardHeader>
        <Cell title="缴费开始时间" value={carportCreditBeginAt} />
        <Cell title="续费后" value={carportEndAtAfterCredit} />
      </Card>
    </Space>
  );
}
