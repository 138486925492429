import React, { FC, useContext, useState } from "react";
import { Button, Dialog, Image, Toast } from "react-vant";
import { useNavigate } from "react-router-dom";
import { OrderSpecialCard } from "../../../entity";
import { UserGroupBuyContext } from "../constant";
import { isNil } from "lodash";
import { useRequest } from "ahooks";
import { getSharePoster } from "../../../requests/order-special-card";

const GroupBuyResultAction: FC<{
  orderSpecialCard: OrderSpecialCard;
}> = ({ orderSpecialCard }) => {
  const [poster, setPoster] = useState("");
  const [openPoster, setOpenPoster] = useState(false);
  const context = useContext(UserGroupBuyContext);
  const navigate = useNavigate();
  const request = useRequest(getSharePoster, { manual: true });

  if (isNil(context)) return <></>;

  const handleShare = async (orderNo: string) => {
    Toast.loading("");
    const response = await request.runAsync(orderNo);
    if (response) {
      setPoster(response);
      setOpenPoster(true);
      Toast.clear();
    }
  };

  return (
    <>
      <Button
        block
        type={"danger"}
        className={"mb-2"}
        onClick={() => handleShare(orderSpecialCard.order_no)}
      >
        立即分享
      </Button>

      <Button
        block
        plain
        type={"danger"}
        onClick={() =>
          navigate(
            "/activity/special-card/poster/" + orderSpecialCard.special_card_id
          )
        }
      >
        返回活动首页
      </Button>

      <Dialog
        visible={openPoster}
        onConfirm={() => setOpenPoster(false)}
        onCancel={() => setOpenPoster(false)}
        showConfirmButton={false}
        closeOnClickOverlay
        onClose={() => setOpenPoster(false)}
      >
        <Image src={poster} onClick={() => setOpenPoster(false)} />
      </Dialog>
    </>
  );
};

export default GroupBuyResultAction;
