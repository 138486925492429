import { FC, ReactNode } from "react";

const FixedBottom: FC<{ children: ReactNode; safeArea?: boolean }> = ({
  children,
  safeArea = true,
}) => {
  return (
    <div
      className={`fixed bottom-0 left-0 w-full${
        safeArea ? " rv-safe-area-bottom" : ""
      }`}
      style={{
        zIndex: "var(--rv-submit-bar-z-index)",
      }}
    >
      {children}
    </div>
  );
};

export default FixedBottom;
