import { Card, Skeleton } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";

const Placeholder = () => (
  <>
    {new Array(3).map(() => (
      <Card>
        <CardBody>
          <Skeleton title row={3} />
        </CardBody>
      </Card>
    ))}
  </>
);

export default Placeholder;
