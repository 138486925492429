import { createStore } from "hox";
import { useState } from "react";
import { Contact } from "../../../entity";
import { useRequest } from "ahooks";
import { getList } from "../../../requests/contact";

export const [useContactStore, ContactStoreProvider] = createStore(() => {
  const [list, setList] = useState<Contact[]>([]);
  const request = useRequest(getList, { manual: true });

  const handleFetch = async () => {
    setList(await request.runAsync());
  };

  return {
    data: list,
    handleFetch,
    loading: request.loading,
  };
});
