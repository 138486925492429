import { useEffect, useState } from "react";
import "./index.css";
import {
  Flex,
  Image,
  NumberKeyboard,
  Space,
  Toast,
  Typography,
} from "react-vant";
import { chunk, first, isEmpty, isNil, padEnd, split, toNumber } from "lodash";
import FlexItem from "react-vant/es/flex/FlexItem";
import LogoMini from "../../assets/logo-mini.png";
import { PayType } from "../../utils/enum";
import CheckstandHeader from "../../components/_checkstand/Header";
import { useNavigate, useParams } from "react-router-dom";
import { Merchant } from "../../entity";
import { useRequest, useTitle } from "ahooks";
import { findMerchantByCode, pay } from "../../requests/checkstand";
import Cashier from "../../components/Cashier";
import CheckstandInput from "../../components/_checkstand/Input";
import { handleWxpay, isDev } from "../../utils/util";
import { setCheckstandOrder } from "../../utils/storage";
import { useSetRecoilState } from "recoil";
import { checkstandOrderAtom } from "../../store/atoms";
import { CheckstandOrder } from "../../store";
import Placeholder from "../../components/Placeholder/Placeholder";

interface PayMoney {
  stringVal: string;
  intVal: number; // 单位：分
}

export default function Checkstand(): JSX.Element {
  useTitle("门店付款");
  const navigate = useNavigate();
  const { code } = useParams();
  const setOrder = useSetRecoilState(checkstandOrderAtom);
  const [payMoney, setPayMoney] = useState<PayMoney>({
    stringVal: "",
    intVal: 0,
  });
  const [keyboard, setKeyboard] = useState(false);
  const [remark, setRemark] = useState("");
  const [merchant, setMerchant] = useState<Merchant>();
  const [openCashier, setOpenCashier] = useState(false);
  const findRequest = useRequest(() => findMerchantByCode(code as string), {
    manual: true,
    onSuccess: (data) => {
      if (isNil(data)) navigate("/");
      else {
        setMerchant(data.data);
        setKeyboard(true);
      }
    },
  });
  const ensureIntval = (value: string): string => {
    if (value.indexOf(".") !== -1) {
      // 多个.分段，取首个有效值数组
      const valueChunk = chunk(split(value, "."), 2) as string[][];
      const [head, end] = first(valueChunk) as string[];
      return `${head + padEnd(end, 2, "0")}`;
    }
    return `${value}00`;
  };
  const handlePayMoneyChange = (value: string): void => {
    if (/^\d+[.]?[\d]{0,2}$/.test(value) || isEmpty(value)) {
      setPayMoney({
        stringVal: value,
        intVal: toNumber(ensureIntval(value)),
      });
    }
  };
  const confirm = () => {
    if (payMoney.intVal > 0) setOpenCashier(true);
    else Toast({ message: "请输入支付金额", position: "bottom" });
  };
  const paySuccess = (orderNo: string) => {
    Toast.clear();
    navigate(`/checkstand/result/${orderNo}`);
  };
  const payRequest = useRequest(pay, {
    manual: true,
  });
  const onSubmit = (value: PayType) => {
    Toast.loading({
      message: "支付中",
      forbidClick: true,
    });
    return new Promise<null>((resolve, reject) => {
      const storeProps: CheckstandOrder = {
        merchantName: merchant?.merchant_name || "",
        payMoney: payMoney.intVal / 100,
        tsCode: code || "",
        payType: value,
      };
      setCheckstandOrder(storeProps);
      setOrder(storeProps);
      payRequest
        .runAsync({
          pay_type: value,
          fee: payMoney.intVal / 100,
          transaction_code: code || "",
          remark,
        })
        .then(async (data) => {
          if (data.success) {
            const payload = data.data;
            if (value === PayType.wallet) {
              resolve(null);
              paySuccess(payload.order_no);
            } else {
              try {
                await handleWxpay(payload.wxpay_config);
                resolve(null);
                paySuccess(payload.order_no);
              } catch (e: any) {
                reject(e.message);
              } finally {
                if (isDev()) paySuccess(payload.order_no);
              }
            }
          } else {
            reject();
            Toast({
              message: data.errors?.first || data.message,
              position: "bottom",
            });
          }
        })
        .catch((e) => reject(e.message));
    });
  };
  const keyboardTitle = (
    <Flex justify="center" align="center" gutter={5}>
      <FlexItem>
        <Image src={LogoMini} width={18} height={18} />
      </FlexItem>
      <FlexItem>
        <Typography.Text type="secondary">武汉文体汇</Typography.Text>
      </FlexItem>
    </Flex>
  );
  useEffect(() => findRequest.run(), []);

  if (findRequest.loading) return <Placeholder rows={18} />;

  if (!merchant) navigate("/404");

  return (
    <>
      <Space block justify="end" className="px-3 py-2 h-5">
        {!findRequest.loading && (
          <Typography.Text
            onClick={() => navigate(`/checkstand/detail/${code}`)}
          >
            账单
          </Typography.Text>
        )}
      </Space>
      <div className="container rv-checkstand pt-10">
        <CheckstandHeader merchant={merchant} />
        <>
          <CheckstandInput
            payMoney={payMoney.stringVal}
            remark={remark}
            setRemark={setRemark}
          />
          <NumberKeyboard
            visible={keyboard}
            title={keyboardTitle}
            value={payMoney.stringVal}
            theme="custom"
            extraKey={["00", "."]}
            safeAreaInsetBottom
            closeButtonText="立即支付"
            onClose={confirm}
            onChange={handlePayMoneyChange}
          />
        </>
        <Cashier
          open={openCashier}
          onClose={() => setOpenCashier(false)}
          price={payMoney.intVal / 100}
          onSubmit={onSubmit}
        />
      </div>
    </>
  );
}
