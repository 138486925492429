import { FC, useEffect, useState } from "react";
import { Vote as VoteEntity } from "../../entity";
import { useRequest, useTitle } from "ahooks";
import { findById } from "../../requests/vote";
import { Skeleton } from "react-vant";
import { useNavigate, useParams } from "react-router-dom";
import { isNil } from "lodash";
import ParticipatorViewContent from "./components/ParticipatorViewContent";
import SwipeBanner from "../../components/SwipeBanner";

const ParticipatorView: FC = () => {
  useTitle("选手介绍");
  const { id } = useParams();
  const navigate = useNavigate();

  const [vote, setVote] = useState<VoteEntity | null>(null);

  const request = useRequest(findById, { manual: true });

  const fetchData = () => {
    request.runAsync(id || "").then((data) => {
      if (data) setVote(data);
      else navigate("/404");
    });
  };

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  if (request.loading || isNil(vote))
    return (
      <Skeleton
        title={false}
        round={false}
        rowHeight={300}
        row={1}
        className={"px-0 mb-3"}
      />
    );

  return (
    <div className={"min-h-screen bg-gray-100 pb-32"}>
      <SwipeBanner banners={vote.banners} />
      <ParticipatorViewContent vote={vote} />
    </div>
  );
};

export default ParticipatorView;
