import { Button, Card, Dialog, Space, Toast, Typography } from "react-vant";
import { CardBody, CardFooter, CardHeader } from "react-vant/es/card/Card";
import React, { FC, useState } from "react";
import { useAsyncEffect, useRequest, useTitle } from "ahooks";
import { useNavigate, useParams } from "react-router-dom";
import SvgChecked from "@react-vant/icons/es/Checked";
import { findByOrderNo } from "../../requests/order-activity-sale";
import { OrderActivitySale } from "../../entity";
import SvgArrow from "@react-vant/icons/es/Arrow";
import Poster from "../../assets/images/activity-sale-share-poster.png";
import { QRCodeCanvas } from "qrcode.react";
import { appUrl } from "../../utils/util";
import html2canvas from "html2canvas";
import useStorage from "../../hooks/useStorage";
import { Share_Code } from "../../config/access-keys";
import { tr } from "date-fns/locale";

const SpecialCardCheckoutResult: FC = () => {
  useTitle("订单支付结果");
  const [loading, setLoading] = useState(true);
  const { orderNo } = useParams();
  const [shareCode] = useStorage<string | null>(Share_Code);
  const navigate = useNavigate();
  const [shareCanvas, setShareCanvas] = useState<string>();
  const [openShareOrder, setOpenShareCanvas] = useState(false);
  const [orderActivitySale, setOrderActivitySale] =
    useState<OrderActivitySale>();
  const request = useRequest(findByOrderNo, {
    manual: true,
    loadingDelay: 1000,
  });

  const handleFetch = async (orderNo: string) => {
    setLoading(true);
    setTimeout(() => {
      request
        .runAsync(orderNo)
        .then((response) => {
          setOrderActivitySale(response);
          handleOpenSharePoster();
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1500);
  };

  const handleOpenSharePoster = () => {
    if (shareCanvas) setOpenShareCanvas(true);
    else {
      setOpenShareCanvas(true);
      setTimeout(() => {
        const sharePosterContainer = document.getElementsByClassName(
          "share-poster-container"
        )[0];
        const targetPoster = document.getElementsByClassName(
          "share-poster-hidden"
        )[0];
        html2canvas(targetPoster as HTMLElement, {
          width: 320,
          height: 620,
        })
          .then((canvas) => {
            const img = sharePosterContainer.firstChild
              ?.firstChild as HTMLElement;
            const dataUrl = canvas.toDataURL("image/png");
            img.classList.remove("hidden");
            img.setAttribute("src", dataUrl);
            setShareCanvas(dataUrl);
          })
          .finally(() => {
            targetPoster.remove();
          });
      }, 500);
    }
  };

  useAsyncEffect(async () => {
    if (orderNo) await handleFetch(orderNo);
  }, [orderNo]);

  if (loading) {
    Toast.loading("加载中");
    return <></>;
  }
  if (orderActivitySale)
    return (
      <>
        <Space direction="vertical" block gap={10}>
          <Card>
            <CardBody className="pt-8">
              <Space block direction="vertical" align="center">
                <SvgChecked fontSize={65} className="text-green-500" />
                <Typography.Text strong size="lg" className="text-black mb-3">
                  购买成功
                </Typography.Text>
                <Space direction={"vertical"} align={"center"} gap={1}>
                  <Typography.Text type="secondary">
                    订单支付成功，稍后商户会与您联系
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    请保持电话畅通
                  </Typography.Text>
                </Space>
              </Space>
              <Space direction={"vertical"} block className={"mt-8 w-full"}>
                <Button
                  type={"danger"}
                  block
                  onClick={() => navigate("/user/orders")}
                >
                  查看我的订单
                </Button>
                <Button
                  block
                  onClick={() =>
                    navigate(
                      `/activity-sale/${orderActivitySale?.activity_sale_id}?share_code=${shareCode}`
                    )
                  }
                >
                  返回活动首页
                </Button>
              </Space>
            </CardBody>
          </Card>
          <Card onClick={handleOpenSharePoster}>
            <CardHeader>温馨提示</CardHeader>
            <CardBody>
              <Space direction={"vertical"}>
                <Typography.Text>
                  1、搜索并关注"
                  <Typography.Text type={"danger"} className={"font-bold"}>
                    武汉文体汇
                  </Typography.Text>
                  "微信公众号，在 会员中心-我的订单 查看已购买课程
                </Typography.Text>
                <Typography.Text>
                  2、在购课订单中转发活动海报至好友，好友下单成功后，您即可获得5元现金券,
                  上不封顶。
                </Typography.Text>
                <Typography.Text>
                  3、现金券可在活动结束后提现，具体流程请咨询文体汇客服
                </Typography.Text>
              </Space>
            </CardBody>
            <CardFooter>
              <Space align={"center"} className={"text-orange-500 font-bold"}>
                <Typography.Text type={"warning"}>点击转发</Typography.Text>
                <SvgArrow />
              </Space>
            </CardFooter>
          </Card>
          <Dialog
            visible={openShareOrder}
            closeable
            closeOnClickOverlay
            showConfirmButton={false}
            onClose={() => setOpenShareCanvas(false)}
            onConfirm={() => setOpenShareCanvas(false)}
            className={"share-poster-container"}
          >
            <img
              src={shareCanvas}
              alt="推广海报"
              className={"hidden align-middle"}
            />
            <div className={"share-poster-hidden relative"}>
              <img src={Poster} className={"align-middle"} />
              <QRCodeCanvas
                className={"share-code absolute z-10"}
                style={{ left: "130px", bottom: "85px" }}
                size={60}
                value={`${appUrl()}/activity-sale/${
                  orderActivitySale.activity_sale_id
                }?share_code=${orderActivitySale.promotion_reward_code}`}
              />
            </div>
          </Dialog>
        </Space>
      </>
    );
  return <></>;
};

export default SpecialCardCheckoutResult;
