import { Toast, Typography } from "react-vant";
import config from "../../config/config";
import { useCountDown } from "react-vant/es/hooks";
import { useRequest } from "ahooks";
import { getMessageCode } from "../../requests/auth";
import { useEffect } from "react";

export default function MessageCountDown({
  mobile,
}: {
  mobile: string;
}): JSX.Element {
  const messageRequest = useRequest(() => getMessageCode(mobile), {
    manual: true,
    throttleWait: 1000,
    onSuccess: (data) => {
      if (!data.success) {
        Toast({ message: data.message, position: "bottom" });
      }
    },
  });
  const { start, reset, current } = useCountDown({
    time: config.messageInterval,
  });
  const handleRefreshSeconds = () => {
    reset(config.messageInterval);
    start();
  };
  useEffect(() => {
    start();
    messageRequest.run();
  }, []);
  return current.seconds > 0 ? (
    <Typography.Text type="secondary">
      {current.seconds}s后重新获取
    </Typography.Text>
  ) : (
    <Typography.Text underline onClick={handleRefreshSeconds}>
      重新获取
    </Typography.Text>
  );
}
