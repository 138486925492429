import useAuth from "../hooks/authenticate";
import { useEffect, useState } from "react";
import { AuthenticateState } from "../utils/enum";
import { Navigate, useLocation } from "react-router-dom";
import { getUser } from "../utils/storage";
import { isNil } from "lodash";

export default function Auth({
  needLogin,
  children,
}: {
  needLogin: boolean;
  children: JSX.Element;
}): JSX.Element | null {
  const location = useLocation();
  const user = getUser();
  const [nextPage, setNextPage] = useState<JSX.Element | null>(null);
  const authenticated = useAuth();

  useEffect(() => {
    if (authenticated === AuthenticateState.authenticated) {
      if (needLogin && isNil(user.mobile)) {
        setNextPage(
          <Navigate
            to={`/login?refer=${location.pathname}`}
            state={location.state}
            replace
          />
        );
      } else {
        setNextPage(children);
      }
    }
  }, [authenticated]);

  return nextPage;
}
