import { FC, useEffect, useState } from "react";
import { Tabbar, TabbarItem, TabbarItemProps } from "react-vant";
import {
  Add,
  AddO,
  Ascending,
  Label,
  LabelO,
  ThumbCircle,
  ThumbCircleO,
} from "@react-vant/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";

type VoteTabbarProps = TabbarItemProps & {
  link: string;
};

const VoteTabbar: FC = () => {
  const { id } = useParams();
  const [active, setActive] = useState<number | string>("0");
  const navigate = useNavigate();
  const location = useLocation();

  const items: VoteTabbarProps[] = [
    {
      children: "投票",
      icon: (active) => (active ? <ThumbCircle /> : <ThumbCircleO />),
      link: `/vote/${id}`,
    },
    {
      children: "我要报名",
      icon: (active) => (active ? <Add /> : <AddO />),
      link: `/vote/${id}/signup`,
    },
    {
      children: "排行",
      icon: (active) => (active ? <Ascending /> : <Ascending />),
      link: `/vote/${id}/ranklist`,
    },
    {
      children: "活动介绍",
      icon: (active) => (active ? <Label /> : <LabelO />),
      link: `/vote/${id}/description`,
    },
  ];

  const isActiveLink = (link: string) => location.pathname === link;

  const initActiveTab = () => {
    items.map((item, index) => {
      if (isActiveLink(items[index].link)) setActive(`${index}`);
    });
  };

  useEffect(() => {
    initActiveTab();
  }, []);

  return (
    <Tabbar value={active}>
      {items.map((item, index) => (
        <TabbarItem
          key={item.link}
          name={`${index}`}
          icon={item.icon}
          onClick={() => {
            if (isActiveLink(item.link)) window.location.reload();
            else navigate(item.link);
          }}
        >
          {item.children}
        </TabbarItem>
      ))}
    </Tabbar>
  );
};

export default VoteTabbar;
