import { FC, useState } from "react";
import Card from "react-vant/es/card/Card";
import { Empty, List, Search } from "react-vant";
import { VoteParticipator } from "../../../entity";
import { getListByVoteId } from "../../../requests/vote-participator";
import { PaginationQueryParams } from "../../../request";
import { useRequest, useSetState } from "ahooks";
import Participator from "./Participator";
import Button from "react-vant/es/button/Button";
import { isEmpty } from "lodash";

const ParticipatorList: FC<{ voteId: number }> = ({ voteId }) => {
  const [participators, setParticipators] = useState<VoteParticipator[]>([]);
  const [finished, setFinished] = useState(false);
  const [keywords, setKeywords] = useState<string>("");
  const [params, setParams] = useSetState<PaginationQueryParams>({
    page: 1,
    take: 8,
    vote_id: voteId,
  });
  const request = useRequest(getListByVoteId, { manual: true });
  const fetchData = async () => {
    const response = await request.runAsync(
      keywords ? { ...params, page: 1, keywords } : params
    );
    const { data, meta } = response;
    if (keywords) {
      setParticipators(data);
      setFinished(true);
    } else {
      const append = [...participators, ...data];
      setParticipators(append);
      setParams({
        page: params.page + 1,
      });
      setFinished(append.length >= meta.total);
    }
  };

  const handleSearch = async () => {
    setParticipators([]);
    await fetchData();
  };

  return (
    <>
      <Card round className={"p-2 mb-3"}>
        <Search
          value={keywords}
          className={"vote-search"}
          showAction
          onChange={setKeywords}
          actionText={
            <Button
              type={"primary"}
              className={"px-5"}
              size={"small"}
              onClick={handleSearch}
            >
              搜索
            </Button>
          }
          placeholder={"请输入选手编号/姓名"}
        />
      </Card>

      <Card style={{ minHeight: 450 }} className={"mt-3"}>
        <List
          onLoad={fetchData}
          finished={finished}
          finishedText={"已加载全部~"}
          offset={50}
        >
          <Participator participators={participators} />
          {finished && isEmpty(participators) && <Empty />}
        </List>
      </Card>
    </>
  );
};

export default ParticipatorList;
