import { SpecialCardProject } from "../entity";
import request from "../utils/request";
import { PaginationQueryParams, PaginationResponse } from "../request";

const ROUTE = "special-card-projects";

export function getList(
  query: PaginationQueryParams
): Promise<PaginationResponse<SpecialCardProject>> {
  return request().get(ROUTE, {
    params: query,
  });
}

export function findById(id: number | string): Promise<SpecialCardProject> {
  return request().get(`${ROUTE}/${id}`);
}
