import { JSApis } from "weixin-js-sdk";
import { DataResponse, WxSdkConfig } from "../request";
import request from "../utils/request";

const ROUTE = "wx/sdk-configs";

export default function getSdkConfig(
  chosen: JSApis[]
): Promise<DataResponse<WxSdkConfig>> {
  return request().get(ROUTE, {
    params: { auth_url: location.href, chosen },
  });
}
