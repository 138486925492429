import { Space, Typography } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { ParkingOrderBody } from "../../../../request";
import { useShallow } from "zustand/react/shallow";
import { useMemoizedFn } from "ahooks";
import {
  useParkingCouponStore,
  useParkingOrderStore,
  useUserCouponStore,
} from "../store";

const ParkingOrderDetail = () => {
  const { order, useUserCoupon } = useParkingOrderStore(
    useShallow((state) => ({
      order: state.data,
      useUserCoupon: state.useUserCoupon,
    }))
  );
  const selectedCoupon = useUserCouponStore((state) => state.selectedCoupon);
  const parkingCouponDiscountMoney = useParkingCouponStore(
    (state) => state.discountMoney
  );

  const totalPay = useMemoizedFn((data: ParkingOrderBody) => {
    let money: number;
    if (useUserCoupon && selectedCoupon)
      money = data.should_pay - selectedCoupon.coupon_amount;
    else money = data.should_pay - parkingCouponDiscountMoney;
    return money > 0 ? money : 0;
  });

  const getParkingCouponDiscountMoney = useMemoizedFn(
    (data: ParkingOrderBody) => {
      if (useUserCoupon) return 0;
      return parkingCouponDiscountMoney > data.payable
        ? data.payable
        : parkingCouponDiscountMoney;
    }
  );

  if (!order) return <></>;

  return (
    <CardBody className="mb-24">
      <Space block direction="vertical" justify="between" gap={15}>
        <Space gap={10}>
          <Typography.Title level={4}>需缴费</Typography.Title>
          <Typography.Title level={4} type="danger">
            ¥{totalPay(order) / 100}
          </Typography.Title>
        </Space>
        <Space block justify="between" align="center">
          <Typography.Text type="secondary">停车费</Typography.Text>
          <Typography.Text type="secondary">
            - ¥{order.payable / 100}
          </Typography.Text>
        </Space>
        <Space block justify="between" align="center">
          <Typography.Text type="secondary">商家抵扣</Typography.Text>
          <Typography.Text type="secondary">
            - ¥{getParkingCouponDiscountMoney(order) / 100}
          </Typography.Text>
        </Space>
        <Space block justify="between" align="center">
          <Typography.Text type="secondary">优惠</Typography.Text>
          <Typography.Text type="secondary">
            - ¥{selectedCoupon ? selectedCoupon.coupon_amount / 100 : 0}
          </Typography.Text>
        </Space>
      </Space>
    </CardBody>
  );
};

export default ParkingOrderDetail;
