import UserMenu from "../../components/_user/UserMenu";
import UserHeader from "../../components/_user/UserHeader";
import Banner from "../../components/_user/Banner";
import "./index.css";
import { useTitle } from "ahooks";

export default function UserHome() {
  useTitle("会员中心");

  return (
    <>
      <div className="rv-user-curtain">
        <UserHeader />
        <Banner />
      </div>
      <UserMenu />
    </>
  );
}
