import { Flex, Image, Typography } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import { getUser } from "../../utils/storage";

export default function UserHeader(): JSX.Element {
  const user = getUser();
  return (
    <Flex direction="column" align="center" gutter={[0, 10]}>
      <FlexItem>
        <Image src={user.avatar} width={70} height={70} round />
      </FlexItem>
      <FlexItem>
        <Flex direction="column" align="center">
          <FlexItem>
            <Typography.Text size="lg" className="font-bold">
              {user.nickname}
            </Typography.Text>
          </FlexItem>
        </Flex>
      </FlexItem>
    </Flex>
  );
}
