import { Card, Divider, Flex, Image, Space, Typography } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import FlexItem from "react-vant/es/flex/FlexItem";
import { Order } from "../../../../entity";
import svgIcon from "../../../../assets/svg";
import { useNavigate } from "react-router-dom";
import { FC } from "react";

const ParkingFeePanel: FC<{ order: Order }> = ({ order }) => {
  const navigate = useNavigate();
  return (
    <Card
      border
      round
      className="mb-2"
      onClick={() => navigate(`/user/orders/parking-fee/${order.order_no}`)}
    >
      <CardBody>
        <Space block justify="between" align="center">
          <Typography.Title level={4} className="mb-0">
            {order.brief}
          </Typography.Title>
          <Typography.Text type="secondary">支付成功</Typography.Text>
        </Space>
        <Divider className="my-3" />
        <Flex align="center" gutter={20}>
          <FlexItem>
            <Image src={svgIcon.OrderParkingFee} width={55} height={55} />
          </FlexItem>
          <FlexItem>
            <Flex direction="column">
              <FlexItem>
                <Typography.Text>支付金额: {order.total_fee}元</Typography.Text>
              </FlexItem>
              <FlexItem>
                <Typography.Text>支付时间: {order.paid_at}</Typography.Text>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ParkingFeePanel;
