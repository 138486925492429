import { Empty, Image, List } from "react-vant";
import { useState } from "react";
import { UserWeekendPresent } from "../../../entity";
import { useRequest, useSetState, useTitle } from "ahooks";
import { getList } from "../../../requests/user-present";
import { PaginationQueryParams } from "../../../request";
import WeekendPresentEntity from "../../../components/_user/_weekend-present/PresentItem";
import svgIcon from "../../../assets/svg";
import { Status } from "../../../utils/enum";

export default function WeekendPresentRecord(): JSX.Element {
  useTitle("领取记录");
  const { StatusEmpty } = svgIcon;
  const [userWeekendPresentRecord, setUserWeekendWeekendPresentRecord] =
    useState<UserWeekendPresent[]>([]);
  const [finished, setFinished] = useState(false);
  const [queryParams, setQueryParams] = useSetState<PaginationQueryParams>({
    page: 1,
    take: 8,
    status: Status.inActive,
  });
  const userPresentRequest = useRequest(getList, {
    manual: true,
  });

  const handleFetch = async () => {
    userPresentRequest.runAsync(queryParams).then((response) => {
      setUserWeekendWeekendPresentRecord((v) => {
        const combine = [...v, ...response.data];
        if (combine.length >= response.meta.total) {
          setFinished(true);
        }
        setQueryParams({
          page: queryParams.page + 1,
        });
        return combine;
      });
    });
  };

  return (
    <>
      <div className="p-3">
        <List onLoad={handleFetch} finished={finished}>
          {userWeekendPresentRecord.map((userPresent) => (
            <WeekendPresentEntity
              key={userPresent.id}
              isRecord
              userPresent={userPresent}
            />
          ))}
          {!userPresentRequest.loading &&
            userWeekendPresentRecord.length < 1 && (
              <Empty
                description="暂无领取记录"
                image={<Image src={StatusEmpty} />}
              />
            )}
        </List>
      </div>
    </>
  );
}
