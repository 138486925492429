import { FC, useEffect, useState } from "react";
import Card, { CardBody } from "react-vant/es/card/Card";
import {
  ActionBar,
  Image,
  Skeleton,
  Space,
  Toast,
  Typography,
} from "react-vant";
import { useNavigate, useParams } from "react-router-dom";
import {
  Vote,
  VoteParticipator as VoteParticipatorEntity,
} from "../../../entity";
import { useRequest } from "ahooks";
import { findById, giveVote } from "../../../requests/vote-participator";
import { isNil } from "lodash";
import Flex from "react-vant/es/flex/Flex";
import FlexItem from "react-vant/es/flex/FlexItem";
import LatestVoteList from "./LatestVoteList";
import { ImagePreview } from "react-vant/lib";

const ParticipatorViewContent: FC<{ vote: Vote }> = ({ vote }) => {
  const { participatorId } = useParams();
  const navigate = useNavigate();

  const [participator, setParticipator] = useState<
    | (VoteParticipatorEntity & {
        current_rank: number;
        front_distance: number;
      })
    | null
  >(null);

  const request = useRequest(findById, { manual: true });

  const raiseRequest = useRequest(giveVote, { manual: true });

  const fetchData = (id: string) => {
    request.runAsync(id).then((data) => {
      if (data) setParticipator(data);
      else navigate("/404");
    });
  };

  const handleGiveVote = () => {
    participatorId &&
      raiseRequest
        .runAsync(participatorId, { vote_id: vote.id, count: 1 })
        .then((response) => {
          Toast({
            type: response.success ? "success" : "fail",
            message: response.message,
          });
        });
  };

  useEffect(() => {
    if (participatorId) fetchData(participatorId);
  }, [participatorId]);

  if (request.loading || isNil(participator))
    return (
      <Card className={"mx-3 mt-3"} round>
        <CardBody>
          <Skeleton title row={20} />
        </CardBody>
      </Card>
    );

  return (
    <>
      <Card className={"mx-3 mt-3"} round>
        <CardBody>
          <Space direction={"vertical"} gap={[5, 0]} className={"mb-5"}>
            <Space gap={10}>
              <Typography.Text size={"lg"}>
                {participator.identity}号
              </Typography.Text>
              <Typography.Text size={"lg"}>
                {participator.username}
              </Typography.Text>
            </Space>
            <Typography.Text ellipsis type={"secondary"}>
              拍摄地点：{participator.photo_address}
            </Typography.Text>
          </Space>
          <Flex justify={"center"} className={"bg-gray-200 py-3 rounded"}>
            <FlexItem span={8}>
              <Space block align={"center"} gap={[0, 0]} direction={"vertical"}>
                <Typography.Text className={"text-lg"}>
                  {participator.current_rank}
                </Typography.Text>
                <Typography.Text>排名</Typography.Text>
              </Space>
            </FlexItem>
            <FlexItem span={8}>
              <Space block align={"center"} gap={[0, 0]} direction={"vertical"}>
                <Typography.Text className={"text-lg"}>
                  {participator.receive_vote}
                </Typography.Text>
                <Typography.Text>票数</Typography.Text>
              </Space>
            </FlexItem>
            <FlexItem span={8}>
              <Space block align={"center"} gap={[0, 0]} direction={"vertical"}>
                <Typography.Text className={"text-lg"}>
                  {participator.front_distance}票
                </Typography.Text>
                <Typography.Text>距上一名</Typography.Text>
              </Space>
            </FlexItem>
          </Flex>
          <Typography.Text className={"pt-5 pb-3"}>
            {participator.description}
          </Typography.Text>
          <Flex wrap={"wrap"} gutter={[0, 10]}>
            {participator.photos.map((photo, index) => (
              <FlexItem
                key={photo}
                span={24}
                onClick={() =>
                  ImagePreview.open({
                    images: participator?.photos || [],
                    startPosition: index,
                    showIndex: false,
                    showIndicators: true,
                  })
                }
              >
                <Image src={photo} width={"100%"} radius={5} />
              </FlexItem>
            ))}
          </Flex>
        </CardBody>
      </Card>
      <LatestVoteList />
      <ActionBar>
        <ActionBar.Button
          disabled={raiseRequest.loading}
          loading={raiseRequest.loading}
          type={"primary"}
          className={"text-xl"}
          onClick={handleGiveVote}
        >
          投票
        </ActionBar.Button>
      </ActionBar>
    </>
  );
};

export default ParticipatorViewContent;
