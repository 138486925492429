import { FC, useContext } from "react";
import ContentCell from "../../Components/ContentCard";
import { UserGroupBuyContext } from "../../../../special-card/constant";
import { isNil } from "lodash";
import { Image, Space } from "react-vant";
import { Status } from "../../../../../utils/enum";
import { ContentCellGroup } from "../../../../../type";

const GroupBuyView: FC = () => {
  const context = useContext(UserGroupBuyContext);

  if (isNil(context)) return <></>;

  if (!isNil(context.leader) && context.leader.status === Status.active) {
    const item: ContentCellGroup = {
      title: "拼团详情",
      children: [
        {
          title: "拼团用户",
          value: (
            <Space block gap={5} justify={"end"} align={"center"}>
              {context.userGroupBuys.map((o) => (
                <Image
                  key={o.id}
                  round
                  height={30}
                  width={30}
                  src={o.user.avatar}
                />
              ))}
            </Space>
          ),
        },
        { title: "拼团人数", value: context.leader.finish_count },
        { title: "完成时间", value: context.leader.finished_at },
        {
          title: "支付金额",
          value: `${context.leader.actual_pay_money / 100}元`,
        },
        {
          title: "拼团状态",
          value: "拼团成功",
        },
      ],
    };

    return <ContentCell item={item} />;
  }

  return <></>;
};

export default GroupBuyView;
