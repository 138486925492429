import { Card, Space } from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import SpecialCardHeader from "./Components/SpecialCardHeader";
import SpecialCardSubmitBar from "./Components/SpecialCardSubmitBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FC, useEffect } from "react";
import { useTitle } from "ahooks";
import { findById } from "../../requests/special-card";
import HTMLReactParser from "html-react-parser";
import { isNil, toNumber } from "lodash";
import SpecialCardPlaceholder from "./Components/SpecialCardPlaceholder";
import SpecialCardCenterTab from "./Components/SpecialCardCenterTab";
import { useRecoilState } from "recoil";
import { specialCardAtom } from "../../store/atoms";

const SpecialCardMain: FC = () => {
  useTitle("文体汇小超人计划");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [specialCard, setSpecialCard] = useRecoilState(specialCardAtom);

  const handleFetch = async (id: number) => {
    const response = await findById(id);
    if (response) setSpecialCard(response);
    else navigate("/404");
  };

  useEffect(() => {
    if (id && isNil(specialCard)) handleFetch(toNumber(id));
  }, []);

  if (specialCard)
    return (
      <>
        <Space direction="vertical" block gap={15} className="pb-32">
          <SpecialCardHeader specialCard={specialCard} />
          <Card>
            <CardBody>
              <SpecialCardCenterTab specialCard={specialCard} />
            </CardBody>
          </Card>

          <Card>
            <CardHeader border>图文详情</CardHeader>
            <CardBody>{HTMLReactParser(specialCard.content || "")}</CardBody>
          </Card>

          <SpecialCardSubmitBar
            onSubmit={() => {
              navigate(
                `/activity/special-card/checkout/${id}${location.search}`
              );
            }}
          />
        </Space>
      </>
    );

  return <SpecialCardPlaceholder />;
};

export default SpecialCardMain;
