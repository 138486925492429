import { CellGroup } from "react-vant/es/cell";
import { Cell, Image, Loading } from "react-vant";
import menus from "./menus";
import { isEmpty, random } from "lodash";
import { useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import getOne from "../../requests/user-wallet";
import { useEffect } from "react";
import { fCurrency } from "../../utils/formatNumber";
import { useRecoilState } from "recoil";
import { walletRemainAtom } from "../../store/atoms";

export default function UserMenu(): JSX.Element {
  const navigate = useNavigate();
  const [walletRemain, setWalletRemain] = useRecoilState(walletRemainAtom);
  const userWalletRequest = useRequest(getOne, {
    manual: true,
    onSuccess: (data) => setWalletRemain(data.data.remain_amount),
  });
  const cellIcon = (icon: any): JSX.Element => (
    <Image src={icon} width={35} height={35} />
  );
  const walletCellValue = (value: string) => {
    if (value === ":wallet") {
      if (userWalletRequest.loading) return <Loading size={24} />;
      if (walletRemain && walletRemain > 0)
        return `¥${fCurrency(walletRemain)}`;
    }
    return "";
  };
  useEffect(() => {
    if (isEmpty(walletRemain)) userWalletRequest.run();
  }, []);
  return (
    <div className="rv-ucenter-menu pb-10">
      {menus &&
        menus.map((menu) => {
          return (
            <CellGroup
              key={random(true)}
              border={false}
              className="mb-2 bg-white"
            >
              {menu.map((row) => (
                <Cell
                  key={row.title as number}
                  center
                  border={false}
                  title={row.title}
                  size="large"
                  value={walletCellValue(row.value as string)}
                  titleClass="text-base ml-5"
                  icon={cellIcon(row.icon)}
                  clickable
                  onClick={() => {
                    if (row.tel) window.location.href = row.link;
                    else navigate(row.link);
                  }}
                />
              ))}
            </CellGroup>
          );
        })}
    </div>
  );
}
