import { FC, useEffect, useState } from "react";
import { Vote as VoteEntity } from "../../entity";
import { useRequest, useTitle } from "ahooks";
import { findById } from "../../requests/vote";
import { useNavigate, useParams } from "react-router-dom";
import { isNil } from "lodash";
import VoteTabbar from "../../components/VoteTabbar";
import RankListContent from "./components/RankListContent";
import SwipeBanner from "../../components/SwipeBanner";
import Card from "react-vant/es/card/Card";

const VoteRankList: FC = () => {
  useTitle("投票排行");
  const { id } = useParams();
  const navigate = useNavigate();
  const [vote, setVote] = useState<VoteEntity | null>(null);
  const request = useRequest(findById, { manual: true });

  const fetchData = (id: string) => {
    request.runAsync(id).then((data) => {
      if (data) setVote(data);
      else navigate("/404");
    });
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  if (request.loading || isNil(vote)) return <Card style={{ height: 300 }} />;

  return (
    <div className={"min-h-screen bg-gray-100 pb-32"}>
      <SwipeBanner banners={vote.banners} />
      <RankListContent />
      <VoteTabbar />
    </div>
  );
};

export default VoteRankList;
