import { Card, Empty, Image, Space, Typography } from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import useActivityProjectOperation from "../../../../hooks/useActivityProjectOperation";
import { useRecoilValue } from "recoil";
import { specialCardPriceSelector } from "../../../../store/selectors";
import { fCurrency } from "../../../../utils/formatNumber";
import { FC, useMemo } from "react";

const CheckoutItem: FC = () => {
  const [selected] = useActivityProjectOperation();
  const specialCardPrice = useRecoilValue(specialCardPriceSelector);

  if (selected.length <= 0)
    return (
      <Card>
        <CardHeader border>已选项目</CardHeader>
        <Empty imageSize={70} description="空空如也~" />
      </Card>
    );

  return useMemo(
    () => (
      <>
        <Card>
          <CardHeader border>已选项目</CardHeader>
          <CardBody>
            <Space direction={"vertical"} block>
              {selected.map((project) => (
                <Space
                  key={project.id}
                  gap={20}
                  block
                  className={"bg-gray-50 rounded p-2"}
                >
                  <Image
                    radius={5}
                    src={project.thumbnailUrl}
                    height={60}
                    width={60}
                  />
                  <Space block direction={"vertical"}>
                    <Typography.Text>{project.title}</Typography.Text>
                    <Space>
                      <Typography.Text size={"lg"}>
                        <Typography.Text size={"sm"}>¥</Typography.Text>
                        {project.currentPrice / 100}
                      </Typography.Text>
                      <Typography.Text type={"secondary"} size={"xs"}>
                        ¥{project.originPrice / 100}
                      </Typography.Text>
                    </Space>
                  </Space>
                </Space>
              ))}
            </Space>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Space direction="vertical" align="end" block>
              <Space block justify="end" align="center">
                <Typography.Text>价格总计:</Typography.Text>
                <Typography.Text type="danger" size="xl">
                  ¥{fCurrency(specialCardPrice.currentPrice / 100)}
                </Typography.Text>
              </Space>
            </Space>
          </CardBody>
        </Card>
      </>
    ),
    [selected]
  );
};

export default CheckoutItem;
