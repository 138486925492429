import { Empty, Image, List, NavBar, Space, Typography } from "react-vant";
import { useRequest, useTitle } from "ahooks";
import { getList } from "../../../requests/user-order";
import { useState } from "react";
import { Order as OrderEntity } from "../../../entity";
import { PaginationQueryParams } from "../../../request";
import svgIcon from "../../../assets/svg";
import OrderVariant from "./UserOrderSelection";
import { ArrowLeft } from "@react-vant/icons";
import { useNavigate } from "react-router-dom";

export default function UserOrderList(): JSX.Element {
  useTitle("全部订单");
  const { StatusEmpty } = svgIcon;
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<PaginationQueryParams>({
    page: 1,
    take: 8,
  });
  const [orderList, setOrderList] = useState<OrderEntity[]>([]);
  const [finished, setFinished] = useState(false);
  const request = useRequest(getList, {
    manual: true,
  });

  const handleFetch = async () => {
    const response = await request.runAsync(queryParams);
    setOrderList((v) => {
      const combine = [...v, ...response.data];
      if (combine.length >= response.meta.total) {
        setFinished(true);
      }
      setQueryParams({
        ...queryParams,
        page: queryParams.page + 1,
      });
      return combine;
    });
  };

  return (
    <>
      <NavBar
        onClickLeft={() => navigate("/user")}
        leftText={
          <Space align="center" gap={15}>
            <ArrowLeft />
            <Typography.Text>会员中心</Typography.Text>
          </Space>
        }
      />

      <div className="p-3">
        <List finished={finished} onLoad={handleFetch}>
          {orderList.map((order) => (
            <OrderVariant key={order.order_no} order={order} />
          ))}
          {!request.loading && orderList.length < 1 && (
            <Empty
              description="没有找到订单数据"
              image={<Image src={StatusEmpty} />}
            />
          )}
        </List>
      </div>
    </>
  );
}
