import "./index.css";
import LoginLayout from "../../layouts/LoginLayout";
import MobileForm from "../../components/_login/MobileForm";
import MessageCodeForm from "../../components/_login/MessageCodeForm";
import { Toast } from "react-vant";
import { useRequest, useSetState, useTitle, useUpdateEffect } from "ahooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../../requests/auth";
import { useState } from "react";
import { LoginFormValues } from "../../request";
import { getUser, setUser } from "../../utils/storage";
import { getMaskMobile } from "../../utils/util";

export default function Login(): JSX.Element {
  useTitle("登录");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [mobilePassed, setMobilePassed] = useState(false);
  const [loginFormValue, setLoginFormValues] = useSetState<LoginFormValues>({
    mobile: "",
    code: "",
  });
  const loginRequest = useRequest(login, {
    manual: true,
    throttleWait: 300,
    onSuccess: (data) => {
      if (data.success) {
        setUser({ ...getUser(), mobile: loginFormValue.mobile });
        navigate(searchParams.get("refer") || "/user", {
          replace: true,
        });
        Toast.clear();
      } else {
        Toast.info({
          message: data.errors?.first || data.message,
          position: "bottom",
        });
      }
    },
  });
  const handleMobilePassed = (values: { mobile: string }) => {
    setLoginFormValues({
      mobile: values.mobile,
    });
    setMobilePassed(true);
  };
  useUpdateEffect(() => {
    if (loginFormValue.code.length === 4) {
      Toast.loading("");
      loginRequest.run(loginFormValue);
    }
  }, [loginFormValue.code]);

  return (
    <>
      {mobilePassed ? (
        <LoginLayout
          title="登录"
          header="请输入验证码"
          subHeader={`验证码已发送至手机 +86 ${getMaskMobile(
            loginFormValue.mobile
          )}`}
          showNavBar
          onNavBarLeftClick={() => setMobilePassed(false)}
        >
          <MessageCodeForm
            mobile={loginFormValue.mobile}
            onPassed={(code) => setLoginFormValues({ code })}
          />
        </LoginLayout>
      ) : (
        <LoginLayout
          title="登录"
          header="登录文体汇"
          subHeader="请输入手机号获取验证码"
        >
          <MobileForm
            mobile={loginFormValue.mobile}
            onPassed={handleMobilePassed}
          />
        </LoginLayout>
      )}
    </>
  );
}
