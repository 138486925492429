import request from "../utils/request";
import {
  CheckstandPayFormValues,
  DataResponse,
  PaginationQueryParams,
  PaginationResponse,
  WxpayBody,
} from "../request";
import { Merchant, OrderCheckstand } from "../entity";

const ROUTE = "checkstand";

export function findMerchantByCode(
  code: string
): Promise<DataResponse<Merchant>> {
  return request().get(`${ROUTE}/${code}`);
}

// 收银台支付
export function pay(
  data: CheckstandPayFormValues
): Promise<DataResponse<WxpayBody>> {
  return request().post(`${ROUTE}/pay`, data);
}

export default function findByOrderNo(
  orderNo: string
): Promise<OrderCheckstand> {
  return request().get(`${ROUTE}/order/${orderNo}`);
}

// 支付账单
export function detail(
  params: PaginationQueryParams,
  code: string
): Promise<PaginationResponse<OrderCheckstand>> {
  return request().get(`${ROUTE}/detail/${code}`, {
    params,
  });
}
