import { createContext } from "react";

export const ListControlContext = createContext<{
  open: boolean;
  setOpen: (value: boolean) => void;
  loading?: boolean;
  list: any[];
  fetch?: VoidFunction;
} | null>(null);

export const FormSheetControl = createContext<{
  open: boolean;
  setOpen: (value: boolean) => void;
  afterSubmit: (value: any) => void;
} | null>(null);
