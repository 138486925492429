import { FC, useEffect, useState } from "react";
import {
  ActionBar,
  Button,
  Form,
  Input,
  Picker,
  Toast,
  Typography,
} from "react-vant";
import FormItem from "react-vant/es/form/FormItem";
import { ActivityForm } from "../../../entity";
import SvgPlus from "@react-vant/icons/es/Plus";
import ContactList from "../../../components/_contact/List";
import { useRecoilValue } from "recoil";
import { contactAtom } from "../../../store/atoms";
import { ActivityFormValues } from "../../../request";
import ActivityFormActionButton from "./ActivityFormActionButton";

const pickerColumns = () =>
  [...new Array(10)].map((_, index) => {
    return {
      text: `${index + 3}岁`,
      value: index + 3,
    };
  });

const ActivityBaseForm: FC<{
  activityForm: ActivityForm;
  onSubmit: (values: ActivityFormValues) => void;
}> = ({ activityForm, onSubmit }) => {
  const contact = useRecoilValue(contactAtom);
  const [form] = Form.useForm();
  const [openContact, setOpenContact] = useState(false);

  const leftCount = () => {
    const count =
      activityForm.join_limit - activityForm.user_activity_form_count;
    return count > 0 ? count : 0;
  };

  const onFinish = () => {
    if (leftCount() <= 0) {
      Toast.fail("报名人数已满");
    } else onSubmit(form.getFieldsValue());
  };
  const onFinishFailed = (errors: any) => {
    Toast.info({
      message: errors.errorFields[0].errors[0],
      position: "bottom",
    });
  };

  useEffect(() => {
    if (contact) {
      form.setFieldValue("mobile", contact.mobile);
      form.validateFields(["mobile"]);
    }
  }, [contact]);

  return (
    <Form
      className={"py-5"}
      layout={"vertical"}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      showValidateMessage={false}
      form={form}
    >
      <FormItem
        size={"large"}
        label={
          <Typography.Text size={"lg"} className={"font-bold"}>
            孩子姓名
          </Typography.Text>
        }
        name={"child_name"}
        rules={[{ required: true, message: "请输入孩子姓名" }]}
      >
        <Input className={"text-xl"} placeholder={"请输入"} />
      </FormItem>
      <FormItem
        rules={[{ required: true, message: "请选择孩子年龄" }]}
        name={"child_age"}
        label={
          <Typography.Text size={"lg"} className={"font-bold"}>
            孩子年龄
          </Typography.Text>
        }
        onClick={(_, action) => action?.current.open()}
      >
        <Picker popup className={"text-xl"} columns={pickerColumns()}>
          {(val) =>
            val ? (
              <Typography.Text className={"text-xl"}>{val}岁</Typography.Text>
            ) : (
              <Typography.Text type={"secondary"}>请选择</Typography.Text>
            )
          }
        </Picker>
      </FormItem>
      <FormItem
        label={
          <Typography.Text size={"lg"} className={"font-bold"}>
            手机号
          </Typography.Text>
        }
        name={"mobile"}
        rules={[
          { required: true, message: "请输入" },
          { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确" },
        ]}
        suffix={
          <Button
            icon={<SvgPlus />}
            type={"primary"}
            size={"small"}
            onClick={() => setOpenContact(true)}
          >
            联系人
          </Button>
        }
      >
        <Input className={"text-xl"} placeholder={"请输入"} />
      </FormItem>
      <ContactList open={openContact} onClose={() => setOpenContact(false)} />
      <ActionBar safeAreaInsetBottom className={"z-10 shadow-black shadow-xl"}>
        <ActionBar.Icon style={{ width: 120 }}>
          <Typography.Text>
            剩余名额：
            <Typography.Text type={"danger"} size={"lg"}>
              {leftCount()}
            </Typography.Text>
          </Typography.Text>
        </ActionBar.Icon>
        <ActivityFormActionButton
          activityForm={activityForm}
          onSubmit={form.submit}
        />
      </ActionBar>
    </Form>
  );
};

export default ActivityBaseForm;
