import { FC, useEffect } from "react";
import Input from "react-vant/es/input/Input";
import { Button, Form, Toast, Typography } from "react-vant";
import { useRequest, useTitle } from "ahooks";
import { useParams } from "react-router-dom";
import { useCode } from "../../../requests/user-present";
import { CdkeyForm } from "../../../request";

const Cdkey: FC = () => {
  useTitle("礼品兑换");

  const [form] = Form.useForm();
  const { code } = useParams();
  const userPresentRequest = useRequest(useCode, { manual: true });

  const handleUseCode = async ({ code }: CdkeyForm) => {
    Toast.loading({
      message: "",
      forbidClick: true,
    });
    const response = await userPresentRequest.runAsync(code);
    setTimeout(() => {
      if (response.success) {
        form.resetFields(["code"]);
        Toast.success(response.message);
      } else {
        Toast.info({
          message: response.errors?.first || response.message,
          position: "bottom",
        });
      }
    }, 500);
  };

  const onFinish = async (values: CdkeyForm) => {
    await handleUseCode(values);
  };

  const onFinishFailed = (errors: any) => {
    Toast.info({
      message: errors.errorFields[0].errors[0],
      position: "bottom",
    });
  };

  useEffect(() => {
    if (code) form.setFieldValue("code", code);
  }, [code]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className={"py-3"}
        showValidateMessage={false}
        footer={
          <div className={"my-4 mx-10"}>
            <Button round nativeType="submit" type="primary" block>
              兑换
            </Button>
          </div>
        }
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: "请填写兑换码",
            },
            {
              min: 4,
              message: "兑换码不正确",
            },
          ]}
          name="code"
        >
          <Input placeholder="请填写兑换码" />
        </Form.Item>
      </Form>
      <div className={"px-6 pt-8"}>
        <Typography.Title
          type={"secondary"}
          level={5}
          className={"text-center"}
        >
          · 兑换规则 ·
        </Typography.Title>
        <Typography.Text type={"secondary"}>
          1、使用兑换码可兑换精美礼品、抵扣券等，兑换成功后在会员中心查看
        </Typography.Text>
        <Typography.Text type={"secondary"}>
          2、一个兑换码仅能兑换一次，兑换后立即失效
        </Typography.Text>
        <Typography.Text type={"secondary"}>
          3、发放的兑换码拥有兑换有效期，超过有效期未兑换，则兑换码会自动作废
        </Typography.Text>
      </div>
    </>
  );
};

export default Cdkey;
