import { Space, Typography } from "react-vant";
import { Checked } from "@react-vant/icons";
import { useTitle } from "ahooks";
import { FC } from "react";
import Button from "react-vant/es/button/Button";
import { useNavigate } from "react-router-dom";

const Result: FC = () => {
  useTitle("账户修复完成");
  const navigate = useNavigate();

  return (
    <>
      <Space
        direction="vertical"
        align="center"
        className="py-10 px-5"
        block
        gap={20}
      >
        <Checked fontSize={75} className="text-blue-500" />
        <Typography.Text size="xl">账户更新成功</Typography.Text>
      </Space>
      <div className={"p-5"}>
        <Button type={"primary"} block onClick={() => navigate("/user")}>
          前往会员中心
        </Button>
      </div>
    </>
  );
};

export default Result;
