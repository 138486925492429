import { Empty, Space } from "react-vant";
import { WarningO } from "@react-vant/icons";
import { useTitle } from "ahooks";

export default function VipNotFound(): JSX.Element {
  useTitle("未找到股民信息");
  return (
    <>
      <Space
        direction="vertical"
        gap={[0, 0]}
        block
        align="center"
        className="pt-6"
      >
        <Empty
          description="未找到预留信息"
          image={<WarningO fontSize={70} className="text-orange-400" />}
          imageSize={70}
        />
      </Space>
    </>
  );
}
