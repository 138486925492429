import { Space, Typography } from "react-vant";
import { Checked } from "@react-vant/icons";
import { useSetRecoilState } from "recoil";
import { parkingOrderAtom } from "../../../store/atoms";
import { useEffect } from "react";
import { useTitle } from "ahooks";

export default function ParkingFeeResult(): JSX.Element {
  useTitle("支付完成");
  const setParkingOrder = useSetRecoilState(parkingOrderAtom);
  useEffect(() => {
    return () => setParkingOrder(null);
  }, []);
  return (
    <>
      <Space
        block
        direction="vertical"
        align="center"
        gap={10}
        className="pt-10"
      >
        <Checked fontSize={65} className="text-blue-500" />
        <Typography.Title level={4}>支付成功</Typography.Title>
        <div className="flex flex-col items-center">
          <Typography.Text type="secondary" className="mb-1">
            请在15分钟内离开停车场，超出时间
          </Typography.Text>
          <Typography.Text type="secondary">将会产生新的费用</Typography.Text>
        </div>
      </Space>
    </>
  );
}
