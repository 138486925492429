import {
  ActionSheet,
  Button,
  Card,
  Divider,
  Empty,
  Loading,
  Space,
} from "react-vant";
import { Contact } from "../../entity";
import { destroy } from "../../requests/contact";
import { FC, useContext, useState } from "react";
import { isEmpty } from "lodash";
import { CardBody } from "react-vant/es/card/Card";
import { useRecoilState, useRecoilValue } from "recoil";
import { contactAtom, contactListAtom } from "../../store/atoms";
import { ListControlContext } from "../../utils/contexts";
import ContactSheetHeader from "./Components/ContactSheetHeader";
import { Status } from "../../utils/enum";
import SvgPlus from "@react-vant/icons/es/Plus";
import ContactListContent from "./Components/ContactListContent";
import FixedBottom from "../FixedBottom";
import { useRequest } from "ahooks";

const ContactListSheet: FC<{ setOpenForm: (value: boolean) => void }> = ({
  setOpenForm,
}) => {
  const listControlContext = useContext(ListControlContext);
  const [contact, setContact] = useRecoilState(contactAtom);
  const [deleteMode, setDeleteMode] = useState(Status.inActive);
  const list = useRecoilValue<Contact[]>(contactListAtom);
  const deleteRequest = useRequest(destroy, {
    throttleWait: 500,
    manual: true,
  });

  const onCellClick = async (value: Contact) => {
    if (deleteMode) {
      await handleDelete(value);
      if (value.mobile === contact?.mobile) setContact(null);
    } else {
      setContact(value);
      listControlContext?.setOpen(false);
    }
  };

  const handleDelete = async (value: Contact) => {
    await deleteRequest.runAsync(value.id);
    await listControlContext?.fetch?.();
  };

  const onCloseSheet = () => {
    setDeleteMode(Status.inActive);
    listControlContext?.setOpen(false);
  };

  return (
    <>
      <ActionSheet
        visible={listControlContext?.open}
        onClickOverlay={onCloseSheet}
      >
        <Card style={{ minHeight: "540px" }}>
          <ContactSheetHeader
            deleteMode={deleteMode}
            onChange={setDeleteMode}
          />
          {listControlContext?.loading ? (
            <Space block justify="center" align="center">
              <Loading size={65} className="pt-16" />
            </Space>
          ) : (
            <CardBody>
              <Divider className="my-3" />

              {isEmpty(list) ? (
                <Empty description={"没有找到联系人信息"} />
              ) : (
                <ContactListContent
                  deleteMode={deleteMode}
                  onSubmit={onCellClick}
                />
              )}
            </CardBody>
          )}
        </Card>
        <FixedBottom>
          <Space block direction={"vertical"} className={"px-3"}>
            <Button
              block
              round
              type="primary"
              icon={<SvgPlus />}
              onClick={() => {
                setOpenForm(true);
                setDeleteMode(Status.inActive);
              }}
            >
              新增联系人
            </Button>
            <Button round block onClick={onCloseSheet}>
              取消
            </Button>
          </Space>
        </FixedBottom>
      </ActionSheet>
    </>
  );
};

export default ContactListSheet;
