import { format, isAfter, isBefore, parseISO } from "date-fns";
import { addDays } from "react-vant/es/datetime-picker/utils";
import { CouponRelease } from "../../entity";

export const getValidIn = (firstDate: string, lastDate: string) =>
  firstDate === lastDate
    ? firstDate
    : `${friendlyDate(firstDate)} - ${friendlyDate(lastDate)}`;

const friendlyDate = (value: string) => format(parseISO(value), "M月d日");

export const beforeDate = (value: CouponRelease) =>
  isBefore(new Date(), parseISO(value.receive_from));

export const afterDate = (value: CouponRelease) =>
  isAfter(new Date(), addDays(parseISO(value.receive_to), 1));

export const soldOut = (value: CouponRelease) => value.remain_count <= 0;

export const overLimit = (value: CouponRelease, count: number) =>
  count >= value.get_limit_daily;
