import { LoginFormValues, Response } from "../request";
import request from "../utils/request";
import { InvestorAccount } from "../entity";

const ROUTE = "/investor-accounts";

export function findVip(): Promise<InvestorAccount> {
  return request().get(ROUTE);
}

// 村民权益激活
export function activeVip(): Promise<Response> {
  return request().put(ROUTE);
}

export function recover(data: LoginFormValues): Promise<Response> {
  return request().put(`${ROUTE}/recover`, data);
}
