import { UserCoupon } from "../../../entity";
import dayjs from "dayjs";
import { CouponInfo } from "react-vant/es/coupon-list/PropsType";

export const getCouponCell = (data: UserCoupon[]) =>
  data.map((userCoupon) => {
    return {
      id: userCoupon.id,
      name: userCoupon.coupon_title,
      condition: "无门槛",
      startAt: dayjs(userCoupon.valid_from, "YYYY-MM-DD").unix(),
      endAt: dayjs(userCoupon.valid_to, "YYYY-MM-DD").unix(),
      value: userCoupon.coupon_amount,
      unitDesc: "元",
      valueDesc: `${userCoupon.coupon_amount / 100}`,
    } as CouponInfo;
  });
