import { useEffect, useState } from "react";
import { CouponCell, CouponList, Popup } from "react-vant";
import { useShallow } from "zustand/react/shallow";
import { useParkingOrderStore, useUserCouponStore } from "../store";

const ParkingCoupon = () => {
  const [open, setOpen] = useState(false);
  const { data, selected, setSelected } = useUserCouponStore(
    useShallow((state) => ({
      data: state.dataCell,
      selected: state.selected,
      setSelected: state.setSelected,
    }))
  );
  const setUseUserCoupon = useParkingOrderStore(
    (state) => state.setUseUserCoupon
  );

  useEffect(() => {
    setUseUserCoupon(selected >= 0);
  }, [selected]);

  return (
    <>
      <CouponCell
        coupons={data}
        chosenCoupon={selected}
        className={"my-10"}
        onClick={() => setOpen(true)}
      />
      <Popup
        round
        position="bottom"
        style={{ height: "90%", paddingTop: 4 }}
        visible={open}
        onClose={() => setOpen(false)}
      >
        <CouponList
          showExchangeBar={false}
          coupons={data}
          chosenCoupon={selected}
          onChange={(value) => {
            setSelected(value);
            setOpen(false);
          }}
          disabledCoupons={[]}
        />
      </Popup>
    </>
  );
};

export default ParkingCoupon;
