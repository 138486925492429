export interface CreditValues {
  id: number;
  value: number;
  rewardValue: number;
}

const creditAmount: CreditValues[] = [
  { id: 1, value: 500, rewardValue: 10 },
  { id: 2, value: 1000, rewardValue: 30 },
  { id: 3, value: 3000, rewardValue: 120 },
  { id: 4, value: 5000, rewardValue: 280 },
];

export default creditAmount;
