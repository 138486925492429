import { Card, Divider, Flex, Image, Space, Typography } from "react-vant";
import { CardBody, CardFooter } from "react-vant/es/card/Card";
import FlexItem from "react-vant/es/flex/FlexItem";
import { Order } from "../../../../../entity";
import { useNavigate } from "react-router-dom";
import GroupBuyFooter from "./GroupBuyFooter";
import { FC, useContext } from "react";
import { UserGroupBuyContext } from "../../../../special-card/constant";
import { Status } from "../../../../../utils/enum";

const GroupBuyPanel: FC<{ order: Order }> = ({ order }) => {
  const context = useContext(UserGroupBuyContext);
  const navigate = useNavigate();

  const getStatusEle = (status: Status) => {
    switch (status) {
      case Status.active:
        return <Typography.Text type="secondary">拼团成功</Typography.Text>;
      case Status.inActive:
        return <Typography.Text type={"danger"}>拼团中</Typography.Text>;
      default:
        return <Typography.Text type="secondary">拼团失败</Typography.Text>;
    }
  };

  if (context)
    return (
      <Card border round className="mb-2">
        <CardBody
          onClick={() =>
            context.leader.status === Status.active &&
            navigate(`/user/orders/special-card/${order.order_no}`)
          }
        >
          <Space block justify="between" align="center">
            <Typography.Title level={4} className="mb-0">
              {order.brief}
            </Typography.Title>
            {getStatusEle(context.leader.status)}
          </Space>
          <Divider className="my-3" />
          <Flex align="center" gutter={20}>
            <FlexItem>
              <Image
                src={order.special_card.banner_url}
                width={50}
                height={50}
                radius={5}
              />
            </FlexItem>
            <FlexItem>
              <Flex direction="column">
                <FlexItem>
                  <Typography.Text>
                    支付金额: {order.total_fee}元
                  </Typography.Text>
                </FlexItem>
                <FlexItem>
                  <Typography.Text>支付时间: {order.paid_at}</Typography.Text>
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
        </CardBody>

        <CardFooter border>
          <GroupBuyFooter />
        </CardFooter>
      </Card>
    );
  return <></>;
};

export default GroupBuyPanel;
