import { Button, Flex, Typography } from "react-vant";
import { Status } from "../../../../utils/enum";
import { useRecoilValue } from "recoil";
import { specialCardPriceSelector } from "../../../../store/selectors";
import { FC, ReactNode, useContext, useMemo } from "react";
import FixedBottom from "../../../../components/FixedBottom";
import { getUnitPrice } from "../../../../utils/util";
import { GroupActionContext } from "../../constant";
import { specialCardAtom } from "../../../../store/atoms";
import { isNil } from "lodash";

const CheckoutGroupBuyAction: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const specialCard = useRecoilValue(specialCardAtom);
  const groupActionContext = useContext(GroupActionContext);
  const specialCardPrice = useRecoilValue(specialCardPriceSelector);

  if (isNil(specialCard) || isNil(groupActionContext)) return <></>;

  return useMemo(
    () => (
      <FixedBottom>
        {children}

        <Flex align={"center"} className={"bg-white"}>
          <Flex.Item span={12}>
            <Button
              className={"opacity-50 rounded-none rounded-l font-bold"}
              block
              type="danger"
              size={"large"}
              onClick={() => groupActionContext.onSubmit?.(Status.inActive)}
            >
              <Typography.Text type="light" size="lg">
                {`¥${specialCardPrice.currentPrice / 100} 立即支付`}
              </Typography.Text>
            </Button>
          </Flex.Item>
          <Flex.Item span={12}>
            <Button
              className={"rounded-none rounded-r font-bold"}
              block
              type="danger"
              size={"large"}
              onClick={() => groupActionContext?.onSubmit?.(Status.active)}
            >
              <Typography.Text type="light" size="lg">
                {`¥${
                  getUnitPrice(
                    specialCardPrice.currentPrice,
                    specialCard.group_buy.group_price_unit,
                    specialCard.group_buy.group_price_amount
                  ) / 100
                } 发起拼团`}
              </Typography.Text>
            </Button>
          </Flex.Item>
        </Flex>
      </FixedBottom>
    ),
    [specialCard]
  );
};

export default CheckoutGroupBuyAction;
