import { ActivityForm } from "../entity";
import request from "../utils/request";
import { ActivityFormValues, DataResponse, WxpayBody } from "../request";
import { PayType } from "../utils/enum";

const ROUTE = "activity-forms";

export function findById(id: number | string): Promise<ActivityForm> {
  return request().get(ROUTE + "/" + id);
}

export function pay(
  id: number,
  data: ActivityFormValues & { pay_type: PayType }
): Promise<DataResponse<WxpayBody>> {
  return request().post(ROUTE + "/" + id, data);
}
