import { Button, Card, Space, Typography } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { FC, useEffect } from "react";
import { useTitle } from "ahooks";
import { useNavigate } from "react-router-dom";
import useActivityProjectOperation from "../../hooks/useActivityProjectOperation";
import SvgChecked from "@react-vant/icons/es/Checked";
import { useSetRecoilState } from "recoil";
import { specialCardAtom } from "../../store/atoms";

const SpecialCardCheckoutResult: FC = () => {
  useTitle("订单支付结果");
  const navigate = useNavigate();
  const [, , , clearSelected] = useActivityProjectOperation();
  const setSpecialCard = useSetRecoilState(specialCardAtom);

  useEffect(() => {
    clearSelected();
    setSpecialCard(null);
  }, []);

  return (
    <>
      <Space direction="vertical" block gap={10}>
        <Card>
          <CardBody className="pt-8">
            <Space block direction="vertical" align="center">
              <SvgChecked fontSize={65} className="text-green-500" />
              <Typography.Text strong size="lg" className="text-black mb-3">
                购买成功
              </Typography.Text>
              <Space direction={"vertical"} align={"center"} gap={1}>
                <Typography.Text type="secondary">
                  订单支付成功，稍后商户可能会与您联系
                </Typography.Text>
                <Typography.Text type="secondary">
                  请保持电话畅通
                </Typography.Text>
              </Space>
            </Space>
            <Space direction={"vertical"} block className={"mt-8 w-full"}>
              <Button
                type={"danger"}
                block
                onClick={() => navigate("/user/orders")}
              >
                查看我的订单
              </Button>
              <Button block onClick={() => navigate(-1)}>
                返回活动首页
              </Button>
            </Space>
          </CardBody>
        </Card>
      </Space>
    </>
  );
};

export default SpecialCardCheckoutResult;
