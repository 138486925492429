import { FC, useState } from "react";
import { CellGroup } from "react-vant/es/cell";
import {
  ActionBar,
  Card,
  Cell,
  Empty,
  NoticeBar,
  Skeleton,
  Toast,
  Typography,
} from "react-vant";
import { useNavigate, useParams } from "react-router-dom";
import SvgInfoO from "@react-vant/icons/es/InfoO";
import { useRequest } from "ahooks";
import { MerchantMember, OrderActivitySale, OrderVerify } from "../../entity";
import { getContactFullName, getMaskMobile } from "../../utils/util";
import { getFirstByAuth } from "../../requests/merchant-member";
import {
  create,
  findUserActivitySaleOrderByOrderNo,
  getFirstByOrderNo,
} from "../../requests/order-verify";

export const ActivitySaleVerifyOrder: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [orderVerify, setOrderVerify] = useState<OrderVerify>();
  const [merchantMember, setMerchantMember] = useState<MerchantMember>();
  const [order, setOrder] = useState<OrderActivitySale>();
  const merchantMemberRequest = useRequest(getFirstByAuth, {
    onSuccess: setMerchantMember,
  });
  const request = useRequest(
    () => findUserActivitySaleOrderByOrderNo(params.orderNo as string),
    {
      onSuccess: setOrder,
    }
  );
  const verifyRequest = useRequest(create, {
    manual: true,
  });
  const fetchRequest = useRequest(() => getFirstByOrderNo(params.orderNo!), {
    onSuccess: (data) => data && setOrderVerify(data),
  });

  const handleVerify = async (orderNo: string) => {
    const response = await verifyRequest.runAsync({ order_no: orderNo });
    if (response.success) {
      setTimeout(() => {
        navigate("/activity-sale/verify-order-result");
      }, 1000);
    } else Toast.fail(response.message);
  };

  if (request.loading || merchantMemberRequest.loading || fetchRequest.loading)
    return (
      <Card>
        <Card.Body>
          <Skeleton title row={10} className={"mb-3"} />
        </Card.Body>
      </Card>
    );

  if (!merchantMember) return <Empty description={"仅限文体汇商户"} />;

  if (order)
    return (
      <>
        {!orderVerify && (
          <NoticeBar leftIcon={<SvgInfoO />} text={"请查看用户的购课信息"} />
        )}
        <CellGroup>
          <Cell
            size={"large"}
            title={
              <Typography.Title className={"font-bold"}>
                {order.user_merchant_project.title}
              </Typography.Title>
            }
            center
            label={
              <span>
                剩余{" "}
                <Typography.Text type={"warning"}>
                  {orderVerify ? 0 : 1}
                </Typography.Text>{" "}
                次可使用
              </span>
            }
            value={
              <Typography.Text className={"font-bold"} size={"lg"}>
                ¥{order.order.total_fee}
              </Typography.Text>
            }
          />
          <Cell
            title={"联系方式"}
            value={`${getContactFullName(
              order.user_merchant_project.username,
              order.user_merchant_project.gender
            )} ${getMaskMobile(order.user_merchant_project.phone)}`}
          />
          <Cell title={"购课时间"} value={order.order.paid_at} />
          {orderVerify && (
            <Cell title={"使用时间"} value={orderVerify.created_at} />
          )}
        </CellGroup>
        <ActionBar>
          {!orderVerify ? (
            <ActionBar.Button
              className={"text-xl"}
              type={"primary"}
              loading={verifyRequest.loading}
              disabled={verifyRequest.loading}
              onClick={() => handleVerify(params.orderNo as string)}
            >
              确认核销
            </ActionBar.Button>
          ) : (
            <ActionBar.Button className={"text-xl"} type={"primary"} disabled>
              已核销
            </ActionBar.Button>
          )}
        </ActionBar>
      </>
    );

  return <></>;
};

export default ActivitySaleVerifyOrder;
