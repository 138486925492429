import { PasswordInput } from "react-vant";
import LicenseKeyboard from "vehicle-plate-keyboard";
import { useRef } from "react";
import { PasswordInputInstance } from "react-vant/es/password-input/PropsType";

export default function LicenseInputControl({
  input,
  setInput,
  openKeyboard,
  setOpenKeyboard,
}: {
  input: string;
  setInput: (value: string) => void;
  openKeyboard: boolean;
  setOpenKeyboard: (open: boolean) => void;
}): JSX.Element {
  const ref = useRef<PasswordInputInstance>(null);
  return (
    <>
      <PasswordInput
        ref={ref}
        className="rv-license-code-input"
        value={input}
        gutter={5}
        type="text"
        onFocus={() => {
          ref.current?.blur();
          setOpenKeyboard(true);
        }}
        mask={false}
        length={8}
      />
      <LicenseKeyboard
        visible={openKeyboard}
        value={input}
        onChange={setInput}
        confirmButtonText="关闭"
        done={() => {
          setOpenKeyboard(false);
        }}
      />
    </>
  );
}
