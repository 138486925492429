import { Button, Flex, Space, Typography } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import {
  clearLicenseNoSearchHistory,
  getLicenseNoSearchHistory,
} from "../../../utils/storage";
import { useUpdate } from "ahooks";

export default function UserLicenseNos({
  onClick,
}: {
  onClick: (value: string) => void;
}): JSX.Element | null {
  const userLicenseNos = getLicenseNoSearchHistory();
  const update = useUpdate();
  return userLicenseNos && userLicenseNos.length > 0 ? (
    <Space direction="vertical" gap={10} block>
      <Flex justify="between">
        <FlexItem>
          <Typography.Text size="lg" className="font-bold">
            查询记录
          </Typography.Text>
        </FlexItem>
        <FlexItem>
          <Typography.Text
            onClick={() => {
              clearLicenseNoSearchHistory();
              update();
            }}
            type="secondary"
            className="font-bold"
          >
            清空
          </Typography.Text>
        </FlexItem>
      </Flex>
      <Flex wrap="wrap" gutter={[10, 10]}>
        {userLicenseNos.map((license) => (
          <FlexItem key={license} span={8}>
            <Button plain block onClick={() => onClick(license)}>
              {license}
            </Button>
          </FlexItem>
        ))}
      </Flex>
    </Space>
  ) : null;
}
