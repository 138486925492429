import { CellGroup } from "react-vant/es/cell";
import { Cell, Image } from "react-vant";
import { getUser } from "../../../utils/storage";
import { getMaskMobile } from "../../../utils/util";
import svgIcon from "../../../assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useTitle } from "ahooks";

export default function Security(): JSX.Element {
  useTitle("账户安全");
  const location = useLocation();
  const navigate = useNavigate();
  const user = getUser();
  const { SecurityMobile, SecurityUpdateMobile } = svgIcon;
  return (
    <>
      <CellGroup>
        {user.mobile ? (
          <Cell
            center
            icon={<Image src={SecurityUpdateMobile} height={20} width={20} />}
            title="更换手机号"
            isLink
            clickable
            onClick={() => navigate("/user/security/update-mobile")}
            value={getMaskMobile(user.mobile)}
          />
        ) : (
          <Cell
            center
            icon={<Image src={SecurityMobile} height={18} width={18} />}
            title="绑定手机号"
            onClick={() => {
              navigate(`/login?refer=${location.pathname}`);
            }}
            isLink
          />
        )}
      </CellGroup>
    </>
  );
}
