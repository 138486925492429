import { Button, NoticeBar, Space, Toast, Typography } from "react-vant";
import LicenseInputControl from "../../components/_parking/_home/LicenseInputControl";
import { useEffect, useState } from "react";
import { useRequest, useSafeState, useTitle } from "ahooks";
import { InfoO } from "@react-vant/icons";
import { useNavigate, useParams } from "react-router-dom";
import { findByCode, useCoupon } from "../../requests/parking-coupon";
import { ParkingCoupon } from "../../entity";
import Placeholder from "../../components/Placeholder/Placeholder";

const ParkingCouponUse = () => {
  useTitle("停车券抵扣");
  const params = useParams();
  const [data, setData] = useState<ParkingCoupon>();
  const navigate = useNavigate();
  const [license, setLicense] = useState("");
  const [disabled, setDisabled] = useSafeState(false);
  const [keyboard, setKeyboard] = useState(false);
  const request = useRequest(findByCode, { manual: true });
  const useCouponRequest = useRequest(useCoupon, { manual: true });

  const handleFetch = (code: string) => {
    request.runAsync(code).then((response) => {
      setData(response);
    });
  };

  const redirectToPay = (value: string) => {
    navigate(`/parking/fee/find/${value}?from=/parking-coupon/use`);
  };

  const handleSubmit = (value: string) => {
    useCouponRequest
      .runAsync({ car_license_no: value, code: params["code"] })
      .then((response) => {
        if (response.success) {
          Toast.success("抵扣成功");
          redirectToPay(value);
        } else Toast.fail(response.message);
      });
  };

  useEffect(() => {
    if (params.code) handleFetch(params.code);
  }, [params]);

  if (request.loading) return <Placeholder />;

  if (!data) return <></>;

  return (
    <>
      <NoticeBar wrapable leftIcon={<InfoO />} className={"text-black"}>
        <div className={"pl-2"}>
          您正在使用<span className={"text-red-500 px-1"}>{data.title}</span>,
          每张可抵扣
          <span className={"text-red-500 px-1"}>{data.amount / 100}元</span>
          停车费
        </div>
        <div className={"text-gray-500 pl-2"}>仅限文体汇地下停车场</div>
      </NoticeBar>

      <div className="pt-6 px-5">
        <Space direction="vertical" block gap={30} style={{ minHeight: 140 }}>
          <Space direction="vertical" gap={10} block>
            <Typography.Text size="lg" className="font-bold">
              请输入完整车牌
            </Typography.Text>
            <LicenseInputControl
              openKeyboard={keyboard}
              setOpenKeyboard={setKeyboard}
              input={license}
              setInput={setLicense}
            />
          </Space>
        </Space>

        <div>
          <Button
            onClick={() => {
              setDisabled(true);
              setKeyboard(false);
              handleSubmit(license);
              setDisabled(false);
            }}
            loading={useCouponRequest.loading}
            disabled={license.length < 7 || disabled}
            type="primary"
            size="large"
            block
            className={"mb-3"}
          >
            确认抵扣
          </Button>
          <Button
            disabled={disabled || license.length < 7}
            block
            size={"large"}
            onClick={() => redirectToPay(license)}
          >
            去缴费
          </Button>
        </div>
      </div>
    </>
  );
};

export default ParkingCouponUse;
