import { FC, useEffect, useState } from "react";
import Card, { CardHeader } from "react-vant/es/card/Card";
import { useRequest } from "ahooks";
import { getLatestVotes } from "../../../requests/vote-grant";
import { useParams } from "react-router-dom";
import { VoteGrant } from "../../../entity";
import Cell from "react-vant/es/cell/Cell";
import { Empty, Image, Skeleton, Typography } from "react-vant";
import { isEmpty, uniqueId } from "lodash";
import { formatDistanceToNow, parseISO } from "date-fns";
import ZhCN from "date-fns/esm/locale/zh-CN";

const LatestVoteList: FC = () => {
  const { participatorId } = useParams();
  const [grantList, setGrantList] = useState<VoteGrant[]>([]);

  const request = useRequest(getLatestVotes, { manual: true });

  const fetchList = (participatorId: string) =>
    request.runAsync(participatorId).then((response) => setGrantList(response));

  useEffect(() => {
    if (participatorId) fetchList(participatorId);
  }, [participatorId]);

  if (request.loading)
    return (
      <Card className={"mx-3 mt-3 py-3"} round>
        {new Array(8).fill(null).map(() => (
          <Skeleton avatar key={uniqueId()} row={1} className={"mb-3"} />
        ))}
      </Card>
    );

  return (
    <Card className={"mx-3 mt-3"} round>
      <CardHeader>最近投票</CardHeader>
      {isEmpty(grantList) ? (
        <Empty description={"暂时没有人投票~"} />
      ) : (
        <>
          {grantList.map((list) => (
            <Cell
              key={list.id}
              border={false}
              center
              title={list.user.nickname}
              label={formatDistanceToNow(parseISO(list.created_at), {
                locale: ZhCN,
                includeSeconds: true,
                addSuffix: true,
              })}
              icon={
                <Image src={list.user.avatar} round width={45} height={45} />
              }
              value={
                <Typography.Text type={"danger"} size={"xl"}>
                  +{list.grant_count}
                </Typography.Text>
              }
            />
          ))}
          <div className={"text-center p-3"}>
            <Typography.Text type={"secondary"}>
              仅显示最近10条数据
            </Typography.Text>
          </div>
        </>
      )}
    </Card>
  );
};

export default LatestVoteList;
