import React, { FC, useContext, useMemo } from "react";
import { Badge, Image, Space } from "react-vant";
import { Status } from "../../../utils/enum";
import { UserGroupBuyContext } from "../constant";
import svgIcon from "../../../assets/svg";
import { uniqueId } from "lodash";

const GroupBuyResultUserList: FC = () => {
  const context = useContext(UserGroupBuyContext);

  if (!context) return <></>;

  const remainCount = () => {
    const { userGroupBuys, leader } = context;
    const remain = leader.finish_count - userGroupBuys.length;
    return remain > 0 ? new Array(remain).fill(uniqueId()) : [];
  };

  return useMemo(
    () => (
      <Space
        align={"center"}
        justify={"center"}
        gap={20}
        block
        wrap
        className={"mb-6"}
      >
        {context.userGroupBuys.map((userGroupBuy) => {
          if (userGroupBuy.is_leader === Status.active)
            return (
              <Badge key={userGroupBuy.id} content={"团长"} offset={[3, 3]}>
                <Image
                  round
                  width={50}
                  height={50}
                  src={userGroupBuy.user.avatar}
                />
              </Badge>
            );
          return (
            <Image
              key={userGroupBuy.id}
              round
              width={50}
              height={50}
              src={userGroupBuy.user.avatar}
            />
          );
        })}
        {remainCount().map((o) => (
          <div
            key={o}
            style={{ width: 50, height: 50 }}
            className={
              "rounded-full border-2 border-dotted border-gray-300 flex justify-center items-center"
            }
          >
            <Image src={svgIcon.Question} height={28} width={28} />
          </div>
        ))}
      </Space>
    ),
    [context.userGroupBuys]
  );
};

export default GroupBuyResultUserList;
