import { Button, Input, Form, Toast } from "react-vant";
import { ArrowDown } from "@react-vant/icons";

export default function MobileForm({
  mobile,
  onPassed,
}: {
  mobile: string;
  onPassed: (values: { mobile: string }) => void;
}): JSX.Element {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      showValidateMessage={false}
      onFinish={onPassed}
      onFinishFailed={(errors) =>
        Toast.info({
          message: errors.errorFields[0].errors[0],
          position: "bottom",
        })
      }
      className="rv-login-mobile-form"
      footer={
        <Button type="primary" block size="large" round onClick={form.submit}>
          获取验证码
        </Button>
      }
    >
      <Form.Item
        name="mobile"
        validateFirst={false}
        required={false}
        initialValue={mobile}
        rules={[
          { required: true, message: "请输入手机号" },
          { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确" },
        ]}
        className="divide-x divide-gray-300 rv-mobile-control"
        leftIcon={
          <>
            <span className="mr-1 text-lg">+86</span>
            <ArrowDown fontSize={14} />
          </>
        }
      >
        <Input type="number" maxLength={11} className={"text-xl"} />
      </Form.Item>
    </Form>
  );
}
