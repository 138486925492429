import React, { FC, useContext, useMemo } from "react";
import { Card, Typography } from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { UserGroupBuyContext } from "../constant";
import { Status } from "../../../utils/enum";

const GroupBuyResultHeader: FC = () => {
  const context = useContext(UserGroupBuyContext);

  if (!context) return <></>;

  if (context.leader.status === Status.active)
    return (
      <Card className={"from-red-600 to-red-400 bg-gradient-to-r py-3 mb-3"}>
        <CardHeader className={"text-white pb-1"}>拼团成功</CardHeader>
      </Card>
    );

  if (context.leader.status === Status.cancel)
    return (
      <Card className={"from-red-600 to-red-400 bg-gradient-to-r py-3 mb-3"}>
        <CardHeader className={"text-white pb-1"}>拼团失败</CardHeader>
      </Card>
    );

  return useMemo(
    () => (
      <Card className={"from-red-600 to-red-400 bg-gradient-to-r py-3 mb-3"}>
        <CardHeader className={"text-white pb-1"}>
          拼团支付成功, 待分享
        </CardHeader>
        <CardBody className={"pt-0"}>
          <Typography.Text className={"text-white"}>
            让朋友们快来拼单吧
          </Typography.Text>
        </CardBody>
      </Card>
    ),
    [context.userGroupBuys]
  );
};

export default GroupBuyResultHeader;
