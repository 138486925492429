import React, { FC, useEffect, useState } from "react";
import { Card, Loading, Space } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { useRequest, useTitle } from "ahooks";
import { OrderSpecialCard, UserGroupBuy } from "../../entity";
import { useNavigate, useParams } from "react-router-dom";
import useActivityProjectOperation from "../../hooks/useActivityProjectOperation";
import { useSetRecoilState } from "recoil";
import { specialCardAtom } from "../../store/atoms";
import { findWithUserGroupBuys } from "../../requests/order-special-card";
import GroupBuyResultUserList from "./GroupBuyResult/GroupBuyResultUserList";
import GroupBuyResultTimeRemain from "./GroupBuyResult/GroupBuyResultTimeRemain";
import { Status } from "../../utils/enum";
import { findGroupLeader, UserGroupBuyContext } from "./constant";
import GroupBuyResultHeader from "./GroupBuyResult/GroupBuyResultHeader";
import GroupBuyResultAction from "./GroupBuyResult/GroupBuyResultAction";
import GroupBuyResultNotice from "./GroupBuyResult/GroupBuyResultNotice";

const SpecialCardGroupBuyResult: FC = () => {
  useTitle("拼团结果");
  const { orderNo } = useParams();
  const navigate = useNavigate();
  const [, , , clearSelected] = useActivityProjectOperation();
  const setSpecialCard = useSetRecoilState(specialCardAtom);
  const [groupLeader, setGroupLeader] = useState<UserGroupBuy>();
  const [orderSpecialCard, setOrderSpecialCard] = useState<OrderSpecialCard>();
  const request = useRequest(findWithUserGroupBuys, {
    manual: true,
  });

  const handleFetch = async (orderNo: string) => {
    const response = await request.runAsync(orderNo);
    if (response) {
      setOrderSpecialCard(response);
      const leader = findGroupLeader(response.user_group_buys);
      setGroupLeader(leader);
      if (leader.status === Status.active && leader.finished_at) {
        navigate(`/activity/special-card/checkout/result/${orderNo}`, {
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    if (orderNo) {
      handleFetch(orderNo);
    }
  }, [orderNo]);

  useEffect(() => {
    clearSelected();
    setSpecialCard(null);
  }, []);

  if (request.loading)
    return (
      <Space
        direction={"vertical"}
        align={"center"}
        justify={"center"}
        style={{ minHeight: 320 }}
        block
      >
        <Loading vertical={true} textSize={16} size={55}>
          加载中...
        </Loading>
      </Space>
    );

  if (orderSpecialCard)
    return (
      <Space direction={"vertical"} block gap={15}>
        {groupLeader && (
          <UserGroupBuyContext.Provider
            value={{
              userGroupBuys: orderSpecialCard.user_group_buys,
              leader: groupLeader,
            }}
          >
            <GroupBuyResultHeader />

            <Card>
              <CardBody>
                <GroupBuyResultTimeRemain />

                <GroupBuyResultUserList />

                <GroupBuyResultAction orderSpecialCard={orderSpecialCard} />
              </CardBody>
            </Card>

            <GroupBuyResultNotice />
          </UserGroupBuyContext.Provider>
        )}
      </Space>
    );

  return <>拼团支付失败</>;
};

export default SpecialCardGroupBuyResult;
