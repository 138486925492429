import svgIcon from "../../../assets/svg";

export interface ParkingMenu {
  title: string;
  icon: string;
  url: string | null;
}

const { ParkingRent, ParkingCarport, ParkingCreditLog, ParkingCredit } =
  svgIcon;

const menus: ParkingMenu[] = [
  { title: "车位缴费", icon: ParkingCredit, url: "/parking/license-search" },
  { title: "车位租赁", icon: ParkingRent, url: null },
  { title: "车位信息", icon: ParkingCarport, url: null },
  { title: "缴费记录", icon: ParkingCreditLog, url: null },
];

export default menus;
