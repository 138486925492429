import { Card, List } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { useRequest, useSafeState, useSetState, useTitle } from "ahooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import SpecialCardProjectContent from "./Components/SpecialCardProjectContent";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedActivityProjectAtom,
  specialCardAtom,
} from "../../store/atoms";
import { isEmpty, isNil, toNumber } from "lodash";
import SpecialCardSubmitBar from "../special-card/Components/SpecialCardSubmitBar";
import { getList } from "../../requests/special-card-project";
import { SpecialCardProject } from "../../entity";
import { PaginationQueryParams } from "../../request";
import { findById } from "../../requests/special-card";

const SpecialCardProjectMain: FC = () => {
  useTitle("全部项目");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const userSelectedActivityProject = useRecoilValue(
    selectedActivityProjectAtom
  );
  const [params, setParams] = useSetState<PaginationQueryParams>({
    page: 1,
    take: 8,
    special_card_id: id,
  });
  const [projectList, setProjectList] = useSafeState<SpecialCardProject[]>([]);
  const [finished, setFinished] = useState(false);
  const fetchRequest = useRequest(getList, {
    manual: true,
  });
  const [specialCard, setSpecialCard] = useRecoilState(specialCardAtom);

  const handleFetch = async (id: number) => {
    const response = await findById(id);
    if (response) setSpecialCard(response);
    else navigate("/404");
  };

  const onLoad = async () => {
    const response = await fetchRequest.runAsync(params);
    setProjectList((value) => {
      const current = [...value, ...response.data];
      setParams((param) => {
        return { page: param.page + 1 };
      });
      if (current.length >= response.meta.total) setFinished(true);
      return current;
    });
  };

  useEffect(() => {
    if (id && isNil(specialCard)) handleFetch(toNumber(id));
  }, [id]);

  return (
    <Card>
      <CardBody>
        <List
          finished={finished}
          onLoad={onLoad}
          finishedText="加载完毕~"
          className="pb-24"
        >
          {specialCard &&
            projectList.map((project) => (
              <SpecialCardProjectContent
                key={project.id}
                specialCard={specialCard}
                specialCardProject={project}
              />
            ))}
        </List>
        {!isEmpty(userSelectedActivityProject) && (
          <SpecialCardSubmitBar
            onSubmit={() => {
              navigate(
                `/activity/special-card/checkout/${id}${location.search}`
              );
            }}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default SpecialCardProjectMain;
