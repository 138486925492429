import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Skeleton, Space, Toast, Typography } from "react-vant";
import { Paid } from "@react-vant/icons";
import { ParkingPayFormValues } from "../../../request";
import { useShallow } from "zustand/react/shallow";
import { useRequest, useTitle } from "ahooks";
import { isString } from "lodash";
import {
  useBannerStore,
  useParkingCouponStore,
  useParkingOrderStore,
  useUserCouponStore,
} from "./store";
import { pay } from "../../../requests/parking-fee";
import Banner from "./Components/Banner";
import ParkingCarState from "./Components/ParkingCarState";
import ParkingCoupon from "./Components/ParkingCoupon";
import ParkingOrderDetail from "./Components/ParkingOrderDetail";
import "./index.css";

const ParkingOrderCheckout = () => {
  useTitle("停车缴费");

  const navigate = useNavigate();
  const { licenseNo } = useParams();
  const [loading, setLoading] = useState(false);
  const { data, handleFetchUserCoupon, selectedCoupon, setSelected } =
    useUserCouponStore(
      useShallow((state) => ({
        data: state.dataCell,
        handleFetchUserCoupon: state.handleFetch,
        setSelected: state.setSelected,
        selectedCoupon: state.selectedCoupon,
      }))
    );
  const { parkingCoupons, handleFetchParkingCoupon } = useParkingCouponStore(
    useShallow((state) => ({
      parkingCoupons: state.data,
      handleFetchParkingCoupon: state.handleFetch,
    }))
  );
  const { order, handleFetchOrder, setUseUserCoupon, useUserCoupon } =
    useParkingOrderStore(
      useShallow((state) => ({
        order: state.data,
        handleFetchOrder: state.handleFetch,
        setUseUserCoupon: state.setUseUserCoupon,
        useUserCoupon: state.useUserCoupon,
      }))
    );
  const handleFetchBanner = useBannerStore((state) => state.handleFetch);

  const handleFetch = async (licenseNo: string) => {
    setLoading(true);
    await handleFetchBanner();
    await handleFetchOrder(licenseNo);
    await handleFetchParkingCoupon(licenseNo);
    await handleFetchUserCoupon();
    if (parkingCoupons.length <= 0 && data.length > 0) {
      setSelected(0);
      setUseUserCoupon(true);
    }
    setLoading(false);
  };

  const parkingPayRequest = useRequest(pay, {
    manual: true,
  });

  const payParkingOrder = async (values: ParkingPayFormValues) => {
    const response = await parkingPayRequest.runAsync(values);
    if (response.success) {
      const data = response.data;
      if (isString(data)) return Toast.fail(data);
      if (data.payType === "WECHAT" && data.codeUrl) {
        Toast.loading("跳转支付中");
        location.href = data.codeUrl;
      } else if (data.payType == "ZERO")
        navigate("/parking/fee/result/" + data.orderNo);
    }
  };

  const handlePayParkingOrder = async (licenseNo: string) => {
    let data: ParkingPayFormValues = { license_no: licenseNo };
    if (useUserCoupon && selectedCoupon) {
      data = { ...data, coupon_id: selectedCoupon.id };
    }
    await payParkingOrder(data);
  };

  useEffect(() => {
    if (licenseNo) handleFetch(licenseNo).then();
  }, [licenseNo]);

  if (loading)
    return (
      <div className="min-h-screen bg-white overflow-hidden">
        <Skeleton title row={15} className="mb-5 pt-6" />
      </div>
    );

  if (!order || !licenseNo) {
    navigate(`/parking/fee/find/${licenseNo}/failed`);
    return <></>;
  }

  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <Banner />

      <ParkingCarState />

      <ParkingCoupon />

      <ParkingOrderDetail />

      {order.payable > 0 && (
        <div className="fixed w-full bottom-8 h-12 text-center">
          <Button
            loading={parkingPayRequest.loading}
            disabled={parkingPayRequest.loading}
            type="primary"
            className="h-full w-2/3 rounded-lg"
            onClick={() => handlePayParkingOrder(licenseNo || "")}
          >
            <Space block justify="center" align="center">
              <Paid fontSize={20} />
              <Typography.Text className="text-white">立即支付</Typography.Text>
            </Space>
          </Button>
        </div>
      )}
    </div>
  );
};

export default ParkingOrderCheckout;
