import { Button, Cell } from "react-vant";
import { Image } from "react-vant/lib";
import { useContext, useMemo } from "react";
import { GroupActionContext } from "../constant";

const GroupBuyCell = () => {
  const context = useContext(GroupActionContext);

  return useMemo(
    () => (
      <>
        {context.userGroupBuys.map((o) => (
          <Cell
            center
            key={o.id}
            icon={<Image round height={35} width={35} src={o.user.avatar} />}
            title={o.user.nickname}
            extra={
              <Button
                onClick={() => {
                  context.onJoinGroup?.(o);
                }}
                type={"danger"}
                className={"rounded"}
                size={"small"}
              >
                参与拼团
              </Button>
            }
          />
        ))}
      </>
    ),
    [context.userGroupBuys]
  );
};

export default GroupBuyCell;
