import { Space, Typography } from "react-vant";
import config from "../../../../config/config";
import { FC } from "react";
import { useParkingOrderStore } from "../store";

const ParkingCarState: FC = () => {
  const data = useParkingOrderStore((state) => state.data);

  return (
    <Space
      block
      direction="vertical"
      align="center"
      gap={10}
      className="arc relative pt-6"
    >
      <Typography.Title level={2} className="mb-1">
        {data?.license_no}
      </Typography.Title>
      <Typography.Title level={5} type="secondary" className="mb-0">
        {config.eastCarportName}
      </Typography.Title>
      <Space block align="center">
        <Typography.Text>{data?.entry_time}入场</Typography.Text>
      </Space>
    </Space>
  );
};

export default ParkingCarState;
