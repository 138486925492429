import { Card, Grid, GridItem, Image, Toast } from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import "./index.css";
import menus from "./menus";
import { useNavigate } from "react-router-dom";
import ParkingCarHeader from "../../../components/_user/UserHeader";
import { isNil } from "lodash";
import { useTitle } from "ahooks";

export default function Parking(): JSX.Element {
  useTitle("车主服务");
  const navigate = useNavigate();
  return (
    <div className="pt-14">
      <ParkingCarHeader />
      <Card round className="mx-5 mt-10">
        <CardHeader>车位管理</CardHeader>
        <CardBody>
          <Grid border={false} className="rv-parking-menu">
            {menus.map((menu) => (
              <GridItem
                key={menu.title}
                onClick={() =>
                  isNil(menu.url)
                    ? Toast({ message: "建设中", position: "bottom" })
                    : navigate(menu.url)
                }
                icon={
                  <Image
                    src={menu.icon}
                    width={30}
                    height={30}
                    className={"block"}
                  />
                }
                text={menu.title}
              />
            ))}
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
}
