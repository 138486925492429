import { FC, useEffect, useState } from "react";
import FormItem from "react-vant/es/form/FormItem";
import Input from "react-vant/es/input/Input";
import TextArea from "react-vant/es/text-area";
import { ActionBar, Form, Toast, Typography, Uploader } from "react-vant";
import Contact from "./components/Contact";
import { useRequest, useTitle } from "ahooks";
import { create, findByVoteId, update } from "../../requests/vote-participator";
import { VoteParticipatorFormValues } from "../../request";
import { useNavigate, useParams } from "react-router-dom";
import { upload } from "../../requests/upload";
import { VoteParticipator } from "../../entity";
import { useRecoilState } from "recoil";
import { contactAtom } from "../../store/atoms";
import { isNil } from "lodash";
import PhotoAddressSheet from "./components/PhotoAddressSheet";

const VoteSignup: FC = () => {
  useTitle("投票报名");
  const navigate = useNavigate();
  const { id } = useParams();
  const [participator, setParticipator] = useState<VoteParticipator>();
  const [form] = Form.useForm<VoteParticipatorFormValues>();
  const [contact, setContact] = useRecoilState(contactAtom);
  const [openSheet, setOpenSheet] = useState(false);
  const createRequest = useRequest(create, { manual: true });
  const updateRequest = useRequest(update, { manual: true });
  const findRequest = useRequest(findByVoteId, { manual: true });

  const fetchData = (id: number | string) => {
    const loading = Toast.loading("");
    findRequest.runAsync(id).then((response) => {
      loading.clear();
      if (response)
        form.setFieldsValue({
          username: response.username,
          description: response.description,
          photo_address: response.photo_address,
          photos: response.photos.map((p) => {
            return { url: p };
          }),
          contact_id: response.contact_id,
        });
      if (isNil(response.contact_id)) setContact(null);
      setParticipator(response);
    });
  };

  const handleSubmit = async (values: VoteParticipatorFormValues) => {
    const formData = {
      ...values,
      vote_id: id || 0,
      contact_id: contact?.id,
      photos: values.photos.map((p) => p.url),
    };
    const submit = () =>
      participator
        ? updateRequest.runAsync(participator.id, formData)
        : createRequest.runAsync(formData);
    const response = await submit();
    if (response.success) {
      Toast.success(participator ? "保存成功" : "报名成功");
      navigate(`/vote/${id}`);
    } else Toast.fail(response.message);
  };

  const handleUpload = async (file: File) => {
    const body = new FormData();
    body.append("file", file);
    const response = await upload(body);
    if (response.success) return { url: response.file_url };
    else {
      Toast.fail(response.message);
      return { url: "" };
    }
  };

  useEffect(() => {
    if (id) fetchData(id);
  }, []);

  return (
    <>
      <Form form={form} onFinish={handleSubmit}>
        <FormItem
          required
          rules={[{ required: true, message: "请填写选手姓名" }]}
          name={"username"}
          label={"选手姓名"}
        >
          <Input placeholder={"请填写选手姓名"} maxLength={12} />
        </FormItem>
        <FormItem label={"介绍"} name={"description"}>
          <TextArea
            placeholder={"选填，介绍一下参赛选手"}
            showWordLimit
            maxLength={200}
          />
        </FormItem>
        <FormItem
          rules={[{ required: true, message: "请上传选手的参赛照片" }]}
          label={"参赛照片"}
          name={"photos"}
          intro={
            <Typography.Text type={"warning"} size={"sm"}>
              参赛照片的拍摄地点需在文体汇内，最多可上传5张。
            </Typography.Text>
          }
        >
          <Uploader
            upload={handleUpload}
            multiple
            accept={"image/*"}
            uploadText={"选择图片"}
            maxSize={2000 * 1000 * 1024}
            maxCount={5}
            onOversize={() =>
              Toast({
                message: "图片大小不能超过2M",
                position: "bottom",
              })
            }
          />
        </FormItem>
        <FormItem
          rules={[{ required: true, message: "请选择拍摄地点" }]}
          label={"拍摄地点"}
          name={"photo_address"}
          onClick={() => setOpenSheet(true)}
        >
          <Input placeholder={"请选择拍摄地点"} readOnly />
        </FormItem>
      </Form>
      <Contact />
      <PhotoAddressSheet
        open={openSheet}
        onClose={(merchant) => {
          setOpenSheet(false);
          merchant &&
            form.setFieldValue("photo_address", merchant.merchant_name);
        }}
      />
      <ActionBar>
        <ActionBar.Button
          disabled={
            createRequest.loading ||
            updateRequest.loading ||
            findRequest.loading
          }
          loading={createRequest.loading || updateRequest.loading}
          type={"primary"}
          className={"text-xl"}
          onClick={form.submit}
        >
          提交
        </ActionBar.Button>
      </ActionBar>
    </>
  );
};

export default VoteSignup;
