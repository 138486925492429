import { Cell, Space, Typography } from "react-vant";
import { Checked } from "@react-vant/icons";
import { CellGroup } from "react-vant/es/cell";
import { useRecoilState } from "recoil";
import { checkstandOrderAtom } from "../../store/atoms";
import { useRequest, useTitle } from "ahooks";
import findByOrderNo from "../../requests/checkstand";
import { useParams } from "react-router-dom";
import { CheckstandOrder } from "../../store";
import { useEffect } from "react";
import { fCurrency } from "../../utils/formatNumber";
import { getPayTypeDescription } from "../../utils/desctriptor";

export default function CheckstandResult(): JSX.Element {
  useTitle("支付结果");
  const { orderNo } = useParams();
  const [order, setOrder] =
    useRecoilState<CheckstandOrder>(checkstandOrderAtom);
  const findRequest = useRequest(() => findByOrderNo(orderNo || ""), {
    manual: true,
    onSuccess: (data) => {
      if (data.order)
        setOrder({
          payType: data.order.pay_type,
          merchantName: data.merchant_name,
          payMoney: data.order.total_fee,
          tsCode: data.transaction_code,
        });
    },
  });
  useEffect(() => findRequest.run(), []);
  return (
    <>
      <Space
        direction="vertical"
        align="center"
        className="pt-10 pb-14"
        block
        gap={5}
      >
        <Checked fontSize={65} className="text-blue-500" />
        <Typography.Title level={2} className="text-blue-500 mb-0">
          {fCurrency(order.payMoney)}元
        </Typography.Title>
        <Typography.Text size="lg">支付成功</Typography.Text>
      </Space>
      <CellGroup>
        <Cell title="收款商户" size="large" value={order.merchantName} />
        <Cell
          title="支付方式"
          size="large"
          value={getPayTypeDescription(order.payType)}
        />
      </CellGroup>
    </>
  );
}
