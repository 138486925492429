import { Card, Checked } from "@react-vant/icons";
import { Typography, Space, Button } from "react-vant";
import { useNavigate } from "react-router-dom";
import { useTitle } from "ahooks";

export default function ActiveVipResult(): JSX.Element {
  useTitle("领取成功");
  const navigate = useNavigate();
  return (
    <>
      <Space
        block
        direction="vertical"
        align="center"
        gap={[0, 15]}
        className="pt-14"
      >
        <Checked fontSize={70} className="text-green-500" />
        <Typography.Text size="lg">领取成功</Typography.Text>
      </Space>
      <div className="mx-auto w-8/12 text-center mt-10">
        <Button
          type="info"
          icon={<Card />}
          onClick={() => navigate("/user/wallet", { replace: true })}
        >
          查看钱包余额
        </Button>
      </div>
    </>
  );
}
