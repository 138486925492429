import {
  ActionBar,
  Card,
  Empty,
  Image,
  Loading,
  Space,
  Tag,
  Typography,
} from "react-vant";
import { Ellipsis, Plus } from "@react-vant/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ParkingCar } from "../../../entity";
import { useRequest, useTitle } from "ahooks";
import { getList } from "../../../requests/parking-car";
import { CardBody } from "react-vant/es/card/Card";
import config from "../../../config/config";
import { isEmpty } from "lodash";
import svgIcon from "../../../assets/svg";
import { format, parseJSON } from "date-fns";
import ParkingCarSheet from "../../../components/_parking/_car/Sheet";
import { useRecoilState } from "recoil";
import { parkingCarAtom } from "../../../store/atoms";

export default function UserParkingCar(): JSX.Element {
  useTitle("常用车牌");
  const navigate = useNavigate();
  const { StatusEmpty } = svgIcon;
  const [parkingCarList, setParkingCarList] = useRecoilState(parkingCarAtom);
  const [activeRow, setActiveRow] = useState<ParkingCar>();
  const [openSheet, setOpenSheet] = useState(false);
  const fetchRequest = useRequest(getList, {
    manual: true,
    onSuccess: (data) => setParkingCarList(data),
  });
  useEffect(() => fetchRequest.run(), []);
  if (fetchRequest.loading)
    return (
      <Space justify="center" align="center" block className="py-10">
        <Loading size={40} />
        <ActionBar>
          <ActionBar.Button
            type="primary"
            onClick={() => navigate("/Parking/car/add")}
            text={
              <Space justify="center" align="center">
                <Plus />
                <Typography.Text size="lg" className="text-white">
                  新增
                </Typography.Text>
              </Space>
            }
          />
        </ActionBar>
      </Space>
    );
  return (
    <div>
      {isEmpty(parkingCarList) ? (
        <Empty
          description="没有绑定车牌~"
          image={<Image src={StatusEmpty} />}
        />
      ) : (
        <Space gap={10} block direction="vertical" className="p-3">
          {parkingCarList.map((car) => (
            <Card
              key={car.id}
              round
              onClick={() => {
                setActiveRow(car);
                setOpenSheet(true);
              }}
            >
              <CardBody>
                <Space block gap={8} direction="vertical">
                  <Space block justify="between" align="center">
                    <Typography.Title level={4} className="mb-0">
                      {car.plate_no}
                    </Typography.Title>
                    <Tag
                      className="bg-blue-100 text-gray-700"
                      round
                      size="large"
                    >
                      正常
                    </Tag>
                  </Space>
                  <Typography.Text type="secondary" size="sm">
                    {config.eastCarportName}
                  </Typography.Text>
                  <Space justify="between" block align="center">
                    <Typography.Text>
                      绑定时间：
                      {format(parseJSON(car.created_at), "yyyy-MM-dd")}
                    </Typography.Text>
                    <Ellipsis className="text-blue-500" fontSize={20} />
                  </Space>
                </Space>
              </CardBody>
            </Card>
          ))}
        </Space>
      )}
      <ActionBar>
        <ActionBar.Button
          type="primary"
          onClick={() => navigate("/Parking/car/add")}
          text={
            <Space justify="center" align="center">
              <Plus />
              <Typography.Text size="lg" className="text-white">
                新增
              </Typography.Text>
            </Space>
          }
        />
      </ActionBar>
      <ParkingCarSheet
        open={openSheet}
        row={activeRow}
        onClose={() => setOpenSheet(false)}
        onDelete={() => fetchRequest.run()}
      />
    </div>
  );
}
