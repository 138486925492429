import { createContext } from "react";
import {
  OrderSpecialCard,
  SpecialCard as SpecialCardEntity,
  UserGroupBuy,
} from "../../entity";
import { filter, first } from "lodash";
import { Status } from "../../utils/enum";
import wxSdk from "weixin-js-sdk";
import { appUrl } from "../../utils/util";

export const SpecialCardContext = createContext<{
  specialCard: SpecialCardEntity;
} | null>(null);

export const UserGroupBuyContext = createContext<{
  userGroupBuys: UserGroupBuy[];
  leader: UserGroupBuy;
} | null>(null);

export const GroupActionContext = createContext<{
  loading: boolean;
  userGroupBuys: UserGroupBuy[];
  onSubmit?: (value: Status) => void;
  onJoinGroup?: (value: UserGroupBuy) => void;
  onJoinShareGroup?: (groupNo: string) => void;
}>({
  loading: false,
  userGroupBuys: [],
  onSubmit: undefined,
  onJoinGroup: undefined,
  onJoinShareGroup: undefined,
});

export const findGroupLeader = (userGroupBuys: UserGroupBuy[]) => {
  return first(
    filter(userGroupBuys, (o) => o.is_leader === Status.active)
  ) as UserGroupBuy;
};

export const getShareUrl = (orderSpecialCard: OrderSpecialCard) =>
  `${appUrl()}/activity/special-card/poster/${
    orderSpecialCard.special_card_id
  }?group_no=${orderSpecialCard.group_no}`;

export const updateShareData = async (
  orderSpecialCard: OrderSpecialCard,
  leader: UserGroupBuy
) => {
  wxSdk.ready(() => {
    wxSdk.updateAppMessageShareData({
      title: `【还剩${
        leader.finish_count - orderSpecialCard.user_group_buys.length
      }个名额】文体汇${orderSpecialCard.title}`,
      desc: `我用${leader.actual_pay_money / 100}元购买了${
        orderSpecialCard.title
      }, 内含篮球、乒乓球、舞蹈等20+精品项目, 更多内容请点击查看`,
      link: `${appUrl()}/activity/special-card/poster/${
        orderSpecialCard.special_card_id
      }?group_no=${orderSpecialCard.group_no}`,
      imgUrl: orderSpecialCard.banner_url,
    });
    wxSdk.updateTimelineShareData({
      title: `【还剩${
        leader.finish_count - orderSpecialCard.user_group_buys.length
      }个名额】文体汇${orderSpecialCard.title}`,
      link: `${appUrl()}/activity/special-card/poster/${
        orderSpecialCard.special_card_id
      }?group_no=${orderSpecialCard.group_no}`,
      imgUrl: orderSpecialCard.banner_url,
    });
  });
};
