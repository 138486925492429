import { Flex, PasswordInput } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import MessageCountDown from "./MessageCountDown";
import { memo } from "react";

export default function MessageCodeForm({
  mobile,
  onPassed,
}: {
  mobile: string;
  onPassed: (code: string) => void;
}): JSX.Element {
  const CodeInput = memo(PasswordInput);
  return (
    <Flex direction="column" gutter={[0, 20]}>
      <FlexItem>
        <CodeInput
          className="rv-login-code-input"
          type="number"
          autoFocus
          length={4}
          mask={false}
          onSubmit={onPassed}
        />
      </FlexItem>
      <FlexItem className="mx-4">
        <MessageCountDown mobile={mobile} />
      </FlexItem>
    </Flex>
  );
}
