import { Order } from "../../../entity";
import OrderCheckstand from "./Checkstand/CheckstandPanel";
import SpecialCardPanel from "./SpecialCard/SpecialCardPanel";
import OrderParkingFee from "./ParkingFee/ParkingFeePanel";
import UserOrderActivityForm from "./ActivityForm/ActivityFormPanel";
import ActivitySalePanel from "./ActivitySale/ActivitySalePanel";

export default function UserOrderSelection({ order }: { order: Order }) {
  if (order.parking) return <OrderParkingFee order={order} />;
  if (order.special_card) return <SpecialCardPanel order={order} />;
  if (order.activity_form) return <UserOrderActivityForm order={order} />;
  if (order.activity_sale) return <ActivitySalePanel order={order} />;

  return <OrderCheckstand order={order} />;
}
