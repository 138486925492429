import { FC, useEffect, useState } from "react";
import {
  Card,
  Image,
  NoticeBar,
  Skeleton,
  Space,
  Toast,
  Typography,
} from "react-vant";
import { useRequest, useTitle } from "ahooks";
import { findById, pay } from "../../requests/activity-form";
import { ActivityForm } from "../../entity";
import { useNavigate, useParams } from "react-router-dom";
import { toNumber } from "lodash";
import ActivityBaseForm from "./Components/ActivityBaseForm";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import HTMLReactParser from "html-react-parser";
import SvgVolume from "@react-vant/icons/es/Volume";
import { PayType } from "../../utils/enum";
import { resolveOrderPay } from "../../utils/util";
import { ActivityFormValues } from "../../request";
import OrderPaySheet from "../../components/OrderPaySheet";
import { OrderPayContext } from "../../components/constant";

const ActivityFormMain: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activityForm, setActivityForm] = useState<ActivityForm>();
  const [formValues, setFormValues] = useState<ActivityFormValues>();
  const [openSheet, setOpenSheet] = useState(false);
  const request = useRequest(findById, { manual: true });
  const orderPayRequest = useRequest(pay, { manual: true });

  useTitle(activityForm?.title || "文体汇");

  const fetchData = async (id: number) => {
    const response = await request.runAsync(id);
    if (response) {
      setActivityForm(response);
    } else navigate("/404");
  };

  const redirect = (orderNo: string) =>
    navigate("/activity-form/result/" + orderNo);

  const onPay = async (payType: PayType) => {
    Toast.loading("支付中");
    if (!activityForm || !formValues) return;
    const response = await orderPayRequest.runAsync(activityForm.id, {
      ...formValues,
      pay_type: payType,
    });
    if (response.success) {
      resolveOrderPay(payType, response.data, (orderNo) => {
        redirect(orderNo);
      });
    } else Toast.fail(response.message);
  };

  useEffect(() => {
    if (id) fetchData(toNumber(id));
  }, [id]);

  if (activityForm)
    return (
      <>
        <Image src={activityForm.banner_url} />
        <Space
          direction={"vertical"}
          align={"center"}
          gap={5}
          block
          className={"pt-5"}
        >
          <Typography.Text size={"xxl"} className={"font-bold"}>
            {activityForm.title}
          </Typography.Text>
          <Typography.Text size={"lg"}>
            报名时间：{activityForm.begin_at} 至 {activityForm.end_at}
          </Typography.Text>
        </Space>
        {activityForm.user_activity_form.length > 0 && (
          <NoticeBar
            mode={"closeable"}
            className={"mt-5"}
            leftIcon={<SvgVolume />}
            onClick={() => navigate("/user/orders")}
            text={
              <Space>
                <Typography.Text>您已报名参与此次活动, 查看</Typography.Text>
                <Typography.Text type={"primary"}>我的订单</Typography.Text>
              </Space>
            }
          />
        )}
        <ActivityBaseForm
          activityForm={activityForm}
          onSubmit={(values) => {
            setFormValues(values);
            setOpenSheet(true);
          }}
        />
        <Card className={"pb-28"}>
          <CardHeader border>活动规则</CardHeader>
          <CardBody>{HTMLReactParser(activityForm.content)}</CardBody>
        </Card>
        <OrderPayContext.Provider
          value={{
            loading: orderPayRequest.loading,
            price: activityForm.price / 100,
            openSheet,
            setOpenSheet,
            onPay,
          }}
        >
          <OrderPaySheet />
        </OrderPayContext.Provider>
      </>
    );

  return (
    <>
      <Skeleton row={1} round={false} rowHeight={240} className={"px-0"} />
      <Skeleton row={5} className={"mt-5 mb-10"} />
      <Skeleton row={10} />
    </>
  );
};

export default ActivityFormMain;
