import { createContext } from "react";
import { PayType } from "../utils/enum";

export const OrderPayContext = createContext<{
  loading: boolean;
  title?: string;
  submitText?: string;
  openSheet: boolean;
  setOpenSheet: (value: boolean) => void;
  price: number;
  onPay: (payType: PayType) => void;
} | null>(null);
