import { ActionBar, Typography } from "react-vant";
import { useRecoilValue } from "recoil";
import { specialCardPriceSelector } from "../../../../store/selectors";
import { Status } from "../../../../utils/enum";
import { FC } from "react";

const CheckoutDefaultAction: FC<{ onSubmit: (value: Status) => void }> = ({
  onSubmit,
}) => {
  const specialCardPrice = useRecoilValue(specialCardPriceSelector);

  return (
    <ActionBar>
      <ActionBar.Button
        text={
          <Typography.Text type="light" size="lg">
            {`¥${specialCardPrice.currentPrice / 100} 立即支付`}
          </Typography.Text>
        }
        type="danger"
        onClick={() => onSubmit(Status.inActive)}
      />
    </ActionBar>
  );
};

export default CheckoutDefaultAction;
