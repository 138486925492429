import useStorage from "./useStorage";
import { WTH_TOKEN, WTH_USER } from "../config/access-keys";

const useClearLoginState = () => {
  const [, , removeToken] = useStorage(WTH_TOKEN);
  const [, , removeUser] = useStorage(WTH_USER);

  const clearState = () => {
    removeToken();
    removeUser();
  };

  return [clearState];
};

export default useClearLoginState;
