import { CardBody } from "react-vant/es/card/Card";
import { Button, Card, Flex, Toast, Typography } from "react-vant";
import { Success } from "@react-vant/icons";
import { ActivityProject, SpecialCardProject } from "../../../entity";
import { useNavigate } from "react-router-dom";
import useActivityProjectOperation from "../../../hooks/useActivityProjectOperation";

export default function SpecialCardProjectSubmitBar({
  specialCardProject,
}: {
  specialCardProject: SpecialCardProject;
}): JSX.Element {
  const navigate = useNavigate();
  const [selected, addSelected, removeSelected] = useActivityProjectOperation();
  const limit = specialCardProject.special_card.project_limit;

  const handleSelect = (value: ActivityProject) => {
    if (selected.length >= specialCardProject.special_card.project_limit) {
      return Toast({
        message: `最多选购${limit}项`,
        position: "bottom",
      });
    }
    addSelected(value);
  };

  const hasSelected = () =>
    selected.find((s) => s.id === specialCardProject.activity_project_id);

  return (
    <Card className="fixed bottom-0 w-full van-safe-area-bottom">
      <CardBody>
        <Flex gutter={10}>
          <Flex.Item span={12}>
            <Button className={"rounded-xl"} block onClick={() => navigate(-1)}>
              返回
            </Button>
          </Flex.Item>
          <Flex.Item span={12}>
            {hasSelected() ? (
              <Button
                plain
                type="danger"
                block
                onClick={() =>
                  removeSelected(specialCardProject.activity_project_id)
                }
                className="rounded-xl"
              >
                <Typography.Text
                  type="danger"
                  size="lg"
                  className="flex justify-center items-center"
                >
                  <Success fontSize={24} />
                  已选择
                </Typography.Text>
              </Button>
            ) : (
              <Button
                className="bg-orange-500 rounded-xl border-orange-500"
                block
                onClick={() =>
                  handleSelect(specialCardProject.activity_project)
                }
              >
                <Typography.Text type="light" strong className="mr-2" size="lg">
                  ¥99
                </Typography.Text>
                <Typography.Text type="light" size="lg">
                  立即选购
                </Typography.Text>
              </Button>
            )}
          </Flex.Item>
        </Flex>
      </CardBody>
    </Card>
  );
}
