import { Order } from "../../../../entity";
import { Card, Divider, Flex, Image, Space, Typography } from "react-vant";
import { CardBody, CardFooter } from "react-vant/es/card/Card";
import FlexItem from "react-vant/es/flex/FlexItem";
import { useNavigate } from "react-router-dom";

export default function ActivitySalePanel({
  order,
}: {
  order: Order;
}): JSX.Element {
  const navigate = useNavigate();

  return (
    <Card border round className="mb-2">
      <CardBody
        onClick={() => navigate(`/user/orders/activity-sale/${order.order_no}`)}
      >
        <Space block justify="between" align="center">
          <Typography.Title level={4} className="mb-0">
            {order.brief.indexOf("-") > 0
              ? order.brief.split("-")[0]
              : order.brief}
          </Typography.Title>
          <Typography.Text type="secondary">支付成功</Typography.Text>
        </Space>
        <Divider className="my-3" />
        <Flex align="center" gutter={20}>
          <FlexItem>
            <Image
              src={order.activity_sale.user_merchant_project.figure_url}
              width={50}
              height={50}
              radius={5}
            />
          </FlexItem>
          <FlexItem>
            <Flex direction="column">
              <FlexItem>
                <Typography.Text>
                  购买课程: {order.activity_sale.user_merchant_project.title}
                </Typography.Text>
              </FlexItem>
              <FlexItem>
                <Typography.Text>支付金额: {order.total_fee}元</Typography.Text>
              </FlexItem>
              <FlexItem>
                <Typography.Text>支付时间: {order.paid_at}</Typography.Text>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      </CardBody>

      <CardFooter />
    </Card>
  );
}
