import store2 from "store2";
import {
  CHECKSTAND_ORDER,
  SELECTED_ACTIVITY_PROJECT,
  USER_CARPORT,
  USER_CARPORT_LICENSE,
  WTH_TOKEN,
  WTH_USER,
} from "../config/access-keys";
import {
  CarportCredential,
  CheckstandOrder,
  SelectedActivityProjectProps,
  UserStorage,
} from "../store";
import { filter, findIndex, initial, union } from "lodash";
import config from "../config/config";
import { ActivityProject } from "../entity";

export function getToken(): string | null {
  return store2.get(WTH_TOKEN);
}

export function setToken(token: string): void {
  store2.set(WTH_TOKEN, token);
}

export function clearToken(): void {
  store2.remove(WTH_TOKEN);
}

export function setUser(value: UserStorage): void {
  store2.set(WTH_USER, value);
}

export function getUser(): UserStorage {
  return store2.get(WTH_USER);
}

export function clearUser(): void {
  store2.remove(WTH_USER);
}

export function getLicenseNoSearchHistory(): string[] | null {
  return store2.get(USER_CARPORT_LICENSE);
}

export function setLicenseNoSearchHistory(value: string): void {
  const values = union([value, ...(getLicenseNoSearchHistory() || [])]);
  store2.set(
    USER_CARPORT_LICENSE,
    values.length > config.licenseHistoryLimit ? initial(values) : values
  );
}

export function clearLicenseNoSearchHistory(): void {
  store2.remove(USER_CARPORT_LICENSE);
}

// ---已认证车位信息---
export function getCarportCredential(): CarportCredential[] | null {
  return store2.get(USER_CARPORT);
}

export function setCarportCredential(value: CarportCredential): void {
  let credentials = getCarportCredential() || [];
  if (credentials) {
    const index = findIndex(
      credentials,
      (credential) => credential.cardId === value.cardId
    );
    if (index !== -1) credentials[index] = value;
    else credentials = credentials.concat(value);
  } else {
    credentials = [value];
  }
  store2.set(USER_CARPORT, credentials);
}

export function clearCarportCredential(): void {
  store2.remove(USER_CARPORT);
}
// ------

// 文体汇套卡
export function getSelectedActivityProjectProps(
  s: ActivityProject
): SelectedActivityProjectProps {
  return {
    id: s.id,
    title: s.title,
    thumbnailUrl: s.thumbnail_url,
    currentPrice: s.current_price,
    originPrice: s.origin_price,
  };
}

export function getSelectedActivityProject(): ActivityProject[] {
  return store2.get(SELECTED_ACTIVITY_PROJECT) || [];
}

export function setSelectedActivityProject(value: ActivityProject): void {
  let selected = getSelectedActivityProject();
  if (selected.findIndex((s) => s.id === value.id) === -1)
    selected = [...selected, value];
  store2.set(
    SELECTED_ACTIVITY_PROJECT,
    selected.map(getSelectedActivityProjectProps)
  );
}

export function deleteSelectedActivityProject(value: ActivityProject): void {
  store2.set(
    SELECTED_ACTIVITY_PROJECT,
    filter(getSelectedActivityProject(), (s) => s.id !== value.id).map(
      getSelectedActivityProjectProps
    )
  );
}

export function clearSelectedActivityProjectAtom(): void {
  store2.set(SELECTED_ACTIVITY_PROJECT, []);
}

// ------

// 门店收款
export function getCheckstandOrder(): CheckstandOrder {
  return store2.get(CHECKSTAND_ORDER);
}

export function setCheckstandOrder(value: CheckstandOrder): void {
  store2.set(CHECKSTAND_ORDER, value);
}
// -----
