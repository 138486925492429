import request from "../utils/request";
import { Response } from "../request";
import { ParkingCoupon, ParkingCouponPreUse } from "../entity";

const ROUTE = "merchant-parking-coupons";

export const getPreUseList = (
  carLicense: string
): Promise<ParkingCouponPreUse[]> => {
  return request().get(`${ROUTE}/pre-use/${carLicense}`);
};

export const findByCode = (code: string): Promise<ParkingCoupon> => {
  return request().get(ROUTE + "/" + code);
};

export const scanCoupon = (data: any): Promise<Response> => {
  return request().post(ROUTE + "/scan", data);
};

export const useCoupon = (data: any): Promise<Response> => {
  return request().post(ROUTE + "/use", data);
};
