export const WTH_TOKEN = "WTH_TOKEN";
export const WTH_USER = "WTH_USER";

export const USER_CARPORT = "USER_CARPORT";
export const USER_CARPORT_LICENSE = "USER_CARPORT_LICENSE";

export const SELECTED_ACTIVITY_PROJECT = "SELECTED_ACTIVITY_PROJECT";

export const CHECKSTAND_ORDER = "CHECKSTAND_ORDER";

export const mp_clear_v1 = "mp_clear_v1";

export const Share_Code = "activity_sale_share_code";
