import { Card, Divider, Flex, Image, Space, Typography } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import FlexItem from "react-vant/es/flex/FlexItem";
import { Phone } from "@react-vant/icons";
import { format, parseISO } from "date-fns";
import { head, last, random } from "lodash";
import { implodedTags } from "../../../utils/util";
import { FC, useMemo } from "react";
import { SpecialCard } from "../../../entity";

const SpecialCardHeader: FC<{ specialCard: SpecialCard }> = ({
  specialCard,
}) => {
  const getTimeRange = () => {
    const timeRange = specialCard.sale_in.map((time) =>
      format(parseISO(time as string), "yyyy-MM-dd")
    );
    return `${head(timeRange)} 至 ${last(timeRange)}`;
  };
  const callMobile = () => {
    window.location.href = `tel://${specialCard.merchant.contact_mobile}`;
  };
  return useMemo(
    () => (
      <Card>
        <CardBody className="py-4">
          <Flex gutter={15}>
            <FlexItem>
              <Image
                src={specialCard.banner_url}
                width={100}
                height={100}
                radius={5}
              />
            </FlexItem>
            <FlexItem>
              <Flex direction="column" gutter={[0, 10]}>
                <FlexItem>
                  <Typography.Title level={2} className="mb-0">
                    {specialCard.title}
                  </Typography.Title>
                </FlexItem>
                <FlexItem>
                  <Typography.Text>
                    {specialCard.merchant.merchant_name}
                  </Typography.Text>
                </FlexItem>
                <FlexItem className="divide-x">
                  {specialCard &&
                    implodedTags(specialCard.tags).map((tag, index) => (
                      <Typography.Text
                        size="sm"
                        key={`${tag}-${random()}`}
                        className={index < 1 ? `px-2 pl-0` : `px-2`}
                      >
                        {tag}
                      </Typography.Text>
                    ))}
                </FlexItem>
              </Flex>
            </FlexItem>
          </Flex>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <Space block justify="between" align="center">
            <Space direction="vertical">
              <Typography.Text>
                地址：{specialCard.merchant.address}
              </Typography.Text>
              <Typography.Text>活动时间：{getTimeRange()}</Typography.Text>
            </Space>
            <Space direction="vertical" gap={0} onClick={callMobile}>
              <Phone className="text-gray-500" fontSize={24} />
              <Typography.Text type="secondary">电话</Typography.Text>
            </Space>
          </Space>
        </CardBody>
      </Card>
    ),
    [specialCard]
  );
};

export default SpecialCardHeader;
