import { getToken, setToken, setUser } from "./storage";
import { endsWith, isEmpty, isNil, repeat, split } from "lodash";
import {
  AuthenticateProgress,
  AuthTokenParams,
  WxpayBody,
  WxpayConfig,
  WxSdkConfig,
} from "../request";
import { getAuthToken, getUserInfo } from "../requests/auth";
import {
  AuthenticateState,
  CarportType,
  CouponType,
  Gender,
  PayType,
  PriceUnit,
  Status,
} from "./enum";
import wxSdk from "weixin-js-sdk";
import { Contact, UserCoupon } from "../entity";
import { endOfDay, isAfter, isBefore, parseISO } from "date-fns";
import { getGenderDescription } from "./desctriptor";

export function isLogin(): boolean {
  return !isNil(getToken());
}

export const getQueryParam = (key: string): null | string => {
  const search = window.location.search.substring(1);
  if (search === "") {
    return null;
  }
  const queries = search.split("&");
  for (let i = 0; i < queries.length; i += 1) {
    const [l, r] = queries[i].split("=");
    if (key === l) {
      return r;
    }
  }
  return null;
};

export function appUrl(): string {
  return (process.env.REACT_APP_CLIENT_URL as string) || "";
}

export function clientFullPath(path: string): string {
  const guessPath = path.charAt(0) === "/" ? path : `/${path}`;
  return appUrl() + guessPath;
}

// 获取登录凭证
export function authenticate(
  failedUrl?: string
): Promise<AuthenticateProgress> {
  return new Promise<AuthenticateProgress>((resolve, reject) => {
    const code = getQueryParam("code");
    let queryParams: AuthTokenParams = {
      redirect: clientFullPath(window.location.pathname + location.search),
    };
    if (!isNil(code)) queryParams = { ...queryParams, code };
    getAuthToken(queryParams)
      .then(async (response) => {
        const { authenticating, authenticated, reject } = AuthenticateState;
        if (response.state === "auth_redirect" && response.redirect) {
          window.location.href = response.redirect;
          resolve({
            state: authenticating,
          });
        }
        if (response.success && response.access_token) {
          setToken(response.access_token);
          await getUserInfo().then((user) => {
            setUser(user);
            resolve({
              state: authenticated,
            });
            if (failedUrl) window.location.href = failedUrl;
          });
        }
        resolve({
          state: reject,
        });
      })
      .catch((errors) => {
        reject(errors);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

export function handleWxpay(config: WxpayConfig): Promise<null> {
  return new Promise((resolve, reject) => {
    wxSdk.config({
      appId: config.appId,
      timestamp: config.timestamp,
      nonceStr: config.nonceStr,
      signature: config.paySign,
      jsApiList: ["chooseWXPay"],
    });
    wxSdk.ready(() => {
      wxSdk.chooseWXPay({
        timestamp: config.timestamp,
        nonceStr: config.nonceStr,
        package: config.package,
        signType: config.signType,
        paySign: config.paySign,
        success: (res) => {
          if (res.errMsg === "chooseWXPay:ok") {
            resolve(null);
          } else {
            reject(res.errMsg);
          }
        },
        cancel: (res) => {
          reject(res.errMsg);
        },
        fail: (res) => {
          reject(res.errMsg);
        },
      });
    });
  });
}

export async function resolveOrderPay(
  payType: PayType,
  data: WxpayBody,
  afterPay?: (orderNo: string) => void
) {
  const { order_no, wxpay_config } = data;
  if (payType === PayType.wallet) {
    afterPay?.(order_no);
  } else {
    try {
      await handleWxpay(wxpay_config);
      afterPay?.(order_no);
    } catch (e: any) {
    } finally {
      if (isDev()) afterPay?.(order_no);
    }
  }
}

export function configWxSdk(config: WxSdkConfig): void {
  wxSdk.config({
    debug: config.debug,
    appId: config.appId,
    timestamp: config.timestamp,
    nonceStr: config.nonceStr,
    signature: config.signature,
    jsApiList: config.jsApiList,
  });
}

export function handleScanQRCode(): Promise<number> {
  return new Promise((resolve, reject) => {
    wxSdk.scanQRCode({
      success: function (res) {
        resolve(res.resultStr); // 当needResult 为 1 时，扫码返回的结果
      },
      cancel: (res) => {
        reject(res.errMsg);
      },
      fail: (res) => {
        reject(res.errMsg);
      },
    });
  });
}

export function isDev(): boolean {
  return process.env.NODE_ENV === "development";
}

export function getMaskMobile(value: string): string {
  return isEmpty(value)
    ? ""
    : value.slice(0, 3) + repeat("*", 4) + value.slice(7, 11);
}

export function carportTypeText(type: CarportType): string {
  return type === CarportType.monthly ? "月租车位" : "VIP车位";
}

export function implodedTags(tags: string) {
  if (tags.indexOf("|")) return split(tags, "|");
  return [tags];
}

export function getContactName(contact: Contact): string {
  return contact.username + (contact.gender === Gender.male ? "先生" : "女士");
}

export const getContactFullName = (name: string, gender: Gender) => {
  const genderSuffix = getGenderDescription(gender);
  return endsWith(name, genderSuffix) ? name : name + genderSuffix;
};

export const isBeforeBeginAt = (date: string) =>
  isBefore(new Date(), parseISO(date));

export const isAfterEndAt = (date: string) =>
  isAfter(new Date(), endOfDay(parseISO(date)));

export const reverseStatus = (value: Status) =>
  value === Status.active ? Status.inActive : Status.active;

export const getUnitPrice = (
  value: number,
  unit: PriceUnit = PriceUnit.default,
  unitAmount = 10
) => {
  if (unit == PriceUnit.discount) return (value * unitAmount) / 1000;
  return unitAmount;
};

/**
 * 计算优惠券折扣金额，单位：分
 *
 * @param coupon
 * @param value   订单金额,单位: 分
 */
export const getCouponAmount = (coupon: UserCoupon, value = 0) => {
  switch (coupon.coupon_type) {
    case CouponType.default:
      return coupon.coupon_amount;
    case CouponType.discount:
      return value > 0 ? (coupon.coupon_amount * 100 * value) / 1000 : 0;
    default:
      return value;
  }
};
