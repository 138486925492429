import { FC, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { contactListAtom } from "../../../store/atoms";
import { Button, Cell } from "react-vant";
import { getContactName, getMaskMobile } from "../../../utils/util";
import { CellGroup } from "react-vant/es/cell";
import { Status } from "../../../utils/enum";
import { Contact } from "../../../entity";

const ContactListContent: FC<{
  deleteMode: Status;
  onSubmit: (value: Contact) => void;
}> = ({ deleteMode, onSubmit }) => {
  const list = useRecoilValue(contactListAtom);

  return useMemo(
    () => (
      <CellGroup border={false}>
        {list.map((contact) => (
          <Cell
            key={contact.id}
            center
            border
            title={getContactName(contact)}
            label={getMaskMobile(contact.mobile)}
            value={
              deleteMode === Status.active ? (
                "删除"
              ) : (
                <Button
                  size={"small"}
                  type={"primary"}
                  round
                  className={"px-5"}
                >
                  选择
                </Button>
              )
            }
            size="large"
            onClick={() => {
              onSubmit(contact);
            }}
            valueClass={deleteMode ? "text-red-500" : "text-blue-500"}
          />
        ))}
      </CellGroup>
    ),
    [deleteMode]
  );
};

export default ContactListContent;
