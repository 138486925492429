import Card, { CardBody } from "react-vant/es/card/Card";
import {
  Dialog,
  Divider,
  Empty,
  NoticeBar,
  Space,
  Typography,
} from "react-vant";
import SvgInfoO from "@react-vant/icons/es/InfoO";
import { useRequest } from "ahooks";
import React, { useState } from "react";
import { PromotionReward as PromotionRewardEntity } from "../../entity";
import Placeholder from "./Components/Placeholder";
import { useNavigate } from "react-router-dom";
import SvgArrow from "@react-vant/icons/es/Arrow";
import { getList } from "../../requests/promotion-reward";
import { filter, sum } from "lodash";
import KefuImage from "../../assets/images/kefu.jpg";

const PromotionReward = () => {
  const navigate = useNavigate();
  const [openKefuQr, setOpenKefuQr] = useState(false);
  const [data, setData] = useState<PromotionRewardEntity[]>([]);
  const request = useRequest(getList, {
    onSuccess: setData,
  });

  const rewardMoney = (data: PromotionRewardEntity[]) => {
    const unUseList = filter(data, (it) => it.status == 0).map((o) => o.money);
    return unUseList.length > 0 ? sum(unUseList) / 100 : 0;
  };

  if (request.loading) return <Placeholder />;

  if (data.length > 0)
    return (
      <>
        {rewardMoney(data) > 0 && (
          <NoticeBar
            onClick={() => setOpenKefuQr(true)}
            leftIcon={<SvgInfoO />}
            wrapable
            text={
              <Typography.Text>
                共有{" "}
                <Typography.Text className={"text-red-500"}>
                  {data.length}
                </Typography.Text>{" "}
                张现金券, 合计:{" "}
                <Typography.Text className={"text-red-500"}>
                  {rewardMoney(data)}元
                </Typography.Text>
                , 请在{" "}
                <Typography.Text className={"text-red-500"}>
                  {data[0].order.activity_sale.activity_sale.end_time}
                </Typography.Text>
                之后 联系文体汇客服提现,{" "}
                <Typography.Text className={"text-blue-500"}>
                  查看客服联系方式
                </Typography.Text>
              </Typography.Text>
            }
          />
        )}
        <div className={"p-3"}>
          {data.map((item) => (
            <Card round className={"mb-3"} key={item.id}>
              <CardBody>
                <Space align={"center"} justify={"between"} block>
                  <Space direction={"vertical"} gap={[0, 0]}>
                    <Typography.Title level={5} className={"mb-1"}>
                      活动返现
                    </Typography.Title>
                    <Typography.Text>
                      {item.status == 10 ? "已使用" : item.order.paid_at}
                    </Typography.Text>
                  </Space>
                  <Space direction={"vertical"} gap={[0, 5]}>
                    <Space align={"center"} gap={[5, 5]}>
                      <Typography.Text
                        className={"font-bold text-2xl text-blue-500"}
                      >
                        {item.money / 100}
                      </Typography.Text>
                      <Typography.Text
                        className={"font-bold text-lg text-blue-500"}
                      >
                        元
                      </Typography.Text>
                    </Space>
                  </Space>
                </Space>
                <Divider className={"my-3"} />
                <Space
                  block
                  justify={"between"}
                  align={"center"}
                  onClick={() =>
                    navigate("/user/orders/activity-sale/" + item.order_no)
                  }
                >
                  <Typography.Text type={"secondary"}>
                    返现订单: {item.order_no}
                  </Typography.Text>
                  <SvgArrow className={"text-gray-500"} />
                </Space>
              </CardBody>
            </Card>
          ))}
        </div>
        <Dialog
          visible={openKefuQr}
          closeable
          closeOnClickOverlay
          showConfirmButton={false}
          onClose={() => setOpenKefuQr(false)}
        >
          <img src={KefuImage} alt="客服微信" />
        </Dialog>
      </>
    );

  return <Empty description={"未找到数据"} />;
};

export default PromotionReward;
