import { atom } from "recoil";
import {
  CarportIdentity,
  CheckstandOrder,
  SelectedActivityProjectProps,
} from "../store";
import {
  Contact,
  MerchantProject,
  ParkingCar,
  ParkingCard,
  SpecialCard,
} from "../entity";
import { getCheckstandOrder } from "../utils/storage";
import { ParkingOrderBody } from "../request";

// 钱包余额，单位：元
export const walletRemainAtom = atom<number | string>({
  key: "WalletRemain",
  default: "",
});

// 待认证的车主信息
export const carportIdentityAtom = atom<CarportIdentity | null>({
  key: "CarportIdentity",
  default: null,
});

// 固定车信息
export const parkingCardAtom = atom<ParkingCard | null>({
  key: "ParkingCard",
  default: null,
});

// 车位缴费时间
export const carportCreditMonthAtom = atom<number>({
  key: "CarportCreditMonth",
  default: 12,
});

export const selectedActivityProjectAtom = atom<SelectedActivityProjectProps[]>(
  {
    key: "SelectedActivityProjectAtom",
    default: [],
  }
);

export const checkstandOrderAtom = atom<CheckstandOrder>({
  key: "CheckstandOrderAtom",
  default: getCheckstandOrder(),
});

export const parkingOrderAtom = atom<ParkingOrderBody | null>({
  key: "ParkingOrderAtom",
  default: null,
});

export const contactAtom = atom<Contact | null>({
  key: "ContactAtom",
  default: null,
});

export const parkingCarAtom = atom<ParkingCar[] | []>({
  key: "ParkingCarAtom",
  default: [],
});

export const specialCardAtom = atom<SpecialCard | null>({
  key: "specialCardAtom",
  default: null,
});

export const contactListAtom = atom<any[]>({
  key: "contactListAtom",
  default: [],
});

export const userPresentMerchantProjectAtom = atom<MerchantProject[]>({
  key: "userPresentMerchantProject",
  default: [],
});
