import request from "../utils/request";
import { ParkingCar } from "../entity";
import { ParkingCarFormValues, Response } from "../request";

const ROUTE = "parking/cars";

export function getList(): Promise<ParkingCar[]> {
  return request().get(ROUTE);
}

export function create(data: ParkingCarFormValues): Promise<Response> {
  return request().post(ROUTE, data);
}

export function destroy(id: number): Promise<Response> {
  return request().delete(`${ROUTE}/${id}`);
}
