import axios, { AxiosInstance } from "axios";
import { assign } from "lodash";
import { getToken } from "./storage";
import { authenticate } from "./util";
import { ResponseErrorCode } from "./enum";
import { Toast } from "react-vant";

export default function request(): AxiosInstance {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL as string}/mp`,
    timeout: 10000,
  });

  instance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      if (token) {
        assign(config.headers, {
          Authorization: `Bearer ${token}`,
        });
      }
      return config;
    },
    () => {
      //
    }
  );

  instance.interceptors.response.use(
    async (response) => {
      const { data } = response;
      if (!data.success) {
        if (data.state === "unauthenticated") {
          window.location.href = "/login";
        }
        if (data.state === "invalid_token") {
          await authenticate(window.location.href);
        }
        if (data.state === "code_used") {
          window.location.href =
            window.location.hash + window.location.pathname;
        }
      }
      return data;
    },
    (error) => {
      if (error && error.response) {
        const { status, data } = error.response;
        switch (status) {
          case ResponseErrorCode.validate:
            Toast.info({
              message: data.errors.first,
              position: "bottom",
            });
            break;
          default:
            Toast.info({
              message: data?.message || "",
              position: "bottom",
            });
        }
        return data;
      }
      return error;
    }
  );

  return instance;
}
