import request from "../utils/request";
import { SpecialCard } from "../entity";
import { DataResponse, WxpayBody } from "../request";
import { SpecialCardCheckoutData } from "./data";

const ROUTE = "special-cards";

export function findById(id: number | string): Promise<SpecialCard> {
  return request().get(`${ROUTE}/${id}`);
}

export function pay(
  searchParams: string,
  data: SpecialCardCheckoutData
): Promise<DataResponse<WxpayBody>> {
  return request().post(ROUTE + searchParams, data);
}
