import { Card, Space, Typography } from "react-vant";
import { Arrow } from "@react-vant/icons";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { FC, useMemo } from "react";
import { isNil } from "lodash";
import { useRecoilValue } from "recoil";
import { contactAtom } from "../../../store/atoms";
import { getContactName, getMaskMobile } from "../../../utils/util";

const ContactFilledCell: FC<{ setOpen: (value: boolean) => void }> = ({
  setOpen,
}) => {
  const contact = useRecoilValue(contactAtom);

  if (isNil(contact)) return <></>;

  return useMemo(
    () => (
      <Card>
        <CardHeader border>
          <Typography.Text size={"lg"} className={"text-black"}>
            联系方式
          </Typography.Text>
        </CardHeader>
        <CardBody>
          <Space block direction="vertical" gap={15}>
            <Space block justify={"between"}>
              <div>
                <Typography.Text>{getContactName(contact)}</Typography.Text>
                <Typography.Text>
                  {getMaskMobile(contact.mobile)}
                </Typography.Text>
              </div>

              <Space
                align="center"
                className="ml-5"
                onClick={() => setOpen(true)}
              >
                <Typography.Text className="text-gray-500 -mr-2">
                  修改
                </Typography.Text>
                <Arrow />
              </Space>
            </Space>
          </Space>
        </CardBody>
      </Card>
    ),
    [contact]
  );
};

export default ContactFilledCell;
