import request from "../utils/request";
import { VoteGrant } from "../entity";

const ROUTE = "/vote-grants/latest";

export function getLatestVotes(
  participatorId: number | string
): Promise<VoteGrant[]> {
  return request().get(`${ROUTE}/${participatorId}`);
}
