import request from "../utils/request";
import { ActivityBanner } from "../entity";

const ROUTE = "activity-banners";

export function getUcenterBanner(): Promise<ActivityBanner> {
  return request().get(`${ROUTE}/user-home-t`);
}

export function getParkingStickBanner(): Promise<ActivityBanner> {
  return request().get(`${ROUTE}/parking-t`);
}

export function getCouponReleaseStickBanner(): Promise<ActivityBanner> {
  return request().get(`${ROUTE}/coupon-release-t`);
}

export function getCouponReleaseFastPayBanner(): Promise<ActivityBanner> {
  return request().get(`${ROUTE}/coupon-release-fastpay`);
}
