import request from "../utils/request";
import {
  CarportAddCreditParams,
  DataResponse,
  TwParkingBody,
} from "../request";
import { OrderParkingCredit } from "../entity";

const ROUTE = "parking";

// 车位缴费
export function addCredit(
  data: CarportAddCreditParams
): Promise<DataResponse<TwParkingBody>> {
  return request().post(`${ROUTE}/credit`, data);
}

export function findByOrderNo(orderNo: string): Promise<OrderParkingCredit> {
  return request().get(`${ROUTE}/credit/order/${orderNo}`);
}
