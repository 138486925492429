import { Skeleton } from "react-vant";
import React from "react";

export const Placeholder = () => {
  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <Skeleton row={1} round={false} rowHeight={240} className="px-0" />
      <Skeleton row={5} className={"py-5"} />
      <Skeleton row={3} className={"pt-5"} />
    </div>
  );
};
