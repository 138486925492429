import { Space, Typography } from "react-vant";
import { Merchant } from "../../entity";

const CheckstandHeader = ({ merchant }: { merchant: Merchant | undefined }) => {
  return (
    <Space direction={"vertical"} block className={"text-center pb-10"}>
      <img
        style={{ height: 50 }}
        src={merchant?.logo_url}
        className="mb-1 rounded"
        alt={""}
      />
      <Typography.Text strong size="lg">
        {merchant?.merchant_name}
      </Typography.Text>
    </Space>
  );
};

export default CheckstandHeader;
