import { FC } from "react";
import { ActivityForm } from "../../../entity";
import { isAfterEndAt, isBeforeBeginAt } from "../../../utils/util";
import { ActionBar } from "react-vant";
import { Status } from "../../../utils/enum";
import numeral from "numeral";

const ActivityFormActionButton: FC<{
  activityForm: ActivityForm;
  onSubmit: VoidFunction;
}> = ({ activityForm, onSubmit }) => {
  const getPrice = () => numeral(activityForm.price / 100).format("0.00");
  if (isBeforeBeginAt(activityForm.begin_at))
    return (
      <ActionBar.Button
        type={"primary"}
        disabled
        className={"text-xl"}
        text={"未到报名时间"}
      />
    );

  if (isAfterEndAt(activityForm.end_at))
    return (
      <ActionBar.Button
        type={"primary"}
        disabled
        className={"text-xl"}
        text={"本次报名已结束"}
      />
    );

  if (activityForm.user_activity_form_count >= activityForm.join_limit)
    return (
      <ActionBar.Button
        type={"primary"}
        disabled
        className={"text-xl"}
        text={"报名人数已满"}
      />
    );

  if (activityForm.status !== Status.active)
    return (
      <ActionBar.Button
        type={"primary"}
        disabled
        className={"text-xl"}
        text={"暂停报名"}
      />
    );

  return (
    <ActionBar.Button
      onClick={onSubmit}
      type={"primary"}
      className={"text-xl"}
      text={`¥${getPrice()} 立即报名`}
    />
  );
};

export default ActivityFormActionButton;
