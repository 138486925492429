import { FC } from "react";
import { Cell, Typography } from "react-vant";

const ContactEmptyCell: FC<{ setOpen: (value: boolean) => void }> = ({
  setOpen,
}) => {
  return (
    <Cell
      border={false}
      title={
        <Typography.Text size={"lg"} className={"font-bold text-black"}>
          联系方式
        </Typography.Text>
      }
      titleClass="text-sm"
      value="请选择联系人"
      valueClass="text-orange-500"
      center
      isLink
      onClick={() => setOpen(true)}
      size="large"
    />
  );
};

export default ContactEmptyCell;
