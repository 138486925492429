import { Card, Skeleton } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { FC } from "react";

const Placeholder: FC<{ rows?: number }> = ({ rows = 10 }) => (
  <>
    <Card>
      <CardBody>
        <Skeleton title row={rows} className={"mb-3"} />
      </CardBody>
    </Card>
  </>
);

export default Placeholder;
