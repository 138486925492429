import {
  ActionBar,
  ActionSheet,
  Card,
  Loading,
  Space,
  Typography,
} from "react-vant";
import { Cross } from "@react-vant/icons";
import { CardBody } from "react-vant/es/card/Card";
import PayTypeCell from "./PayTypeCell";
import { useRecoilState } from "recoil";
import { walletRemainAtom } from "../store/atoms";
import { useRequest, useSafeState } from "ahooks";
import getOne from "../requests/user-wallet";
import { useEffect, useState } from "react";
import { PayType } from "../utils/enum";

export default function Cashier({
  open,
  onClose,
  price,
  onSubmit,
}: {
  open: boolean;
  onClose: VoidFunction;
  price: number;
  onSubmit: (value: PayType) => Promise<null>;
}): JSX.Element {
  const [loading, setLoading] = useSafeState(false);
  const [walletRemain, setWalletRemain] = useRecoilState(walletRemainAtom);
  const [payType, setPayType] = useState<PayType>(PayType.wallet);
  const findRequest = useRequest(getOne, {
    manual: true,
    onSuccess: (data) => setWalletRemain(data.data.remain_amount),
  });
  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(payType);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };
  useEffect(() => {
    if (open) findRequest.run();
  }, [open]);
  return (
    <ActionSheet round={false} visible={open} onCancel={onClose}>
      {findRequest.loading ? (
        <div
          className="flex items-center w-full justify-center"
          style={{ height: 390 }}
        >
          <Loading className="text-blue-500" size={60} />
        </div>
      ) : (
        <Card style={{ height: 390 }}>
          <CardBody>
            <Space direction="vertical" block gap={35}>
              <Space justify="between" align="center" block>
                <Typography.Text type="secondary">订单支付</Typography.Text>
                <Typography.Text type="secondary" onClick={onClose}>
                  <Cross fontSize={18} />
                </Typography.Text>
              </Space>
              <div className="flex items-center justify-center">
                <Typography.Text className="text-xl mb-0 mr-1">
                  ¥
                </Typography.Text>
                <Typography.Text className="text-4xl mb-0 text-black">
                  {price}
                </Typography.Text>
              </div>
              <PayTypeCell
                disableWallet={price > walletRemain || walletRemain <= 0}
                onSelected={setPayType}
              />
            </Space>
            <ActionBar>
              <ActionBar.Button
                disabled={loading}
                type="primary"
                className="text-lg"
                onClick={handleSubmit}
              >
                {loading ? "正在支付" : "确认支付"}
              </ActionBar.Button>
            </ActionBar>
          </CardBody>
        </Card>
      )}
    </ActionSheet>
  );
}
