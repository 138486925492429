import { Card, Dialog, Image, Space, Typography } from "react-vant";
import { FC } from "react";
import ParkingPayBannerImage from "../../../assets/images/parking-pay-banner.jpg";
import MpImage from "../../../assets/images/mp.jpg";
import { ActivityBanner } from "../../../entity";

export const ParkingPayBanner: FC<{ data: ActivityBanner }> = ({ data }) => {
  return (
    <Card
      round
      className={"mt-5"}
      onClick={() => {
        Dialog.show({
          title: "快速缴费",
          message: (
            <>
              <Image src={MpImage} />
              <Space direction={"vertical"} align={"center"}>
                <Typography.Text>长按图片关注"武汉文体汇"</Typography.Text>
                <Typography.Text>
                  选择停车缴费-&gt;查询车牌-&gt;优惠券支付
                </Typography.Text>
              </Space>
            </>
          ),
        });
      }}
    >
      <Image src={data.image_url || ParkingPayBannerImage} />
    </Card>
  );
};
