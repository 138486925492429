import { FC, useState } from "react";
import { Contact } from "../../entity";
import {
  ActionSheet,
  Button,
  Card,
  Cell,
  Divider,
  Empty,
  Form,
  Input,
  Loading,
  Radio,
  Space,
  Toast,
  Typography,
} from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { isEmpty } from "lodash";
import FixedBottom from "../FixedBottom";
import SvgPlus from "@react-vant/icons/es/Plus";
import { useContactStore } from "./store/contact";
import SvgSettingO from "@react-vant/icons/es/SettingO";
import { CellGroup } from "react-vant/es/cell";
import { getContactName, getMaskMobile } from "../../utils/util";
import { useAsyncEffect, useRequest } from "ahooks";
import { Arrow, Plus } from "@react-vant/icons";
import { RadioGroup } from "react-vant/es/radio";
import { Gender } from "../../utils/enum";
import { create, destroy } from "../../requests/contact";
import { ContactFormValues } from "../../request";

const ContactSelectionMain: FC<{
  onChange: (value: Contact) => void;
  title?: string;
}> = ({ onChange, title = "联系方式" }) => {
  const { data, handleFetch, loading } = useContactStore();
  const [openContactSheet, setOpenContactSheet] = useState(false);
  const [openContactCreateSheet, setOpenContactCreateSheet] = useState(false);
  const [contact, setContact] = useState<Contact>();
  const [deleteMode, setDeleteMode] = useState(false);
  const { male, female } = Gender;
  const [form] = Form.useForm();
  const request = useRequest(create, { manual: true, throttleWait: 500 });
  const deleteRequest = useRequest(destroy, {
    throttleWait: 500,
    manual: true,
  });
  const onFinished = async (values: ContactFormValues) => {
    const response = await request.runAsync(values);
    if (response.success) {
      setOpenContactCreateSheet(false);
      await handleFetch();
    } else
      Toast({
        message: response.errors?.first || response.message,
        position: "bottom",
      });
  };

  const handleContactClick = async (contact: Contact) => {
    if (deleteMode) {
      await deleteRequest.runAsync(contact.id);
      await handleFetch();
    } else {
      setContact(contact);
      onChange(contact);
      setOpenContactSheet(false);
    }
  };

  useAsyncEffect(async () => {
    if (openContactSheet) await handleFetch();
    else setDeleteMode(false);
  }, [openContactSheet]);

  return (
    <>
      {contact ? (
        <Card>
          <CardHeader border>
            <Typography.Text size={"lg"} className={"text-black"}>
              {title || "联系方式"}
            </Typography.Text>
          </CardHeader>
          <CardBody>
            <Space block justify={"between"}>
              <div>
                <Typography.Text>{getContactName(contact)}</Typography.Text>
                <Typography.Text>
                  {getMaskMobile(contact.mobile)}
                </Typography.Text>
              </div>

              <Space
                align="center"
                className="ml-5"
                onClick={() => setOpenContactSheet(true)}
              >
                <Typography.Text className="text-gray-500 -mr-2">
                  修改
                </Typography.Text>
                <Arrow />
              </Space>
            </Space>
          </CardBody>
        </Card>
      ) : (
        <Cell
          border={false}
          title={
            <Typography.Text size={"lg"} className={"font-bold text-black"}>
              {title || "联系方式"}
            </Typography.Text>
          }
          value="去选择"
          valueClass="text-orange-500"
          center
          isLink
          onClick={() => setOpenContactSheet(true)}
          size="large"
        />
      )}

      <ActionSheet
        visible={openContactSheet}
        onClickOverlay={() => setOpenContactSheet(false)}
      >
        <Card style={{ minHeight: "540px" }}>
          <CardHeader
            extra={
              <Space
                align="center"
                onClick={() => {
                  setDeleteMode(!deleteMode);
                }}
              >
                {deleteMode ? (
                  <Typography.Text>完成</Typography.Text>
                ) : (
                  <Space align="center">
                    <SvgSettingO className="text-gray-500" />
                    <Typography.Text type="secondary">管理</Typography.Text>
                  </Space>
                )}
              </Space>
            }
          >
            <Typography.Title level={4} className="mb-0">
              常用联系人
            </Typography.Title>
          </CardHeader>
          {loading ? (
            <Space block justify="center" align="center">
              <Loading size={65} className="pt-16" />
            </Space>
          ) : (
            <CardBody>
              <Divider className="my-3" />

              {isEmpty(data) ? (
                <Empty description={"没有找到联系人信息"} />
              ) : (
                <CellGroup border={false}>
                  {data.map((contact) => (
                    <Cell
                      key={contact.id}
                      center
                      border
                      title={getContactName(contact)}
                      label={getMaskMobile(contact.mobile)}
                      value={
                        deleteMode ? (
                          "删除"
                        ) : (
                          <Button
                            size={"small"}
                            type={"primary"}
                            round
                            className={"px-5"}
                          >
                            选择
                          </Button>
                        )
                      }
                      size="large"
                      onClick={() => {
                        handleContactClick(contact);
                      }}
                      valueClass={deleteMode ? "text-red-500" : "text-blue-500"}
                    />
                  ))}
                </CellGroup>
              )}
            </CardBody>
          )}
        </Card>
        <FixedBottom>
          <Space block direction={"vertical"} className={"px-3"}>
            <Button
              block
              round
              type="primary"
              icon={<SvgPlus />}
              onClick={() => {
                setOpenContactCreateSheet(true);
                setDeleteMode(false);
              }}
            >
              新增联系人
            </Button>
            <Button round block onClick={() => setOpenContactSheet(false)}>
              取消
            </Button>
          </Space>
        </FixedBottom>
      </ActionSheet>

      <ActionSheet
        visible={openContactCreateSheet}
        onClickOverlay={() => setOpenContactCreateSheet(false)}
        onClosed={() => form.resetFields()}
      >
        <Card>
          <CardHeader>常用联系人</CardHeader>
          <CardBody>
            <Form
              initialValues={{ gender: male }}
              showValidateMessage={false}
              form={form}
              onFinish={onFinished}
              footer={
                <Button
                  loading={request.loading}
                  type="primary"
                  block
                  className="mt-10"
                  round
                  onClick={form.submit}
                >
                  <Space align="center">
                    <Plus />
                    <Typography.Text type="light">添加</Typography.Text>
                  </Space>
                </Button>
              }
            >
              <Form.Item
                label="称呼"
                name="username"
                rules={[{ required: true, message: "请填写您的称呼" }]}
              >
                <Input placeholder="请填写您的称呼" clearable />
              </Form.Item>
              <Form.Item label="性别" name="gender">
                <RadioGroup direction="horizontal">
                  <Radio name={male} className="mb-0">
                    先生
                  </Radio>
                  <Radio name={female}>女士</Radio>
                </RadioGroup>
              </Form.Item>
              <Form.Item
                name="mobile"
                label="手机号"
                required
                rules={[
                  { required: true, message: "请输入您的手机号" },
                  { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确" },
                ]}
              >
                <Input
                  type="number"
                  maxLength={11}
                  placeholder="请填写您的手机号"
                />
              </Form.Item>
            </Form>
          </CardBody>
        </Card>
      </ActionSheet>
    </>
  );
};

export default ContactSelectionMain;
