import { Order } from "../../../../entity";
import { Status } from "../../../../utils/enum";
import {
  findGroupLeader,
  UserGroupBuyContext,
} from "../../../special-card/constant";
import { isNil } from "lodash";
import GroupBuyPanel from "./Components/GroupBuyPanel";
import DefaultPanel from "./Components/DefaultPanel";

export default function SpecialCardPanel({ order }: { order: Order }) {
  const leader = findGroupLeader(order.special_card.user_group_buys);
  const isValidGroup = () => {
    return order.special_card.use_group_buy === Status.active && !isNil(leader);
  };

  if (isValidGroup())
    return (
      <UserGroupBuyContext.Provider
        value={{
          userGroupBuys: order.special_card.user_group_buys,
          leader,
        }}
      >
        <GroupBuyPanel order={order} />
      </UserGroupBuyContext.Provider>
    );

  return <DefaultPanel order={order} />;
}
