import { FC, useMemo } from "react";
import { Space, Tabs, Typography } from "react-vant";
import TabPane from "react-vant/es/tabs/TabPane";
import { orderBy, take } from "lodash";
import SpecialCardProjectContent from "../../special-card-project/Components/SpecialCardProjectContent";
import { Arrow } from "@react-vant/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SpecialCard } from "../../../entity";

const SpecialCardCenterTab: FC<{ specialCard: SpecialCard }> = ({
  specialCard,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const showLimit = 3;

  return useMemo(
    () => (
      <Tabs
        align="start"
        swipeable
        lineWidth={50}
        color="#f44336"
        titleActiveColor="#000000"
      >
        <TabPane title="课程选购" titleClass="px-0 mr-5 text-base">
          {take(
            orderBy(specialCard.special_card_projects, "order", "desc"),
            showLimit
          ).map((specialCardProject) => (
            <SpecialCardProjectContent
              key={specialCardProject.id}
              specialCard={specialCard}
              specialCardProject={specialCardProject}
            />
          ))}
          {specialCard.special_card_projects.length > showLimit && (
            <Space
              block
              align="center"
              justify="center"
              className="mt-5"
              onClick={() =>
                navigate(
                  `/activity/special-card/${id}/project${location.search}`
                )
              }
            >
              <Typography.Text>
                查看更多
                {(specialCard.special_card_projects.length || showLimit) -
                  showLimit}
                个项目
              </Typography.Text>
              <Arrow />
            </Space>
          )}
        </TabPane>
        <TabPane title="购买须知" titleClass="text-base">
          <Space direction="vertical" block className="pt-5">
            {specialCard.tips.map((tip, index) => (
              <Typography.Text key={tip.id}>
                {index + 1}、{tip.content}
              </Typography.Text>
            ))}
          </Space>
        </TabPane>
      </Tabs>
    ),
    [specialCard]
  );
};

export default SpecialCardCenterTab;
