import { selector } from "recoil";
import {
  carportCreditMonthAtom,
  carportIdentityAtom,
  parkingCardAtom,
  selectedActivityProjectAtom,
} from "./atoms";
import { first, floor, isNil, join, split, sum } from "lodash";
import { CarportType } from "../utils/enum";
import config from "../config/config";
import { ParkingCarport } from "../entity";
import {
  addMonths,
  format,
  formatDistanceStrict,
  isBefore,
  parseISO,
  subDays,
} from "date-fns";
import { zhCN } from "date-fns/locale";
import { addDays } from "react-vant/es/datetime-picker/utils";
import { ActionSheetAction } from "react-vant/es/action-sheet/PropsType";

// 车位认证手机号，可能有多个
export const carportAuthMobileSelector = selector<string[]>({
  key: "CarportAuthMobileSelector",
  get: ({ get }) => {
    const carportIdentity = get(carportIdentityAtom);
    if (carportIdentity?.mobile) {
      if (carportIdentity?.mobile.indexOf(",") !== -1) {
        return split(carportIdentity?.mobile, ",");
      }
      return [carportIdentity?.mobile];
    }
    return [];
  },
});

// 车位绑定车牌
export const carportLicenseNoSelector = selector<string>({
  key: "CarportLicenseNos",
  get: ({ get }) => {
    const parkingCard = get(parkingCardAtom);
    if (parkingCard?.licenses)
      return join(
        parkingCard?.licenses?.map((license) => license.license_no),
        ", "
      );
    return "";
  },
});

// 默认车位
export const defaultCarportSelector = selector<ParkingCarport | null>({
  key: "FirstCarportSelector",
  get: ({ get }) => {
    return first(get(parkingCardAtom)?.carports) || null;
  },
});

// 车位类型
export const carportTypeNameSelector = selector<string>({
  key: "CarportTypeNameSelector",
  get: ({ get }) => {
    return get(defaultCarportSelector)?.type === CarportType.vip
      ? "VIP车位"
      : "月租车位";
  },
});

// 是否第一次缴费
export const carportFirstCreditSelector = selector<boolean>({
  key: "CarportFirstCredit",
  get: ({ get }) => {
    return isNil(get(defaultCarportSelector)?.end_at);
  },
});

// 车位缴费价格
export const carportCreditPriceSelector = selector<number>({
  key: "CarportCreditPrice",
  get: ({ get }) => {
    const {
      carportMonthlyCreditPrice,
      carportRelationCreditPrice,
      carportSingleCreditPrice,
    } = config;
    const carport = first(get(parkingCardAtom)?.carports);
    if (CarportType.vip === carport?.type) {
      if (carport.count > 1) {
        return carportRelationCreditPrice;
      }
      return carportSingleCreditPrice;
    }
    return carportMonthlyCreditPrice;
  },
});

// 续费赠送时长
export const carportCreditRewardSelector = selector<number>({
  key: "CarportCreditRewardSelector",
  get: ({ get }) => {
    const creditMonth = get(carportCreditMonthAtom);
    return creditMonth >= 12 ? floor(creditMonth / 12) : 0;
  },
});

// 缴费选项
export const carportCreditActionsSelector = selector<ActionSheetAction[]>({
  key: "CarportCreditActionsSelector",
  get: ({ get }) => {
    const defaultCarport = get(defaultCarportSelector);
    if (defaultCarport?.type === CarportType.monthly) {
      return Array.from(new Array(4)).map((n, i) => {
        const unit = (i + 1) * 3;
        return {
          name: `${unit}个月`,
          subname: unit >= 12 ? `赠${floor(unit / 12)}个月` : "",
          className: "rv-Parking-credit-sheet-button",
        };
      });
    }
    return Array.from(new Array(5)).map((n, i) => {
      return {
        name: `${(i + 1) * 12}个月`,
        subname: `赠${i + 1}个月`,
        className: "rv-Parking-credit-sheet-button",
      };
    });
  },
});

// 判断车位是否过期
export const carportOutOfDateSelector = selector<boolean>({
  key: "CarportOutOfDateSelector",
  get: ({ get }) => {
    const fns = addDays(
      parseISO(get(defaultCarportSelector)?.end_at as string),
      1
    );
    const endDay = new Date();
    return isBefore(fns, endDay);
  },
});

// 友好显示车位剩余天数
export const friendlyEndAtSelector = selector<string | null>({
  key: "FriendlyEndAtSelector",
  get: ({ get }) => {
    const defaultCarport = get(defaultCarportSelector);
    if (get(carportFirstCreditSelector) || isNil(defaultCarport?.begin_at))
      return null;
    const fns = addDays(parseISO(defaultCarport?.end_at as string), 1);
    const endDay = new Date();
    if (isBefore(fns, endDay)) {
      // 月租车位不显示过期时间
      if (defaultCarport?.type === CarportType.monthly) return null;
      return `已到期 ${formatDistanceStrict(fns, endDay, {
        locale: zhCN,
        unit: "day",
      })}`;
    }
    const formatted = formatDistanceStrict(endDay, fns, {
      locale: zhCN,
    });
    return `剩余: ${formatted}`;
  },
});

// 车位续费开始时间
export const carportCreditBeginAtSelector = selector<string>({
  key: "CarportCreditAtSelector",
  get: ({ get }) => {
    const defaultCarport = get(defaultCarportSelector);
    const creditedBeginAt = (date: Date) =>
      format(addDays(date, 1), "yyyy-MM-dd");
    // 到期月租车位、未缴费的vip车位从当天开始续费，其他无区别
    if (
      (defaultCarport?.type === CarportType.monthly &&
        get(carportOutOfDateSelector)) ||
      isNil(defaultCarport?.end_at)
    ) {
      return creditedBeginAt(new Date());
    }
    // vip车位从到期当天开始续费
    return creditedBeginAt(parseISO(defaultCarport?.end_at as string));
  },
});

// 车位续费后到期时间
export const carportEndAtAfterCreditSelector = selector<string>({
  key: "CarportEndAtAfterCredit",
  get: ({ get }) => {
    const creditMonths = get(carportCreditMonthAtom);
    const creditRewardMonth = get(carportCreditRewardSelector);
    const carportCreditBeginAt = get(carportCreditBeginAtSelector);
    const creditedEndAt = (date: Date) =>
      format(
        subDays(addMonths(date, creditMonths + creditRewardMonth), 1),
        "yyyy-MM-dd"
      );
    return creditedEndAt(parseISO(carportCreditBeginAt));
  },
});

// 车位缴费应付金额
export const carportCreditShouldPaySelector = selector<number>({
  key: "CarportCreditShouldPay",
  get: ({ get }) => {
    const defaultCarport = get(defaultCarportSelector);
    const price = get(carportCreditPriceSelector);
    const creditMonth = get(carportCreditMonthAtom);
    const unit = get(carportCreditRewardSelector);
    if (defaultCarport?.type === CarportType.monthly) {
      return price * creditMonth * 100;
    }
    return price * unit * 100;
  },
});

// 无团购价格
export const specialCardPriceSelector = selector({
  key: "specialCardPriceSelector",
  get: ({ get }) => {
    const projects = get(selectedActivityProjectAtom);
    const currentPrices = projects.map((project) => project.currentPrice);
    const originPrices = projects.map((project) => project.originPrice);
    return {
      currentPrice: sum(currentPrices),
      originPrice: sum(originPrices),
    };
  },
});
