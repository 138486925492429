import { Card, Cell } from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { CellGroup } from "react-vant/es/cell";
import { FC } from "react";
import { ContentCellGroup } from "../../../../type";

const ContentCard: FC<{
  item: ContentCellGroup;
}> = ({ item }) => {
  return (
    <Card round>
      <CardHeader border>{item.title}</CardHeader>
      <CardBody className="px-0">
        <CellGroup border={false}>
          {item.children.map((children, index) => (
            <Cell
              key={`${item.title + index}`}
              titleClass={children.titleClass ?? "text-gray-500"}
              valueClass={`text-black ${children.valueClass}`}
              title={children.title}
              value={children.value}
              border={false}
              isLink={children.isLink}
              center={children.center}
              label={children.label}
              icon={children.icon}
              onClick={children.onClick}
              rightIcon={children.rightIcon}
            />
          ))}
        </CellGroup>
      </CardBody>
    </Card>
  );
};

export default ContentCard;
