import { create } from "zustand";
import { getList } from "../../../../requests/user-coupon";
import { getCouponCell } from "../constant";
import {
  BannerState,
  ParkingCouponState,
  ParkingOrderState,
  UserCouponState,
} from "./state";
import {
  BannerAction,
  ParkingCouponAction,
  ParkingOrderAction,
  UserCouponAction,
} from "./action";
import { findOrderByPlateNo } from "../../../../requests/parking-fee";
import { getPreUseList } from "../../../../requests/parking-coupon";
import { getParkingStickBanner } from "../../../../requests/activity-banner";
import { sumBy } from "lodash";

export const useParkingOrderStore = create<
  ParkingOrderState & ParkingOrderAction
>((set) => ({
  data: null,
  useUserCoupon: true,
  setUseUserCoupon: (value: boolean) => {
    set({ useUserCoupon: value });
  },
  handleFetch: async (carLicense: string) => {
    const response = await findOrderByPlateNo(carLicense);
    if (response.success && response.data) {
      set({ data: response.data });
    }
  },
}));

export const useParkingCouponStore = create<
  ParkingCouponState & ParkingCouponAction
>((set) => ({
  data: [],
  discountMoney: 0,
  handleFetch: async (carLicense: string) => {
    const data = await getPreUseList(carLicense);
    const discountMoney = sumBy(
      data,
      (item) => item.merchant_parking_coupon.amount * item.use_count
    );
    set({ data, discountMoney });
  },
}));

export const useBannerStore = create<BannerState & BannerAction>((set) => ({
  loading: false,
  data: null,
  handleFetch: async () => {
    set({ data: await getParkingStickBanner() });
  },
}));

export const useUserCouponStore = create<UserCouponState & UserCouponAction>(
  (set, get) => ({
    data: [],
    dataCell: [],
    selected: -1,
    selectedCoupon: null,
    setSelected: (index: number) => {
      if (index >= 0) {
        const coupon = get().data.length > 0 ? get().data[index] : null;
        if (coupon) set({ selected: index, selectedCoupon: coupon });
      } else set({ selected: index, selectedCoupon: null });
    },
    handleFetch: async () => {
      const response = await getList();
      set({
        data: response,
        dataCell: getCouponCell(response),
      });
    },
  })
);
