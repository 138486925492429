import { FC, useMemo } from "react";
import { Status } from "../../../utils/enum";
import { CardHeader } from "react-vant/es/card/Card";
import { Space, Typography } from "react-vant";
import SvgSettingO from "@react-vant/icons/es/SettingO";
import { reverseStatus } from "../../../utils/util";

const ContactSheetHeader: FC<{
  onChange: (value: Status) => void;
  deleteMode: Status;
}> = ({ onChange, deleteMode }) => {
  return useMemo(
    () => (
      <CardHeader
        extra={
          <Space
            align="center"
            onClick={() => {
              onChange(reverseStatus(deleteMode));
            }}
          >
            {deleteMode ? (
              <Typography.Text>完成</Typography.Text>
            ) : (
              <Space align="center">
                <SvgSettingO className="text-gray-500" />
                <Typography.Text type="secondary">管理</Typography.Text>
              </Space>
            )}
          </Space>
        }
      >
        <Typography.Title level={4} className="mb-0">
          常用联系人
        </Typography.Title>
      </CardHeader>
    ),
    [deleteMode]
  );
};

export default ContactSheetHeader;
