import { Button, Image, Space, Toast, Typography } from "react-vant";
import { implodedTags } from "../../../utils/util";
import {
  ActivityProject,
  SpecialCard,
  SpecialCardProject,
} from "../../../entity";
import { useLocation, useNavigate } from "react-router-dom";
import { Plus, Success } from "@react-vant/icons";
import { take, truncate } from "lodash";
import { FC, useMemo } from "react";
import useActivityProjectOperation from "../../../hooks/useActivityProjectOperation";
import SvgArrow from "@react-vant/icons/es/Arrow";

const SpecialCardProjectContent: FC<{
  specialCard: SpecialCard;
  specialCardProject: SpecialCardProject;
}> = ({ specialCardProject, specialCard }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, addSelected, removeSelected] = useActivityProjectOperation();

  const handleAddSelected = (value: ActivityProject) => {
    if (selected.length >= specialCard.project_limit)
      return Toast({
        message: `最多选购${specialCard.project_limit}项`,
        position: "bottom",
      });
    addSelected(value);
  };

  return useMemo(
    () => (
      <>
        <Space justify="between" block className="my-3">
          <Space
            gap={10}
            block
            onClick={() =>
              navigate(
                `/activity/project/${specialCardProject.id}${location.search}`
              )
            }
          >
            <Image
              src={specialCardProject.activity_project.thumbnail_url}
              width={90}
              height={90}
              radius={5}
            />
            <Space block direction="vertical">
              <Typography.Title level={4} className="mb-0">
                {truncate(specialCardProject.activity_project.title, {
                  length: 9,
                  omission: "..",
                })}
              </Typography.Title>
              <div className="divide-x">
                {specialCardProject.activity_project.tags &&
                  take(
                    implodedTags(specialCardProject.activity_project.tags),
                    2
                  ).map((tag, index) => (
                    <Typography.Text
                      key={tag}
                      className={index < 1 ? "px-2 pl-0" : "px-2"}
                      type="secondary"
                    >
                      {tag}
                    </Typography.Text>
                  ))}
              </div>
              <Space align="center" gap={20}>
                <Typography.Text strong className="text-red-500" size="lg">
                  ¥{specialCardProject.activity_project.current_price / 100}
                </Typography.Text>
                <Space align={"center"} gap={2}>
                  <Typography.Text type="secondary" size="sm">
                    点击查看
                  </Typography.Text>
                  <SvgArrow fontSize={12} />
                </Space>
              </Space>
            </Space>
          </Space>
          <Space
            direction="vertical"
            style={{ height: "100%" }}
            justify={"center"}
            align={"center"}
          >
            {selected.find(
              (s) => s.id === specialCardProject.activity_project.id
            ) ? (
              <Button
                round
                plain
                icon={<Success />}
                type="danger"
                className="text-sm px-4"
                size="small"
                onClick={() =>
                  removeSelected(specialCardProject.activity_project.id)
                }
              >
                已选
              </Button>
            ) : (
              <Button
                round
                icon={<Plus />}
                type="danger"
                className="text-sm px-4 bg-red-400 border-red-400 text-white"
                size="small"
                onClick={() =>
                  handleAddSelected(specialCardProject.activity_project)
                }
              >
                选择
              </Button>
            )}
          </Space>
        </Space>
      </>
    ),
    [specialCardProject, selected]
  );
};

export default SpecialCardProjectContent;
