import { CellGroup } from "react-vant/es/cell";
import { Cell, Image, Space, Typography } from "react-vant";
import svgIcon from "../assets/svg";
import { fCurrency } from "../utils/formatNumber";
import { PayType } from "../utils/enum";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { walletRemainAtom } from "../store/atoms";
import { Checked } from "@react-vant/icons";

interface PayTypeCellProps {
  icon: string;
  title: string;
  label: string;
  value: PayType;
  disabled: boolean;
}

export default function PayTypeCell({
  disableWallet,
  onSelected,
}: {
  disableWallet: boolean;
  onSelected: (value: PayType) => void;
}): JSX.Element {
  const walletRemain = useRecoilValue(walletRemainAtom);
  const { wxpay, wallet } = PayType;
  const [value, setValue] = useState<PayType>(wallet);
  const { WxPay, WalletPay } = svgIcon;
  const types: PayTypeCellProps[] = [
    {
      icon: WalletPay,
      title: "钱包支付",
      label: `(剩余:¥${fCurrency(walletRemain)})`,
      value: wallet,
      disabled: disableWallet,
    },
    {
      icon: WxPay,
      title: "微信支付",
      label: "",
      value: wxpay,
      disabled: false,
    },
  ];
  const handleSelect = (row: PayTypeCellProps) => {
    if (!row.disabled) setValue(row.value);
  };

  useEffect(() => onSelected(value), [value]);

  useEffect(() => setValue(disableWallet ? wxpay : wallet), [disableWallet]);

  return (
    <CellGroup border={false}>
      {types.map((row) => (
        <Cell
          key={row.title}
          icon={<Image src={row.icon} width={24} height={24} />}
          size="large"
          border={false}
          title={
            <Space>
              <Typography.Text size="lg">{row.title}</Typography.Text>
              <Typography.Text size="lg">{row.label}</Typography.Text>
            </Space>
          }
          onClick={() => handleSelect(row)}
          rightIcon={
            row.value === value &&
            !row.disabled && <Checked className="text-2xl text-blue-500" />
          }
          titleClass="ml-3"
          className={row.disabled ? "px-0 opacity-50" : "px-0 "}
          center
        />
      ))}
    </CellGroup>
  );
}
