import { FC, useContext, useMemo } from "react";
import { Card, Empty } from "react-vant";
import { GroupActionContext } from "../constant";
import { isNil } from "lodash";
import GroupBuyHeader from "./GroupBuyHeader";
import GroupBuyCell from "./GroupBuyCell";

const GroupBuyPreview: FC = () => {
  const context = useContext(GroupActionContext);

  if (isNil(context) || context.loading) return <></>;

  const Content = () => {
    if (context.loading) return <></>;
    if (context.userGroupBuys.length > 0) return <GroupBuyCell />;

    return (
      <Empty
        imageSize={70}
        className={"pt-0"}
        description={"暂无用户发布拼团~"}
      />
    );
  };

  return useMemo(
    () => (
      <div className={"pb-40 mt-2"}>
        <Card>
          <GroupBuyHeader />

          <Content />
        </Card>
      </div>
    ),
    [context.loading, context.userGroupBuys]
  );
};

export default GroupBuyPreview;
