import { Flex, Typography } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import { Checked } from "@react-vant/icons";

export default function ResultHead({
  title = "支付成功",
  className = "text-green-500",
}: {
  title?: string;
  className?: string;
}): JSX.Element {
  return (
    <Flex direction="column" align="center" gutter={[0, 15]}>
      <FlexItem>
        <Checked fontSize={70} className={className} />
      </FlexItem>
      <FlexItem>
        <Typography.Text size="lg" className="font-bold">
          {title}
        </Typography.Text>
      </FlexItem>
    </Flex>
  );
}
