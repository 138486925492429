import { Toast, Typography } from "react-vant";
import config from "../../../config/config";
import { useCountDown } from "react-vant/es/hooks";
import { useRequest } from "ahooks";
import { getMessageCode } from "../../../requests/auth";

export default function MessageCountDown({
  onGetCode,
}: {
  onGetCode: () => Promise<{ mobile: string }>;
}): JSX.Element {
  const { start, reset, current } = useCountDown({
    time: config.messageInterval,
  });
  const messageRequest = useRequest(getMessageCode, {
    manual: true,
    throttleWait: 1000,
    onSuccess: (data) => {
      if (data.success) {
        reset(config.messageInterval);
        start();
      } else {
        Toast({
          message: data.errors?.first || data.message,
          position: "bottom",
        });
      }
    },
  });

  const handleRefreshSeconds = () => {
    if (!messageRequest.loading)
      onGetCode()
        .then((res) => {
          if (res) messageRequest.run(res.mobile);
        })
        .catch(() => {});
  };
  return current.seconds > 0 ? (
    <Typography.Text disabled type="secondary">
      {current.seconds}s
    </Typography.Text>
  ) : (
    <Typography.Text type="success" onClick={handleRefreshSeconds}>
      获取验证码
    </Typography.Text>
  );
}
