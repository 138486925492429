import request from "../utils/request";
import { Link } from "../entity";
import { DataResponse, Response } from "../request";

const ROUTE = "weekend-present-releases";

export function findByCode(code: string): Promise<DataResponse<Link>> {
  return request().get(`${ROUTE}/${code}`);
}

export function receive(code: string): Promise<Response> {
  return request().post(`${ROUTE}/${code}`);
}

export function userReceived(code: string): Promise<number> {
  return request().get(`${ROUTE}/received/${code}`);
}
