import { Image, Toast } from "react-vant";
import { useRequest, useTitle } from "ahooks";
import { findByCode } from "../../requests/present-release";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "../../entity";
import ReceiveButton from "./ReceiveButton";

export default function WeekendPresentRelease(): JSX.Element {
  useTitle("好礼相送");
  const { code } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [link, setLink] = useState<Link>();
  const findRequest = useRequest(findByCode, {
    manual: true,
    onSuccess: (data) => {
      if (data.success) setLink(data.data);
      else navigate("/404", { replace: true });
      Toast.clear();
    },
  });
  useEffect(() => {
    Toast.loading({
      message: "",
      forbidClick: true,
    });
    if (code) findRequest.run(code + location.search);
  }, [code]);
  return (
    <>
      <div>
        {link && (
          <>
            <Image src={link?.weekend_present_release?.banner_url} />
            <div className="absolute top-2/3 z-10 w-full text-center">
              <ReceiveButton row={link} />
            </div>
          </>
        )}
      </div>
    </>
  );
}
