import { Route, Routes } from "react-router-dom";
import Checkstand from "../pages/checkstand/Main";
import Login from "../pages/login/Login";
import UserHome from "../pages/user/Home";
import Order from "../pages/user/order/UserOrderList";
import WeekendPresentList from "../pages/user/weekend-presents/List";
import PresentView from "../pages/user/weekend-presents/View";
import Wallet from "../pages/user/wallet/Main";
import Credit from "../pages/user/wallet/Credit";
import BasicLayout from "../layouts/BasicLayout";
import Coupon from "../pages/user/coupon/List";
import WalletCreditResult from "../pages/user/wallet/Result";
import Kabao from "../pages/user/kabao/List";
import Security from "../pages/user/security/List";
import UpdateMobile from "../pages/user/security/UpdateMobile";
import Parking from "../pages/Parking/home/Main";
import LicenseSearch from "../pages/Parking/home/LicenseSearch";
import ParkingAuth from "../pages/Parking/home/CarportAuth";
import ParkingCreditResult from "../pages/Parking/credit/Result";
import ParkingCredit from "../pages/Parking/credit/Main";
import InvestorAccount from "../pages/investor-account/Main";
import VipNotFound from "../pages/investor-account/NotFound";
import ActiveVipResult from "../pages/investor-account/Result";
import SpecialCardMain from "../pages/special-card/SpecialCardMain";
import SpecialCardCheckout from "../pages/special-card/SpecialCardCheckout";
import SpecialCardResult from "../pages/special-card/SpecialCardCheckoutResult";
import CheckstandResult from "../pages/checkstand/Result";
import CheckstandDetail from "../pages/checkstand/Detail";
import ParkingLicenseSearch from "../pages/Parking/TempFee/ParkingOrderSearch";
import UserParkingCarCreate from "../pages/Parking/car/Create";
import UserParkingCar from "../pages/Parking/car/Main";
import ParkingFeeCheckout from "../pages/Parking/TempFee/ParkingOrderCheckout";
import ParkingFeeResult from "../pages/Parking/TempFee/Result";
import NotFound from "../pages/404";
import OrderSpecialCardView from "../pages/user/order/SpecialCard/SpecialCardView";
import OrderParkingFeeView from "../pages/user/order/ParkingFee/ParkingFeeView";
import OrderCheckstandView from "../pages/user/order/Checkstand/CheckstandView";
import WeekendPresentRelease from "../pages/weekend-present-release/Main";
import ParkingOrderSearchResult from "../pages/Parking/TempFee/ParkingOrderSearchResult";
import { FC } from "react";
import Vote from "../pages/Vote/Vote";
import VoteSignup from "../pages/VoteParticipator/VoteSignup";
import ParticipatorView from "../pages/VoteParticipator/ParticipatorView";
import VoteRankList from "../pages/VoteRank/VoteRankList";
import VoteNote from "../pages/Vote/Note";
import VipLogin from "../pages/vip-recover/Login";
import ClearToken from "../pages/common/ClearToken";
import Result from "../pages/vip-recover/Result";
import Cdkey from "../pages/user/cdkey/Main";
import WeekendPresentRecord from "../pages/user/weekend-presents/Record";
import Home from "../pages/Home";
import ActivityProjectList from "../pages/special-card-project/SpecialCardProjectMain";
import SpecialCardProjectView from "../pages/special-card-project/SpecialCardProjectView";
import SpecialCardGroupBuyResult from "../pages/special-card/SpecialCardGroupBuyResult";
import OrderActivityFormView from "../pages/user/order/ActivityForm/ActivityFormView";
import SpecialCardPoster from "../pages/special-card/SpecialCardPoster";
import ActivityFormResult from "../pages/ActivityForm/ActivityFormResult";
import ActivityFormMain from "../pages/ActivityForm/ActivityFormMain";
import CouponReleaseMain from "../pages/CouponRelease/CouponReleaseMain";
import ParkingOrderSearchFailed from "../pages/Parking/TempFee/Components/ParkingOrderSearchFailed";
import ActivitySaleMain from "../pages/ActivitySale/ActivitySaleMain";
import MerchantProjectMain from "../pages/MerchantProject/MerchantProjectMain";
import MerchantProjectCheckout from "../pages/MerchantProject/MerchantProjectCheckout";
import MerchantProjectCheckoutResult from "../pages/MerchantProject/MerchantProjectCheckoutResult";
import ActivitySaleView from "../pages/user/order/ActivitySale/ActivitySaleView";
import ActivitySaleVerifyOrder from "../pages/ActivitySale/VerifyOrder";
import VerifyOrderResult from "../pages/ActivitySale/VerifyOrderResult";
import PromotionReward from "../pages/ActivitySale/PromotionReward";
import ActivitySalePoster from "../pages/ActivitySale/ActivitySalePoster";
import ParkingCouponUse from "../pages/ParkingCoupon/Use";

const Router: FC = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />

        {/* 登录 */}
        <Route path="login" element={<Login />} />

        {/* 周年庆 */}
        <Route path={"/activity-sale"} element={<BasicLayout />}>
          <Route
            path={"verify-order/:orderNo"}
            element={<ActivitySaleVerifyOrder />}
          />
          <Route path={"verify-order-result"} element={<VerifyOrderResult />} />
          <Route path={":activitySaleId"} element={<ActivitySalePoster />} />
          <Route
            path={"entry/:activitySaleId"}
            element={<ActivitySaleMain />}
          />
          <Route
            path={"merchant-project/:id"}
            element={<MerchantProjectMain />}
          />
          <Route
            path={"merchant-project/checkout/:id"}
            element={<MerchantProjectCheckout />}
          />
          <Route
            path={"merchant-project/checkout/result/:orderNo"}
            element={<MerchantProjectCheckoutResult />}
          />
        </Route>

        {/* 课程返现 */}
        <Route path={"/promotion-reward"} element={<BasicLayout />}>
          <Route path={""} element={<PromotionReward />}></Route>
        </Route>

        {/* 兑换码 */}
        <Route path="cdkey" element={<BasicLayout />}>
          <Route path={""} element={<Cdkey />} />
          <Route path={":code"} element={<Cdkey />} />
        </Route>

        {/* 会员中心 */}
        <Route path="user" element={<BasicLayout />}>
          <Route index element={<UserHome />} />
          {/* 我的订单 */}
          <Route path="orders">
            <Route index element={<Order />} />
            <Route
              path="special-card/:orderNo"
              element={<OrderSpecialCardView />}
            />
            <Route
              path="parking-fee/:orderNo"
              element={<OrderParkingFeeView />}
            />
            <Route
              path="checkstand/:orderNo"
              element={<OrderCheckstandView />}
            />
            <Route
              path="activity-form/:orderNo"
              element={<OrderActivityFormView />}
            />
            <Route
              path="activity-sale/:orderNo"
              element={<ActivitySaleView />}
            />
          </Route>

          {/* 抵扣券 */}
          <Route path="coupons" element={<Coupon />} />

          {/* 福利兑换 */}
          <Route path="weekend-presents">
            <Route index element={<WeekendPresentList />} />
            <Route path="record" element={<WeekendPresentRecord />} />

            <Route path="view/:id" element={<PresentView />} />
          </Route>

          {/* 我的钱包 */}
          <Route path="wallet">
            <Route index element={<Wallet />} />
            <Route path="credit" element={<Credit />} />
            <Route path="credit/:orderNo" element={<WalletCreditResult />} />
          </Route>

          {/* 卡包 */}
          <Route path="kabao" element={<Kabao />} />

          {/* 账户安全 */}
          <Route path="security">
            <Route index element={<Security />} />
            {/* 绑定/更换手机号 */}
            <Route path="update-mobile" element={<UpdateMobile />} />
          </Route>
        </Route>

        <Route path="activity" element={<BasicLayout />}>
          {/* 文体汇套卡 */}
          <Route path="special-card" element={<BasicLayout />}>
            <Route path="poster/:id" element={<SpecialCardPoster />} />
            <Route path=":id" element={<SpecialCardMain />} />
            <Route path="checkout/:id" element={<SpecialCardCheckout />} />
            <Route
              path="checkout/result/:orderNo"
              element={<SpecialCardResult />}
            />
            <Route
              path="checkout/group-buy/result/:orderNo"
              element={<SpecialCardGroupBuyResult />}
            />
            <Route path=":id/project" element={<ActivityProjectList />} />
          </Route>
          {/* 活动项目 */}
          <Route path="project/:id" element={<SpecialCardProjectView />} />
        </Route>

        <Route path={"coupon"} element={<BasicLayout />}>
          <Route path={"release/:id"} element={<CouponReleaseMain />} />
        </Route>

        {/* 车位与临停 */}
        <Route path="parking" element={<BasicLayout />}>
          <Route index element={<Parking />} />
          {/* 车主认证 */}
          <Route path="auth/:licenseNo" element={<ParkingAuth />} />
          {/* 车牌查询 */}
          <Route path="license-search" element={<LicenseSearch />} />
          {/* 车位缴费 */}
          <Route path="credit/:licenseNo" element={<ParkingCredit />} />
          {/* 车位缴费结果 */}
          <Route
            path="credit/result/:orderNo"
            element={<ParkingCreditResult />}
          />
          {/* 车位租赁 */}
          <Route path="rent" element={<Parking />} />
          {/* 车位信息 */}
          <Route path="carport" element={<Parking />} />
          {/* 缴费记录 */}
          <Route path="credit/log" element={<Parking />} />

          {/* 临停缴费 */}
          <Route path="fee">
            {/* 临停订单查询 */}
            <Route index element={<ParkingLicenseSearch />} />
            {/* 临停订单查询 */}
            <Route path=":licenseNo" element={<ParkingLicenseSearch />} />
            {/* 临停订单查询结果 */}
            <Route
              path="find/:licenseNo"
              element={<ParkingOrderSearchResult />}
            />
            {/* 临停查询失败 */}
            <Route
              path="find/:licenseNo/failed"
              element={<ParkingOrderSearchFailed />}
            />
            {/* 临停订单支付 */}
            <Route
              path="checkout/:licenseNo"
              element={<ParkingFeeCheckout />}
            />
            {/* 临停订单支付结果 */}
            <Route path="result">
              <Route index element={<ParkingFeeResult />} />
              <Route path=":orderNo" element={<ParkingFeeResult />} />
            </Route>
          </Route>

          {/* 常用车牌 */}
          <Route path="car" element={<UserParkingCar />} />
          <Route path="car/add" element={<UserParkingCarCreate />} />
        </Route>

        {/* 停车券 */}
        <Route path={"parking-coupon"}>
          <Route path={"use/:code"} element={<ParkingCouponUse />} />
        </Route>

        <Route path={"activity-form"}>
          <Route path={":id"} element={<ActivityFormMain />} />
          <Route path={"result/:orderNo"} element={<ActivityFormResult />} />
        </Route>

        {/* 收银台 */}
        <Route path="checkstand" element={<BasicLayout />}>
          {/* 门店收款 */}
          <Route path=":code" element={<Checkstand />} />
          <Route path="detail/:code" element={<CheckstandDetail />} />
          {/* 电费收款 */}
          <Route path="electric/:code" element={<Checkstand />} />
          {/* 空调收款 */}
          <Route path="vrv/:code" element={<Checkstand />} />
          <Route path="result/:orderNo" element={<CheckstandResult />} />
        </Route>

        <Route path="weekend-present" element={<BasicLayout white />}>
          <Route path="release/:code" element={<WeekendPresentRelease />} />
        </Route>

        {/* 村民钱包激活 */}
        <Route path="investor-account" element={<BasicLayout needLogin />}>
          <Route index element={<InvestorAccount />} />
          <Route path="result" element={<ActiveVipResult />} />
          <Route path={"update"} element={<InvestorAccount />} />
          <Route path="notfound" element={<VipNotFound />} />
        </Route>

        {/* 公众号迁移，恢复村民数据 */}
        <Route path="investor-account" element={<BasicLayout />}>
          <Route path={"refresh"} element={<VipLogin />} />
          <Route path={"refresh/result"} element={<Result />} />
        </Route>

        {/* 投票 */}
        <Route path={"vote/:id"}>
          <Route index element={<Vote />} />
          <Route path={"signup"} element={<VoteSignup />} />

          <Route path={"description"} element={<VoteNote />} />

          <Route
            path={"participator/:participatorId"}
            element={<ParticipatorView />}
          />

          <Route path={"ranklist"} element={<VoteRankList />} />
        </Route>

        <Route path={"/clear/token"} element={<ClearToken />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
