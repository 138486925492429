import { Card } from "react-vant";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { contactAtom } from "../../store/atoms";
import { isNil } from "lodash";
import { Contact } from "../../entity";
import ContactListSheet from "./ContactListSheet";
import ContactEmptyCell from "./Components/ContactEmptyCell";
import ContactFilledCell from "./Components/ContactFilledCell";
import { FormSheetControl, ListControlContext } from "../../utils/contexts";
import ContactCreateSheet from "./ContactCreateSheet";

const ContactInput: FC<{
  list: Contact[];
  loading: boolean;
  reload: VoidFunction;
}> = ({ list, loading, reload }) => {
  const contact = useRecoilValue(contactAtom);
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const afterSubmit = () => {
    setOpenForm(false);
    reload();
  };

  useEffect(() => {
    reload();
  }, [open]);

  return (
    <Card>
      {isNil(contact) ? (
        <ContactEmptyCell setOpen={setOpen} />
      ) : (
        <ContactFilledCell setOpen={setOpen} />
      )}
      <ListControlContext.Provider
        value={{
          open,
          setOpen,
          list,
          loading,
          fetch: reload,
        }}
      >
        <ContactListSheet setOpenForm={setOpenForm} />
      </ListControlContext.Provider>
      <FormSheetControl.Provider
        value={{
          open: openForm,
          setOpen: setOpenForm,
          afterSubmit,
        }}
      >
        <ContactCreateSheet />
      </FormSheetControl.Provider>
    </Card>
  );
};

export default ContactInput;
