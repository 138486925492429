import { useRecoilValue } from "recoil";
import { SubmitBar, Toast } from "react-vant";
import { carportCreditShouldPaySelector } from "../../../store/selectors";
import "./index.css";
import CarportInfo from "../../../components/_parking/_credit/CarportInfo";
import { useRequest, useTitle } from "ahooks";
import { addCredit } from "../../../requests/parking-credit";
import { carportCreditMonthAtom, parkingCardAtom } from "../../../store/atoms";
import { PayType } from "../../../utils/enum";
import { OrderPayContext } from "../../../components/constant";
import OrderPaySheet from "../../../components/OrderPaySheet";
import { useState } from "react";
import { isNil } from "lodash";
import { TwParkingBody } from "../../../request";

export default function ParkingCredit(): JSX.Element {
  useTitle("车位缴费");
  const [openSheet, setOpenSheet] = useState(false);
  const carportCreditShouldPay = useRecoilValue(carportCreditShouldPaySelector);
  const creditMonth = useRecoilValue(carportCreditMonthAtom);
  const parkingCard = useRecoilValue(parkingCardAtom);
  const orderPayRequest = useRequest(addCredit, {
    manual: true,
  });
  const handleSubmit = () => {
    setOpenSheet(true);
  };

  const paySuccess = (response: TwParkingBody) => {
    Toast.clear();
    location.href = response.codeUrl || "";
  };

  const onPay = async (payType: PayType) => {
    Toast.loading({
      message: "支付中",
      duration: 10000,
    });
    const data = {
      add_month: creditMonth,
      card_id: parkingCard?.card_id || 0,
      pay_type: payType,
    };
    if (isNil(data)) return;
    const response = await orderPayRequest.runAsync(data);
    if (response) {
      // await resolveOrderPay(payType, response.data, (orderNo) => {
      //   paySuccess(response);
      //   Toast.clear();
      // });
    }
  };

  return (
    <div className="p-3">
      <CarportInfo />

      <OrderPayContext.Provider
        value={{
          loading: orderPayRequest.loading,
          price: carportCreditShouldPay / 100,
          openSheet,
          setOpenSheet,
          onPay,
        }}
      >
        <OrderPaySheet />
      </OrderPayContext.Provider>

      <SubmitBar
        disabled={orderPayRequest.loading}
        label="应付金额："
        price={carportCreditShouldPay}
        onSubmit={handleSubmit}
        buttonColor="linear-gradient(to right, #8387ff, #2a30ff)"
        buttonText="缴费"
      />
    </div>
  );
}
