import { CouponScene, CouponType, Gender, PayType } from "./enum";
import { isNil, toNumber } from "lodash";

export function getPayTypeDescription(value: PayType): string {
  switch (value) {
    case PayType.wallet:
      return "钱包支付";
    case PayType.wxpay:
      return "微信支付";
    default:
      return "钱包支付";
  }
}

export function getPaidDescription(value: string | null): string {
  return isNil(value) ? "支付失败" : "支付成功";
}

export function getGenderDescription(value: Gender): string {
  switch (toNumber(value)) {
    case Gender.male:
      return "先生";
    case Gender.female:
      return "女士";
    default:
      return "先生";
  }
}

export function getCouponSceneDescription(value: CouponScene): string {
  switch (value) {
    case CouponScene.parking:
      return "停车缴费";
    case CouponScene.specialCard:
      return "小超人计划";
    case CouponScene.checkstand:
      return "到店付款";
    default:
      return "停车缴费";
  }
}

export function getCouponTypeDescription(value: CouponType): string {
  switch (value) {
    case CouponType.default:
      return "立减券";
    case CouponType.discount:
      return "折扣券";
    case CouponType.super:
      return "全免";
  }
}

export function getCouponUnitDescription(value: CouponType): string {
  switch (value) {
    case CouponType.default:
      return "元";
    case CouponType.discount:
      return "折";
    case CouponType.super:
      return "全免";
  }
}
