import { FC, useEffect, useState } from "react";
import { Merchant } from "../../../entity";
import { ActionSheet, Image, Loading, Space } from "react-vant";
import Cell from "react-vant/es/cell/Cell";
import { CellGroup } from "react-vant/es/cell";
import { getAll } from "../../../requests/merchant";
import { useRequest } from "ahooks";

const PhotoAddressSheet: FC<{
  open: boolean;
  onClose: (merchant?: Merchant) => void;
}> = ({ open, onClose }) => {
  const [merchantList, setMerchantList] = useState<Merchant[]>([]);

  const request = useRequest(getAll, { manual: true });

  const fetchMerchantList = () => {
    request.runAsync().then((response) => {
      setMerchantList(response.data);
    });
  };

  useEffect(() => {
    if (open) fetchMerchantList();
  }, [open]);

  return (
    <ActionSheet
      title={"选择拍摄地点"}
      closeable={false}
      visible={open}
      closeOnClickOverlay
      onCancel={onClose}
      style={{ height: 450 }}
    >
      {request.loading ? (
        <Space block justify="center" align="center" className={"mt-28"}>
          <Loading size={50} />
        </Space>
      ) : (
        <CellGroup>
          {merchantList.map((l: Merchant) => (
            <Cell
              key={l.merchant_name}
              title={l.merchant_name}
              icon={<Image src={l.logo_url} width={30} height={30} />}
              size={"large"}
              onClick={() => onClose(l)}
            />
          ))}
        </CellGroup>
      )}
    </ActionSheet>
  );
};

export default PhotoAddressSheet;
