import { FC } from "react";
import { VoteParticipator } from "../../../entity";
import Flex from "react-vant/es/flex/Flex";
import FlexItem from "react-vant/es/flex/FlexItem";
import { Image, Space, Toast, Typography } from "react-vant";
import Button from "react-vant/es/button/Button";
import { ThumbCircle } from "@react-vant/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { giveVote } from "../../../requests/vote-participator";

const Participator: FC<{ participators: VoteParticipator[] }> = ({
  participators,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGiveVote = (participator: VoteParticipator) => {
    Toast.loading({
      message: "正在投票",
      forbidClick: true,
    });
    giveVote(participator.id, { vote_id: participator.vote_id, count: 1 }).then(
      (response) => {
        Toast({
          type: response.success ? "success" : "fail",
          message: response.message,
        });
      }
    );
  };

  return (
    <Flex wrap={"wrap"} justify={"between"} gutter={10}>
      {participators.map((participator) => (
        <FlexItem key={participator.id} span={12} className={"mb-5"}>
          <Image
            src={participator.photos[0]}
            height={240}
            onClick={() =>
              navigate(`${location.pathname}/participator/${participator.id}`)
            }
          />
          <Space
            direction={"vertical"}
            block
            align={"center"}
            className={"my-3"}
            gap={5}
          >
            <Typography.Text ellipsis>
              {participator.identity}号 {participator.username}
            </Typography.Text>
            <Typography.Text>{participator.receive_vote} 票</Typography.Text>
          </Space>
          <div className={"px-3"}>
            <Button
              onClick={() => handleGiveVote(participator)}
              icon={<ThumbCircle />}
              block
              round
              type={"primary"}
            >
              投票
            </Button>
          </div>
        </FlexItem>
      ))}
    </Flex>
  );
};

export default Participator;
