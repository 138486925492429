import { Button, Flex, Loading, Typography } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import { GoldCoin } from "@react-vant/icons";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { walletRemainAtom } from "../../../store/atoms";
import { useRequest, useTitle } from "ahooks";
import getOne from "../../../requests/user-wallet";
import { useEffect } from "react";
import { isString } from "lodash";
import { fCurrency } from "../../../utils/formatNumber";

export default function Wallet(): JSX.Element {
  useTitle("我的钱包");
  const navigate = useNavigate();
  const [walletRemain, setWalletRemain] = useRecoilState(walletRemainAtom);
  const userWalletRequest = useRequest(getOne, {
    manual: true,
    onSuccess: (data) => setWalletRemain(data.data.remain_amount),
  });
  useEffect(() => {
    if (isString(walletRemain)) userWalletRequest.run();
  }, []);
  return (
    <>
      <Flex
        justify="center"
        align="center"
        direction="column"
        className="pt-16"
      >
        <FlexItem>
          <GoldCoin fontSize={60} className="text-yellow-500" />
        </FlexItem>
        <FlexItem className="mt-6 mb-1">
          <Typography.Text size="md">账户余额</Typography.Text>
        </FlexItem>
        <FlexItem style={{ minHeight: "41px" }}>
          {userWalletRequest.loading ? (
            <Loading size={41} />
          ) : (
            <Flex justify="center" align="center" gutter={3}>
              <FlexItem>
                <strong className="text-xl">¥</strong>
              </FlexItem>
              <FlexItem>
                <strong className="text-4xl">
                  {walletRemain > 0 ? fCurrency(walletRemain) : "0.00"}
                </strong>
              </FlexItem>
            </Flex>
          )}
        </FlexItem>
        <FlexItem className="mt-10">
          <Button
            type="primary"
            className="rv-pay-link"
            onClick={() => navigate("/user/wallet/credit")}
          >
            充值
          </Button>
        </FlexItem>
      </Flex>
    </>
  );
}
