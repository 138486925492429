import {
  ActionBar,
  ActionSheet,
  Card,
  Cell,
  Divider,
  Loading,
  Space,
  Typography,
} from "react-vant";
import { Contact } from "../../entity";
import { useRequest } from "ahooks";
import { destroy, getList } from "../../requests/contact";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import ContactForm from "./Form";
import { CardBody } from "react-vant/es/card/Card";
import { CellGroup } from "react-vant/es/cell";
import { SettingO } from "@react-vant/icons";
import { useSetRecoilState } from "recoil";
import { getContactName } from "../../utils/util";
import { contactAtom } from "../../store/atoms";

export default function ContactList({
  open,
  onClose,
}: {
  open: boolean;
  onClose: VoidFunction;
}): JSX.Element {
  const setContact = useSetRecoilState(contactAtom);
  const [openForm, setOpenForm] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [contactList, setContactList] = useState<Contact[]>([]);
  const fetchRequest = useRequest(getList, {
    manual: true,
    onSuccess: (data) => setContactList(data),
  });
  const onSubmit = () => {
    fetchRequest.run();
    setOpenForm(false);
  };
  const deleteRequest = useRequest(destroy, {
    manual: true,
    onSuccess: (data) => {
      if (data.success) fetchRequest.run();
    },
  });
  const cellClick = (contact: Contact) => {
    if (deleteMode) deleteRequest.run(contact.id);
    else {
      setContact(contact);
      onClose();
    }
  };
  useEffect(() => {
    if (open) fetchRequest.run();
    if (!open || !openForm) setDeleteMode(false);
  }, [open, openForm]);
  return (
    <ActionSheet visible={open} onClickOverlay={onClose}>
      {fetchRequest.loading && (
        <Space
          block
          justify="center"
          align="center"
          className="py-5"
          style={{ minHeight: "520px" }}
        >
          <Loading size={50} />
        </Space>
      )}
      {!fetchRequest.loading && isEmpty(contactList) && (
        <ContactForm onSubmit={onSubmit} />
      )}
      {!fetchRequest.loading && contactList.length > 0 && (
        <Card style={{ minHeight: "520px" }}>
          <CardBody>
            <Space block align="center" justify="between" className="mb-2">
              <Typography.Title level={4} className="mb-0">
                常用联系人
              </Typography.Title>
              <Space align="center" onClick={() => setDeleteMode(!deleteMode)}>
                {deleteMode ? (
                  <Typography.Text>取消</Typography.Text>
                ) : (
                  <Space align="center">
                    <SettingO className="text-gray-520" />
                    <Typography.Text type="secondary">管理</Typography.Text>
                  </Space>
                )}
              </Space>
            </Space>
            <Divider className="my-3" />
            <CellGroup border={false}>
              {contactList.map((contact) => (
                <Cell
                  key={contact.id}
                  center
                  title={getContactName(contact)}
                  label={contact.mobile}
                  value={deleteMode ? "删除" : "选择"}
                  size="large"
                  onClick={() => cellClick(contact)}
                  valueClass={deleteMode ? "text-red-500" : "text-blue-500"}
                />
              ))}
            </CellGroup>
            <ActionBar>
              <ActionBar.Button
                type="primary"
                onClick={() => setOpenForm(true)}
              >
                新增联系人
              </ActionBar.Button>
            </ActionBar>
            <ActionSheet
              visible={openForm}
              onClickOverlay={() => setOpenForm(false)}
            >
              <ContactForm onSubmit={onSubmit} />
            </ActionSheet>
          </CardBody>
        </Card>
      )}
    </ActionSheet>
  );
}
