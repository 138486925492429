import request from "../utils/request";
import {
  AuthResponse,
  AuthTokenParams,
  LoginFormValues,
  Response,
} from "../request";
import { User } from "../entity";

// 获取jwt token
export function getAuthToken(params: AuthTokenParams): Promise<AuthResponse> {
  return request().get("authenticate", {
    params,
  });
}

// 根据token获取用户信息
export function getUserInfo(): Promise<User> {
  return request().get("userinfo");
}

// 短信验证码，4位
export function getMessageCode(mobile: string): Promise<Response> {
  return request().get("sms/getcode", { params: { mobile } });
}

export function login(data: LoginFormValues): Promise<Response> {
  return request().post("/login", data);
}
