import { Empty, Image } from "react-vant";
import svgIcon from "../assets/svg";
import { useTitle } from "ahooks";

export default function NotFound(): JSX.Element {
  useTitle("页面未找到");
  const { StatusNotFound } = svgIcon;
  return (
    <>
      <Empty
        description="抱歉，页面未找到~"
        imageSize={200}
        image={<Image src={StatusNotFound} />}
      />
    </>
  );
}
