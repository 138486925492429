import { Space, Toast, Typography } from "react-vant";
import { getMaskMobile } from "../../../utils/util";
import { getUser, setUser } from "../../../utils/storage";
import UpdateMobileForm from "../../../components/_user/_security/UpdateMobileForm";
import { LoginFormValues } from "../../../request";
import { useRequest, useTitle } from "ahooks";
import updateMobile from "../../../requests/user-security";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function UpdateMobile(): JSX.Element {
  useTitle("更换手机号");
  const navigate = useNavigate();
  const [newMobile, setNewMobile] = useState<string>("");
  const updateMobileRequest = useRequest(updateMobile, {
    manual: true,
    throttleWait: 1000,
    onSuccess: (data) => {
      if (data.success) {
        setUser({ ...getUser(), mobile: newMobile });
        navigate(-1);
        Toast.success("更改成功");
      } else {
        Toast.info({
          message: data.errors?.first || data.message,
          position: "bottom",
        });
      }
    },
  });
  const { mobile } = getUser();
  const handleFormFinish = (values: LoginFormValues) => {
    Toast.loading({
      message: "",
      forbidClick: true,
    });
    setNewMobile(values.mobile);
    updateMobileRequest.run(values);
  };
  return (
    <div className="p-3">
      <Space direction="vertical" block gap={[0, 10]}>
        <Typography.Text type="secondary">
          当前手机号：{mobile ? getMaskMobile(mobile) : "空"}
        </Typography.Text>
        <UpdateMobileForm onFinish={handleFormFinish} />
      </Space>
    </div>
  );
}
