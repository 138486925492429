import { Button, Form, Input, Toast } from "react-vant";
import { LoginFormValues } from "../../../request";
import MessageCountDown from "./MessageCountDown";

export default function UpdateMobileForm({
  onFinish,
}: {
  onFinish: (values: LoginFormValues) => void;
}): JSX.Element {
  const [form] = Form.useForm<LoginFormValues>();
  const onFinishFailed = (errors: any) => {
    Toast.info({
      message: errors.errorFields[0].errors[0],
      position: "bottom",
    });
  };
  const onGetCode = () => {
    return new Promise<{ mobile: string }>((resolve, reject) => {
      form
        .validateFields(["mobile"])
        .then((values: { mobile: string }) => {
          resolve(values);
        })
        .catch((error) => {
          Toast.info({
            message: "手机号格式不正确",
            position: "bottom",
          });
          reject(error);
        });
    });
  };
  return (
    <Form
      form={form}
      showValidateMessage={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      footer={
        <Button type="primary" size="large" round style={{ marginTop: 25 }}>
          确认更换
        </Button>
      }
    >
      <Form.Item
        colon
        rules={[
          { required: true, message: "请输入您的新手机号" },
          { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确" },
        ]}
        name="mobile"
        label="新手机号"
      >
        <Input type="number" placeholder="请输入手机号" />
      </Form.Item>
      <Form.Item
        colon
        rules={[
          { required: true, message: "请输入短信验证码" },
          { len: 4, message: "短信验证码不正确" },
        ]}
        name="code"
        label="验证码"
        suffix={<MessageCountDown onGetCode={onGetCode} />}
      >
        <Input type="number" placeholder="请输入验证码" />
      </Form.Item>
    </Form>
  );
}
