import LicenseInput from "../../../components/_parking/_car/LicenseInput";
import { Toast } from "react-vant";
import { useRequest, useTitle } from "ahooks";
import { create } from "../../../requests/parking-car";
import { useNavigate } from "react-router-dom";

export default function UserParkingCarCreate(): JSX.Element {
  useTitle("常用车牌添加");
  const navigate = useNavigate();
  const createRequest = useRequest(create, {
    manual: true,
    onSuccess: (data) => {
      if (data.success) {
        Toast.success(data.message);
        navigate("/Parking/car", { replace: true });
      } else Toast({ message: data.message, position: "bottom" });
    },
  });
  const onSubmit = (value: string) => {
    Toast.loading({ forbidClick: true });
    createRequest.run({ plate_no: value });
  };
  return (
    <div className="px-5 pt-10">
      <LicenseInput onSubmit={onSubmit} />
    </div>
  );
}
