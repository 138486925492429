import React, { FC, useState } from "react";
import { Image } from "react-vant/lib";
import { Button, Card, Dialog, Space, Toast, Typography } from "react-vant";
import { ActivityBanner, CouponRelease } from "../../entity";
import { useAsyncEffect, useRequest } from "ahooks";
import { findById } from "../../requests/coupon-release";
import { useNavigate, useParams } from "react-router-dom";
import { toNumber } from "lodash";
import {
  afterDate,
  beforeDate,
  getValidIn,
  overLimit,
  soldOut,
} from "./constant";
import { receiveCoupon, receiveCouponCount } from "../../requests/user-coupon";
import { isAfter, isBefore, parseISO } from "date-fns";
import { addDays } from "react-vant/es/datetime-picker/utils";
import {
  getCouponReleaseFastPayBanner,
  getCouponReleaseStickBanner,
} from "../../requests/activity-banner";
import { Placeholder } from "./Placeholder";
import { ParkingPayBanner } from "./Components/ParkingPayBanner";

const CouponReleaseMain: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeBanner, setActiveBanner] = useState<ActivityBanner>();
  const [fastPayBanner, setFastPayBanner] = useState<ActivityBanner>();
  const [receiveCouponCountToday, setReceiveCouponCountToday] = useState(0);
  const [data, setData] = useState<CouponRelease>();
  const request = useRequest(findById, { manual: true });
  const receiveCouponRequest = useRequest(receiveCoupon, { manual: true });
  const receiveCouponCountRequest = useRequest(receiveCouponCount, {
    manual: true,
  });
  const activeBannerRequest = useRequest(getCouponReleaseStickBanner, {
    manual: true,
  });
  const fastPayBannerRequest = useRequest(getCouponReleaseFastPayBanner, {
    manual: true,
  });

  const handleGetBanner = async () => {
    setActiveBanner(await activeBannerRequest.runAsync());
    setFastPayBanner(await fastPayBannerRequest.runAsync());
  };

  const handleReceive = async (value: CouponRelease) => {
    if (!validateReceive(value)) return;
    const response = await receiveCouponRequest.runAsync(value.id);
    if (response.success) {
      Toast.success("领取成功");
      setData({
        ...data,
        remain_count: response.data.remain_count,
      } as CouponRelease);
    } else {
      Toast.fail(response.message);
    }
  };

  const validateReceive = (value: CouponRelease) => {
    if (isBefore(new Date(), parseISO(value.receive_from))) {
      Toast.fail("活动未开始");
      return false;
    }
    if (isAfter(new Date(), addDays(parseISO(value.receive_to), 1))) {
      Toast.fail("活动已结束");
      return false;
    }
    if (value.remain_count <= 0) {
      Toast.fail("手速太慢，券已被抢光~");
      return false;
    }
    if (overLimit(value, receiveCouponCountToday)) {
      Toast.fail(`每日限领${value.get_limit_daily}次`);
      return false;
    }
    return true;
  };

  const handleFetchReceiveCouponCountToday = async (id: number) => {
    setReceiveCouponCountToday(await receiveCouponCountRequest.runAsync(id));
  };

  const handleFetch = async (id: number) => {
    const response = await request.runAsync(id);
    if (response) setData(response);
    else {
      setData(undefined);
      navigate("/404");
    }
  };

  const ActionButton: FC<{ value: CouponRelease }> = ({ value }) => {
    if (beforeDate(value))
      return (
        <Button disabled block type={"primary"} className={"rounded font-bold"}>
          未到活动时间
        </Button>
      );
    if (afterDate(value))
      return (
        <Button disabled block type={"primary"} className={"rounded font-bold"}>
          活动已结束
        </Button>
      );
    if (soldOut(value))
      return (
        <Button disabled block type={"primary"} className={"rounded font-bold"}>
          已被抢完~
        </Button>
      );
    return (
      <Button
        loading={receiveCouponRequest.loading}
        onClick={() => handleReceive(value)}
        disabled={receiveCouponRequest.loading}
        block
        type={"primary"}
        className={"rounded font-bold"}
      >
        一键领取
      </Button>
    );
  };

  useAsyncEffect(async () => {
    if (id) {
      await handleGetBanner();
      await handleFetch(toNumber(id));
      await handleFetchReceiveCouponCountToday(toNumber(id));
    } else navigate("/404");
  }, [id]);

  if (
    request.loading ||
    activeBannerRequest.loading ||
    fastPayBannerRequest.loading
  )
    return <Placeholder />;

  if (!data) {
    return <></>;
  }

  return (
    <>
      <Image
        style={{ height: 240 }}
        src={activeBanner?.image_url}
        onClick={() => {
          activeBanner?.click_url
            ? (location.href = activeBanner?.click_url)
            : null;
        }}
      />
      <div className={"px-5"}>
        <Card round className={"p-3 shadow -mt-5"}>
          <Space block className={"pb-5"} direction={"vertical"} gap={[0, 5]}>
            <Space
              block
              justify={"between"}
              className={"pb-2"}
              align={"center"}
            >
              <Typography.Title className={"font-bold mb-0"} level={4}>
                {data.title}
              </Typography.Title>
              <Typography.Text>
                剩余:{" "}
                <span className={"text-red-500"}>{data.remain_count}</span>
              </Typography.Text>
            </Space>
            <Typography.Text>
              有效期:&nbsp;{getValidIn(data.valid_from, data.valid_to)}
            </Typography.Text>
            <Typography.Text type={"secondary"} size={"sm"}>
              注：本券仅适用于文体汇地下停车场(商业区)
            </Typography.Text>
          </Space>
          <ActionButton value={data} />
        </Card>
        {fastPayBanner && <ParkingPayBanner data={fastPayBanner} />}
      </div>
    </>
  );
};

export default CouponReleaseMain;
