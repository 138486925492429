import {
  ActionBar,
  ActionSheet,
  Card,
  Loading,
  Space,
  Typography,
} from "react-vant";
import { Cross } from "@react-vant/icons";
import { CardBody } from "react-vant/es/card/Card";
import PayTypeCell from "./PayTypeCell";
import { useRecoilState } from "recoil";
import { walletRemainAtom } from "../store/atoms";
import { useRequest } from "ahooks";
import getOne from "../requests/user-wallet";
import { FC, useContext, useEffect, useState } from "react";
import { PayType } from "../utils/enum";
import { OrderPayContext } from "./constant";
import { fCurrency } from "../utils/formatNumber";
import { isNil } from "lodash";

const OrderPaySheet: FC = () => {
  const context = useContext(OrderPayContext);
  const [walletRemain, setWalletRemain] = useRecoilState(walletRemainAtom);
  const [payType, setPayType] = useState<PayType>(PayType.wallet);
  const request = useRequest(getOne, {
    manual: true,
    onSuccess: (data) => setWalletRemain(data.data.remain_amount),
  });

  if (isNil(context)) return <></>;

  const walletDisabled = () =>
    (context.price && context.price > walletRemain) || walletRemain <= 0;

  useEffect(() => {
    if (context.openSheet) request.run();
  }, [context.openSheet]);

  return (
    <ActionSheet
      round={false}
      visible={context.openSheet}
      onCancel={() => context.setOpenSheet(false)}
    >
      {request.loading ? (
        <div
          className="flex items-center w-full justify-center"
          style={{ height: 390 }}
        >
          <Loading className="text-blue-500" size={60} />
        </div>
      ) : (
        <Card style={{ height: 390 }}>
          <CardBody>
            <Space direction="vertical" block gap={35}>
              <Space justify="between" align="center" block>
                <Typography.Text type="secondary">
                  {context.title || "订单支付"}
                </Typography.Text>
                <Typography.Text
                  type="secondary"
                  onClick={() => context.setOpenSheet(false)}
                >
                  <Cross fontSize={18} />
                </Typography.Text>
              </Space>
              <div className="flex items-center justify-center">
                <Typography.Text className="text-xl mb-0 mr-1">
                  ¥
                </Typography.Text>
                <Typography.Text className="text-4xl mb-0 text-black">
                  {context.price && fCurrency(context.price)}
                </Typography.Text>
              </div>
              <PayTypeCell
                disableWallet={walletDisabled()}
                onSelected={setPayType}
              />
            </Space>
            <ActionBar>
              <ActionBar.Button
                disabled={context.loading}
                type="primary"
                className="text-lg"
                onClick={() => context.onPay(payType)}
              >
                {context.loading
                  ? "正在支付"
                  : context.submitText || "确认支付"}
              </ActionBar.Button>
            </ActionBar>
          </CardBody>
        </Card>
      )}
    </ActionSheet>
  );
};

export default OrderPaySheet;
