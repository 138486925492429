import { FC } from "react";
import { Button, Space, Typography } from "react-vant";
import { Checked } from "@react-vant/icons";
import { useNavigate } from "react-router-dom";

const ActivityFormResult: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Space
        direction="vertical"
        align="center"
        className="pt-10 pb-14"
        block
        gap={5}
      >
        <Checked fontSize={65} className="text-blue-500" />
        <Typography.Text size="lg">报名成功</Typography.Text>
      </Space>
      <Space block className={"px-4"} gap={15} direction={"vertical"}>
        <Button
          type={"primary"}
          plain
          block
          size={"large"}
          onClick={() => navigate(-1)}
        >
          返回活动首页
        </Button>
        <Button
          type={"primary"}
          block
          size={"large"}
          onClick={() => navigate("/user/orders")}
        >
          查看我的订单
        </Button>
      </Space>
    </>
  );
};

export default ActivityFormResult;
