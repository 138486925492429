import React, { FC, ReactNode, useEffect, useState } from "react";
import { ActivitySale, Contact, MerchantProject } from "../../entity";
import { useAsyncEffect, useRequest, useSetState } from "ahooks";
import {
  findById as findMerchantProjectById,
  getListByActivitySaleId,
} from "../../requests/merchant-project";
import {
  findById as findActivitySaleById,
  pay,
} from "../../requests/activity-sale";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isNil, take, toNumber } from "lodash";
import {
  ActionBar,
  ActionSheet,
  Button,
  Card,
  Image,
  List,
  Space,
  Tag,
  Toast,
  Typography,
} from "react-vant";
import { CardBody, CardFooter, CardHeader } from "react-vant/es/card/Card";
import SvgPlus from "@react-vant/icons/es/Plus";
import { useRecoilState } from "recoil";
import { contactAtom, userPresentMerchantProjectAtom } from "../../store/atoms";
import MerchantProjectCheckoutPlaceholder from "./Components/MerchantProjectCheckoutPlaceholder";
import { CellGroup } from "react-vant/es/cell";
import Cell from "react-vant/es/cell/Cell";
import { PaginationQueryParams } from "../../request";
import { PayType } from "../../utils/enum";
import { OrderPayContext } from "../../components/constant";
import OrderPaySheet from "../../components/OrderPaySheet";
import { implodedTags, resolveOrderPay } from "../../utils/util";
import { ContactStoreProvider } from "../../components/ContactSelection/store/contact";
import ContactSelectionMain from "../../components/ContactSelection/ContactSelectionMain";
import useStorage from "../../hooks/useStorage";
import { Share_Code } from "../../config/access-keys";

const MerchantProjectCheckout = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [shareCode] = useStorage<string | null>(Share_Code);
  const [searchParams] = useSearchParams();
  const [openSheet, setOpenSheet] = useState(false);
  const [openPaySheet, setOpenPaySheet] = useState(false);
  const [finished, setFinished] = useState(false);
  const [params, setParams] = useSetState<PaginationQueryParams>({
    page: 1,
    take: 8,
    activity_sale_id: searchParams.get("activity_sale_id"),
  });
  const [merchantProjects, setMerchantProjects] = useState<MerchantProject[]>(
    []
  );
  const [activitySale, setActivitySale] = useState<ActivitySale>();
  const [merchantProject, setMerchantProject] = useState<MerchantProject>();
  const [userPresentMerchantProject, setUserPresentMerchantProject] =
    useRecoilState(userPresentMerchantProjectAtom);
  const [presentContact, setPresentContact] = useState<Contact>();
  const [contact, setContact] = useRecoilState(contactAtom);
  const request = useRequest(getListByActivitySaleId);
  const orderPayRequest = useRequest(pay, {
    manual: true,
  });
  const activitySaleRequest = useRequest(findActivitySaleById, {
    manual: true,
  });
  const merchantProjectRequest = useRequest(findMerchantProjectById, {
    manual: true,
  });

  const handleFetch = async (id: number, activitySaleId: number) => {
    setActivitySale(await activitySaleRequest.runAsync(activitySaleId));
    setMerchantProject(
      await merchantProjectRequest.runAsync(activitySaleId, id)
    );
  };

  const handleAddPresentMerchantProject = (value: MerchantProject) => {
    setUserPresentMerchantProject([]);
    setUserPresentMerchantProject([value]);
  };

  const handleRemovePresentMerchantProject = (value: MerchantProject) => {
    setUserPresentMerchantProject(
      userPresentMerchantProject.filter((it) => it.id !== value.id)
    );
  };

  const onLoad = async () => {
    const response = await request.runAsync(params);
    setMerchantProjects((value) => {
      const current = [...value, ...response.data];
      setParams((param) => {
        return { page: param.page + 1 };
      });
      if (current.length >= response.meta.total) setFinished(true);
      return current;
    });
  };

  const validate = (value: ActivitySale) => {
    if (isNil(contact) || (isNil(presentContact) && value.present_count > 0)) {
      Toast({ message: "请选择联系人", position: "bottom" });
      return false;
    }
    if (
      value.present_count > 0 &&
      userPresentMerchantProject.length < value.present_count
    ) {
      Toast({
        message: "请选择附赠课程",
        position: "bottom",
      });
      return false;
    }
    if (
      userPresentMerchantProject.findIndex(
        (it) => it.id === merchantProject?.id
      ) >= 0 &&
      contact.mobile === presentContact?.mobile
    ) {
      Toast({
        message: "同类型课程，不能使用相同联系方式",
        position: "bottom",
      });
      return false;
    }

    return true;
  };

  const onPay = async (
    activitySale: ActivitySale,
    merchantProject: MerchantProject,
    payType: PayType
  ) => {
    Toast.loading({
      message: "支付中",
      duration: 10000,
    });
    const response = await orderPayRequest.runAsync({
      contact_id: contact?.id || 0,
      present_contact_id: presentContact?.id || 0,
      activity_sale_id: activitySale.id,
      merchant_project_id: merchantProject.id,
      share_code: shareCode,
      pay_type: payType,
      present_merchant_project_ids: userPresentMerchantProject.map(
        (it) => it.id
      ),
    });
    if (response.success) {
      await resolveOrderPay(payType, response.data, (orderNo) => {
        paySuccess(orderNo);
      });
    } else Toast.fail(response.message);
  };

  const onSubmit = (activitySale: ActivitySale) => {
    if (validate(activitySale)) {
      setOpenPaySheet(true);
    }
  };

  const paySuccess = (orderNo: string) => {
    Toast.clear();
    navigate(`/activity-sale/merchant-project/checkout/result/${orderNo}`, {
      replace: true,
    });
  };

  const UserMerchantProject: FC<{
    value: MerchantProject;
    suffix?: ReactNode;
    extra?: ReactNode;
  }> = ({ value, extra, suffix }) => {
    const isPresented = !!userPresentMerchantProject.find(
      (it) => it.id == value.id
    );

    return (
      <div
        key={value.id}
        className={
          "flex items-center w-full bg-gray-50 rounded p-2 mb-2 border"
        }
      >
        <Image
          radius={5}
          src={value.figure_url}
          height={80}
          width={80}
          className={"mr-3"}
        />
        <Space block justify={"between"} align={"center"} className={"flex-1"}>
          <Space block direction={"vertical"}>
            <Space align={"center"}>
              <Typography.Text className={"font-bold"} size={"lg"}>
                {value.title}
              </Typography.Text>
              {suffix}
            </Space>

            <Typography.Text type={"secondary"}>
              <div className="divide-x">
                {value.tags &&
                  take(implodedTags(value.tags), 3).map((tag, index) => (
                    <Typography.Text
                      key={tag}
                      className={index < 1 ? "px-2 pl-0" : "px-2"}
                      type="secondary"
                    >
                      {tag}
                    </Typography.Text>
                  ))}
              </div>
            </Typography.Text>
          </Space>
          {extra}
        </Space>
      </div>
    );
  };

  const UserPresentMerchantProject: FC<{ value: MerchantProject }> = ({
    value,
  }) => {
    return (
      <>
        <UserMerchantProject
          value={value}
          suffix={<Tag type={"success"}>活动赠送</Tag>}
          extra={
            <Typography.Text
              type={"danger"}
              size={"lg"}
              onClick={() => handleRemovePresentMerchantProject(value)}
            >
              移除
            </Typography.Text>
          }
        />
      </>
    );
  };

  useAsyncEffect(async () => {
    if (id && searchParams.get("activity_sale_id"))
      handleFetch(toNumber(id), toNumber(searchParams.get("activity_sale_id")));
    else navigate("/404");
  }, [id, searchParams]);

  useEffect(() => {
    return () => {
      setUserPresentMerchantProject([]);
      setContact(null);
    };
  }, []);

  if (merchantProjectRequest.loading || activitySaleRequest.loading)
    return <MerchantProjectCheckoutPlaceholder />;

  if (merchantProject && activitySale)
    return (
      <Space direction={"vertical"} gap={[12, 12]} block>
        <Card>
          <CardHeader>课程信息</CardHeader>
          <CardBody>
            <Space direction={"vertical"} gap={[10, 10]} block>
              <UserMerchantProject value={merchantProject} />
              {userPresentMerchantProject.map((it) => (
                <UserPresentMerchantProject value={it} />
              ))}
            </Space>
          </CardBody>
          {activitySale.present_count > 0 &&
            userPresentMerchantProject.length < activitySale.present_count && (
              <CardFooter>
                <Button
                  type={"info"}
                  block
                  plain
                  icon={<SvgPlus />}
                  className={"text-center py-3"}
                  onClick={() => setOpenSheet(true)}
                >
                  选择附赠课程
                </Button>
              </CardFooter>
            )}
        </Card>
        <Card>
          <ContactStoreProvider>
            <ContactSelectionMain onChange={setContact} />
          </ContactStoreProvider>
          {activitySale.present_count > 0 ? (
            <ContactStoreProvider>
              <ContactSelectionMain
                onChange={setPresentContact}
                title={"联系方式 (附赠课程)"}
              />
            </ContactStoreProvider>
          ) : (
            <></>
          )}
        </Card>
        <Card>
          <CardHeader>活动规则</CardHeader>
          <CardBody>
            <Space direction={"vertical"}>
              <Typography.Text>
                1、
                <Typography.Text type={"danger"} className={"font-bold"}>
                  本次活动仅限新用户(会员)参与
                </Typography.Text>
                ，同类型课程仅允许购买一次
              </Typography.Text>
              <Typography.Text>
                2、购课成功后可享推广返现福利，关注"武汉文体汇"微信公众号，在
                会员中心-我的订单 中查看
              </Typography.Text>
              <Typography.Text>
                3、因本次活动特殊性，一经购买无法退款，敬请谅解
              </Typography.Text>
            </Space>
          </CardBody>
        </Card>
        <ActionBar>
          <ActionBar.Button
            text={
              <Typography.Text type="light" size="lg">
                {`¥${activitySale.current_price / 100} 立即支付`}
              </Typography.Text>
            }
            type="danger"
            onClick={() => onSubmit(activitySale)}
          />
        </ActionBar>
        <OrderPayContext.Provider
          value={{
            loading: orderPayRequest.loading,
            title: "订单支付",
            submitText: "订单支付",
            price: activitySale.current_price / 100,
            openSheet: openPaySheet,
            setOpenSheet: (value) => setOpenPaySheet(value),
            onPay: (payType) => onPay(activitySale, merchantProject, payType),
          }}
        >
          <OrderPaySheet />
        </OrderPayContext.Provider>
        <ActionSheet
          style={{ height: "550px" }}
          cancelText={"取消选择"}
          visible={openSheet}
          onClose={() => setOpenSheet(false)}
        >
          <Typography.Text className={"p-3"} size={"lg"}>
            请选择附赠课程
          </Typography.Text>
          <List
            finished={finished}
            onLoad={onLoad}
            finishedText="加载完毕~"
            className="pb-24"
          >
            <CellGroup>
              {merchantProjects.map((project) => {
                return (
                  <Cell
                    center
                    clickable
                    icon={
                      <Image
                        src={project.figure_url}
                        width={50}
                        height={50}
                        round
                      />
                    }
                    key={project.id}
                    title={
                      <Space>
                        <Typography.Text size={"lg"}>
                          {project.title}
                        </Typography.Text>
                        <Tag plain type={"danger"}>
                          附赠课程
                        </Tag>
                      </Space>
                    }
                    extra={
                      !!userPresentMerchantProject.find(
                        (it) => it.id == project.id
                      ) ? (
                        <Button
                          size={"small"}
                          className={"px-5"}
                          onClick={() => {
                            handleRemovePresentMerchantProject(project);
                          }}
                        >
                          取消加入
                        </Button>
                      ) : (
                        <Button
                          type={"danger"}
                          size={"small"}
                          className={"px-5"}
                          onClick={() => {
                            handleAddPresentMerchantProject(project);
                            setOpenSheet(false);
                          }}
                        >
                          加入列表
                        </Button>
                      )
                    }
                  />
                );
              })}
            </CellGroup>
          </List>
        </ActionSheet>
      </Space>
    );

  return <></>;
};

export default MerchantProjectCheckout;
