import { ActionSheet, Cell } from "react-vant";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  carportCreditActionsSelector,
  defaultCarportSelector,
} from "../../../store/selectors";
import { CarportType } from "../../../utils/enum";
import { floor } from "lodash";

export default function CreditDurationCell({
  open,
  onSelected,
  setOpen,
}: {
  open: boolean;
  onSelected: (duration: number) => void;
  setOpen: (open: boolean) => void;
}): JSX.Element {
  const [selected, setSelected] = useState(0);
  const defaultCarport = useRecoilValue(defaultCarportSelector);
  const actions = useRecoilValue(carportCreditActionsSelector);
  const [selectedText, setSelectedText] = useState("");
  const normalizeSelectedText = (index: number) => {
    const creditBaseMonth =
      defaultCarport?.type === CarportType.monthly ? 3 : 12;
    const currentActionMonth = (index + 1) * creditBaseMonth;
    const rewardMonth =
      currentActionMonth >= 12 ? floor(currentActionMonth / 12) : 0;
    setSelectedText(() => {
      return rewardMonth > 0
        ? `${currentActionMonth}+${rewardMonth}个月`
        : `${currentActionMonth}个月`;
    });
    onSelected(currentActionMonth);
  };
  useEffect(() => {
    normalizeSelectedText(selected);
  }, [selected, defaultCarport]);
  return (
    <>
      <Cell
        title="缴费时长"
        value={selectedText}
        center
        border={false}
        isLink
        onClick={() => setOpen(true)}
        arrowDirection="down"
      />
      <ActionSheet
        visible={open}
        actions={actions}
        closeOnClickAction
        onSelect={(actions, index) => setSelected(index)}
        cancelText="取消"
        onCancel={() => setOpen(false)}
      />
    </>
  );
}
