import {
  ActionSheet,
  Button,
  Card,
  Form,
  Input,
  Radio,
  Space,
  Toast,
  Typography,
} from "react-vant";
import { RadioGroup } from "react-vant/es/radio";
import { Gender } from "../../utils/enum";
import { ContactFormValues } from "../../request";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { useRequest } from "ahooks";
import { create } from "../../requests/contact";
import { Plus } from "@react-vant/icons";
import { FC, useContext, useMemo } from "react";
import { FormSheetControl } from "../../utils/contexts";

const ContactCreateSheet: FC = () => {
  const context = useContext(FormSheetControl);
  const request = useRequest(create, { manual: true, throttleWait: 500 });
  const { male, female } = Gender;
  const [form] = Form.useForm();

  const onFinished = async (values: ContactFormValues) => {
    const response = await request.runAsync(values);
    if (response.success) context?.afterSubmit(values);
    else
      Toast({
        message: response.errors?.first || response.message,
        position: "bottom",
      });
  };

  return useMemo(
    () => (
      <ActionSheet
        visible={context?.open}
        onClickOverlay={() => context?.setOpen(false)}
        onClosed={() => form.resetFields()}
      >
        <Card>
          <CardHeader>常用联系人</CardHeader>
          <CardBody>
            <Form
              initialValues={{ gender: male }}
              showValidateMessage={false}
              form={form}
              onFinish={onFinished}
              footer={
                <Button
                  loading={request.loading}
                  type="primary"
                  block
                  className="mt-10"
                  round
                  onClick={form.submit}
                >
                  <Space align="center">
                    <Plus />
                    <Typography.Text type="light">添加</Typography.Text>
                  </Space>
                </Button>
              }
            >
              <Form.Item
                label="称呼"
                name="username"
                rules={[{ required: true, message: "请填写您的称呼" }]}
              >
                <Input placeholder="请填写您的称呼" clearable />
              </Form.Item>
              <Form.Item label="性别" name="gender">
                <RadioGroup direction="horizontal">
                  <Radio name={male} className="mb-0">
                    先生
                  </Radio>
                  <Radio name={female}>女士</Radio>
                </RadioGroup>
              </Form.Item>
              <Form.Item
                name="mobile"
                label="手机号"
                required
                rules={[
                  { required: true, message: "请输入您的手机号" },
                  { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确" },
                ]}
              >
                <Input
                  type="number"
                  maxLength={11}
                  placeholder="请填写您的手机号"
                />
              </Form.Item>
            </Form>
          </CardBody>
        </Card>
      </ActionSheet>
    ),
    [context?.open, request.loading]
  );
};

export default ContactCreateSheet;
