import { Flex, NavBar, Typography } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import { useTitle } from "ahooks";

interface LoginLayoutProps {
  title: string;
  header: string;
  subHeader: string;
  showNavBar?: boolean;
  onNavBarLeftClick?: () => void;
  children: JSX.Element | JSX.Element[];
}

export default function LoginLayout({
  title,
  header,
  subHeader,
  children,
  showNavBar = false,
  onNavBarLeftClick = () => {},
}: LoginLayoutProps): JSX.Element {
  useTitle(title);
  return (
    <>
      {showNavBar && (
        <NavBar
          leftText="上一步"
          onClickLeft={onNavBarLeftClick}
          border={false}
        />
      )}
      <div
        className={showNavBar ? "container px-6 pt-5" : "container px-6 pt-16"}
      >
        <Flex direction="column" gutter={[0, 10]}>
          <FlexItem>
            <Typography.Text size="xxl" className="font-bold">
              {header}
            </Typography.Text>
          </FlexItem>
          <FlexItem>
            <Typography.Text type="secondary">{subHeader}</Typography.Text>
          </FlexItem>
        </Flex>
        <div className="mt-10">{children}</div>
      </div>
    </>
  );
}
