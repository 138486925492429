import { FC } from "react";
import SwiperItem from "react-vant/es/swiper/SwiperItem";
import { Image } from "react-vant";

const SwipeBanner: FC<{ banners?: string[] }> = ({ banners }) => {
  return (
    <div>
      {(banners || []).map((banner) => (
        <SwiperItem key={banner}>
          <Image lazyload src={banner} />
        </SwiperItem>
      ))}
    </div>
  );
};

export default SwipeBanner;
