import { Button, Cell, Space } from "react-vant";
import { useRequest, useTitle } from "ahooks";
import { CellGroup } from "react-vant/es/cell";
import ResultHead from "../../../components/ResultHead";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { OrderParkingCredit } from "../../../entity";
import { format, parseISO } from "date-fns";
import { carportTypeText } from "../../../utils/util";
import { CarportType } from "../../../utils/enum";
import { findByOrderNo } from "../../../requests/parking-credit";

export default function ParkingCreditResult(): JSX.Element {
  useTitle("充值成功");
  const navigate = useNavigate();
  const { orderNo } = useParams();
  const [orderParkingCredit, setOrderParkingCredit] =
    useState<OrderParkingCredit>();
  const orderRequest = useRequest(findByOrderNo, {
    manual: true,
    onSuccess: (data) => {
      if (data.order) setOrderParkingCredit(data);
    },
  });
  useEffect(() => {
    orderRequest.run(orderNo as string);
  }, []);
  return (
    <>
      <Space className="pt-14" gap={30} block direction="vertical">
        <ResultHead />
        <CellGroup>
          <Cell
            title="项目"
            extra={orderParkingCredit?.order.brief}
            size="large"
          />
          <Cell
            title="车位编号"
            border={false}
            value={`${orderParkingCredit?.lot_name}(${carportTypeText(
              orderParkingCredit?.car_type as CarportType
            )})`}
          />
          <Cell
            title="缴费时长"
            border={false}
            value={`${
              (orderParkingCredit?.renew_duration as number) +
              (orderParkingCredit?.reward_duration as number)
            }个月`}
          />
          <Cell
            title="缴费后有效期"
            border={false}
            value={
              orderParkingCredit?.valid_to &&
              format(parseISO(orderParkingCredit.valid_to), "yyyy-MM-dd")
            }
          />
        </CellGroup>
        <Space
          direction="vertical"
          justify="center"
          gap={10}
          block
          className="px-20"
        >
          <Button
            size="large"
            type="primary"
            onClick={() => navigate("/Parking")}
          >
            完成
          </Button>
        </Space>
      </Space>
    </>
  );
}
