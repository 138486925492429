import { CardBody } from "react-vant/es/card/Card";
import { Button, Card, Flex, Typography } from "react-vant";
import { MerchantProject } from "../../../entity";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function MerchantProjectSubmitBar({
  merchantProject,
}: {
  merchantProject: MerchantProject;
}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <Card className="fixed bottom-0 w-full van-safe-area-bottom shadow">
      <CardBody>
        <Flex gutter={10}>
          <Flex.Item span={12}>
            <Button className={"rounded-xl"} block onClick={() => navigate(-1)}>
              返回
            </Button>
          </Flex.Item>
          <Flex.Item span={12}>
            <Button
              className="bg-red-500 rounded-xl border-red-500"
              block
              onClick={() => {
                navigate(
                  `/activity-sale/merchant-project/checkout/${merchantProject.id}?${searchParams}`
                );
              }}
            >
              <Typography.Text type="light" size="lg">
                立即购买
              </Typography.Text>
            </Button>
          </Flex.Item>
        </Flex>
      </CardBody>
    </Card>
  );
}
