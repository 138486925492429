import { useNavigate } from "react-router-dom";
import { useTitle } from "ahooks";
import { LicenseInputProvider } from "./store/license-input";
import { ParkingLicenseInput } from "./Components/LicenseInput";

export default function ParkingLicenseSearch(): JSX.Element {
  useTitle("临停缴费");
  const navigate = useNavigate();

  const onSubmit = (value: string) => {
    navigate(`/parking/fee/find/${value}`);
  };

  return (
    <div className="mx-5 py-6">
      <LicenseInputProvider>
        <ParkingLicenseInput onSubmit={onSubmit} />
      </LicenseInputProvider>
    </div>
  );
}
