import { CellGroup } from "react-vant/es/cell";
import { Cell, List, Space, Typography } from "react-vant";
import { useRequest, useSetState, useTitle } from "ahooks";
import { detail } from "../../requests/checkstand";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { PaginationMeta, PaginationQueryParams } from "../../request";
import { OrderCheckstand } from "../../entity";
import { isEmpty, isNil } from "lodash";
import { fCurrency } from "../../utils/formatNumber";

export default function CheckstandDetail(): JSX.Element {
  useTitle("门店付款账单");
  const { code } = useParams();
  const [finished, setFinished] = useState(false);
  const [params, setParams] = useSetState<PaginationQueryParams>({
    page: 1,
    take: 8,
  });
  const [meta, setMeta] = useState<PaginationMeta>();
  const [detailList, setDetailList] = useState<OrderCheckstand[]>([]);
  const fetchRequest = useRequest(
    () =>
      detail(
        {
          ...params,
        },
        code || ""
      ),
    {
      manual: true,
    }
  );
  const onLoad = async () => {
    const response = await fetchRequest.runAsync();
    if (isEmpty(response.data)) setFinished(true);
    else {
      if (isNil(meta)) setMeta(response.meta);
      setDetailList((value) => {
        const current = [...value, ...response.data];
        setParams((param) => {
          return { page: param.page + 1 };
        });
        if (current.length >= response.meta.total) setFinished(true);
        return current;
      });
    }
  };
  return (
    <>
      {finished && detailList.length < 1 ? (
        <Space block justify="center" className="p-5" align="center">
          <Typography.Text>暂无付款账单~</Typography.Text>
        </Space>
      ) : (
        <CellGroup title={`累计付款：¥${fCurrency(meta?.summary || 0)}`}>
          <List finished={finished} finishedText="已加载全部" onLoad={onLoad}>
            {detailList.map((detail) => (
              <Cell
                key={detail.id}
                center
                title={detail.receipt_name}
                label={detail.order.paid_at}
                value={
                  <Typography.Text strong size="lg">
                    - {fCurrency(detail.order.total_fee)}
                  </Typography.Text>
                }
              />
            ))}
          </List>
        </CellGroup>
      )}
    </>
  );
}
