import CheckoutItem from "./Checkout/Components/CheckoutItem";
import { Space, Toast } from "react-vant";
import { useMemoizedFn, useRequest, useSafeState, useTitle } from "ahooks";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  contactAtom,
  contactListAtom,
  specialCardAtom,
} from "../../store/atoms";
import { findById, pay } from "../../requests/special-card";
import { FC, useEffect, useState } from "react";
import { first, isEmpty, isNil, toNumber } from "lodash";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CheckoutPlaceholder from "./Checkout/Components/CheckoutPlaceholder";
import ContactInput from "../../components/Contact/ContactInput";
import { Contact, User, UserGroupBuy } from "../../entity";
import useStorage from "../../hooks/useStorage";
import { WTH_USER } from "../../config/access-keys";
import { PayType, Status } from "../../utils/enum";
import useActivityProjectOperation from "../../hooks/useActivityProjectOperation";
import { specialCardPriceSelector } from "../../store/selectors";
import { findByGroupNo, getList } from "../../requests/user-group-buy";
import { SpecialCardCheckoutData } from "../../requests/data";
import { getUnitPrice, resolveOrderPay } from "../../utils/util";
import { GroupActionContext } from "./constant";
import CheckoutGroupBuyAction from "./Checkout/Components/CheckoutGroupBuyAction";
import CheckoutShareGroup from "./Checkout/Components/CheckoutShareGroup";
import GroupBuyPreview from "./GroupBuy/GroupBuyPreview";
import CheckoutDefaultAction from "./Checkout/Components/CheckoutDefaultAction";
import { OrderPayContext } from "../../components/constant";
import OrderPaySheet from "../../components/OrderPaySheet";
import { getList as getContactList } from "../../requests/contact";

const SpecialCardCheckout: FC = () => {
  useTitle("提交订单");
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [userGroupBuys, setUserGroupBuys] = useState<UserGroupBuy[]>([]);
  const [shareGroupBuys, setShareGroupBuys] = useSafeState<UserGroupBuy[]>([]);
  const [contactList, setContactList] =
    useRecoilState<Contact[]>(contactListAtom);
  const [user] = useStorage<User>(WTH_USER);
  const [openSheet, setOpenSheet] = useState(false);
  const [isGroupBuy, setIsGroupBuy] = useState<Status>(Status.inActive);
  const [groupNo, setGroupNo] = useState<string | null>(null);
  const [specialCard, setSpecialCard] = useRecoilState(specialCardAtom);
  const [selectedProject] = useActivityProjectOperation();
  const specialCardPrice = useRecoilValue(specialCardPriceSelector);
  const [contact, setContact] = useRecoilState(contactAtom);
  const orderPayRequest = useRequest(pay, {
    manual: true,
  });
  const shareGroupFetchRequest = useRequest(findByGroupNo, { manual: true });
  const userGroupBuyFetchRequest = useRequest(getList, { manual: true });
  const fetchRequest = useRequest(findById, {
    manual: true,
  });
  const contactListRequest = useRequest(getContactList, { manual: true });

  const handleFetch = async (id: number) => {
    const response = await fetchRequest.runAsync(id);
    if (response) setSpecialCard(response);
    else navigate("/404");
  };

  const handleFetchContactList = async () => {
    const response = await contactListRequest.runAsync();
    setContactList(response);
    setContact(first(response) as Contact);
  };

  const handleFetchUserGroupBuyList = async () => {
    const response = await userGroupBuyFetchRequest.runAsync({
      group_buy_id: toNumber(specialCard?.group_buy.id),
    });
    if (response.data) setUserGroupBuys(response.data);
  };

  const handleFetchShareGroupBuy = async (groupNo: string) => {
    setShareGroupBuys(await shareGroupFetchRequest.runAsync(groupNo));
  };

  const paySuccess = (orderNo: string) => {
    Toast.clear();
    navigate(
      `/activity/special-card/${
        isGroupBuy ? "checkout/group-buy" : "checkout"
      }/result/${orderNo}`,
      {
        replace: true,
      }
    );
  };

  const validate = () => {
    if (isNil(contact)) {
      Toast({ message: "请选择联系人", position: "bottom" });
      return false;
    }
    if (selectedProject.length <= 0) {
      Toast({
        message: "未选择任何项目",
        position: "bottom",
      });
      return false;
    }
    return true;
  };

  const orderPayData = (payType: PayType): SpecialCardCheckoutData | null => {
    if (id && contact)
      return {
        id,
        pay_type: payType,
        contact: contact as Contact,
        projects: selectedProject,
        use_group_buy: isGroupBuy,
        group_no: groupNo,
      };
    return null;
  };

  const onPay = async (payType: PayType) => {
    Toast.loading({
      message: "支付中",
      duration: 10000,
    });
    const data = orderPayData(payType);
    if (isNil(data)) return;
    const response = await orderPayRequest.runAsync(location.search, data);
    if (response.success) {
      await resolveOrderPay(payType, response.data, (orderNo) => {
        paySuccess(orderNo);
      });
    } else Toast.fail(response.message);
  };

  const onSubmit = useMemoizedFn((isGroupBuy = Status.inActive) => {
    if (validate()) {
      setOpenSheet(true);
      setIsGroupBuy(isGroupBuy);
    }
  });

  const onJoinGroup = (value: UserGroupBuy) => {
    if (user.id == value.user_id)
      return Toast({
        message: "您已在队伍中",
        position: "bottom",
      });
    setGroupNo(value.group_no);
    onSubmit(Status.active);
  };

  const onJoinShareGroup = (groupNo: string) => {
    setGroupNo(groupNo);
    onSubmit(Status.active);
  };

  const openGroupBuy = useMemoizedFn(
    () =>
      specialCard?.open_group_buy === Status.active &&
      !isEmpty(specialCard?.group_buy)
  );

  useEffect(() => {
    if (!openSheet) setGroupNo(null);
  }, [openSheet]);

  useEffect(() => {
    if (openGroupBuy()) handleFetchUserGroupBuyList();
  }, []);

  useEffect(() => {
    const shareGroupNo = searchParams.get("group_no");
    if (!isNil(shareGroupNo) && openGroupBuy())
      handleFetchShareGroupBuy(shareGroupNo);
  }, [searchParams]);

  useEffect(() => {
    if (id && isNil(specialCard)) handleFetch(toNumber(id));
  }, [id]);

  useEffect(() => {
    handleFetchContactList();
  }, []);

  if (
    fetchRequest.loading ||
    userGroupBuyFetchRequest.loading ||
    shareGroupFetchRequest.loading
  )
    return <CheckoutPlaceholder />;

  return (
    <>
      <Space direction="vertical" block gap={10}>
        <CheckoutItem />

        <ContactInput
          list={contactList}
          loading={contactListRequest.loading}
          reload={handleFetchContactList}
        />
      </Space>

      {openGroupBuy() ? (
        <GroupActionContext.Provider
          value={{
            loading: userGroupBuyFetchRequest.loading,
            userGroupBuys,
            onSubmit,
            onJoinShareGroup,
            onJoinGroup,
          }}
        >
          <CheckoutGroupBuyAction>
            <CheckoutShareGroup shareGroups={shareGroupBuys} />
          </CheckoutGroupBuyAction>

          <GroupBuyPreview />
        </GroupActionContext.Provider>
      ) : (
        <CheckoutDefaultAction onSubmit={onSubmit} />
      )}

      <OrderPayContext.Provider
        value={{
          loading: orderPayRequest.loading,
          title: isGroupBuy ? "拼团支付" : "订单支付",
          submitText: "拼团支付",
          price: isGroupBuy
            ? getUnitPrice(
                specialCardPrice.currentPrice,
                specialCard?.group_buy.group_price_unit,
                specialCard?.group_buy.group_price_amount
              ) / 100
            : specialCardPrice.currentPrice / 100,
          openSheet,
          setOpenSheet,
          onPay,
        }}
      >
        <OrderPaySheet />
      </OrderPayContext.Provider>
    </>
  );
};

export default SpecialCardCheckout;
