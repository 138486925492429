import { FC, useEffect, useState } from "react";
import Card, { CardBody } from "react-vant/es/card/Card";
import { Space, Toast, Typography } from "react-vant";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { useRequest, useTitle } from "ahooks";
import { findById } from "../../requests/vote";
import { Vote as VoteEntity } from "../../entity";
import VoteTabbar from "../../components/VoteTabbar";
import HTMLReactParser from "html-react-parser";
import SwipeBanner from "../../components/SwipeBanner";

const VoteNote: FC = () => {
  useTitle("活动介绍");
  const { id } = useParams();
  const navigate = useNavigate();
  const [vote, setVote] = useState<VoteEntity | null>(null);

  const request = useRequest(findById, { manual: true });

  const fetchData = () => {
    Toast.loading("");
    request.runAsync(id || "").then((data) => {
      if (data) setVote(data);
      else navigate("/404");
      Toast.clear();
    });
  };

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  return (
    <div className={"min-h-screen bg-gray-100 pb-24"}>
      {!request.loading && vote ? (
        <>
          <SwipeBanner banners={vote.banners} />
          <Card className={"mx-3 mt-3"} round>
            <CardBody>
              <Space block direction={"vertical"} className={"mb-8"}>
                <Typography.Title className={"mb-0"}>活动规则</Typography.Title>
                <Typography.Text>
                  {HTMLReactParser(vote?.rules || "")}
                </Typography.Text>
              </Space>
              <Space block direction={"vertical"}>
                <Typography.Title className={"mb-0"}>活动介绍</Typography.Title>
                <Typography.Text>
                  {HTMLReactParser(vote?.content || "")}
                </Typography.Text>
              </Space>
            </CardBody>
          </Card>
        </>
      ) : (
        <Card style={{ height: 300 }} />
      )}
      <VoteTabbar />
    </div>
  );
};

export default VoteNote;
