import {
  Button,
  Card,
  Cell,
  Flex,
  Image,
  Tag,
  Toast,
  Typography,
} from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import { CardBody } from "react-vant/es/card/Card";
import svgIcon from "../../../assets/svg";
import { Success } from "@react-vant/icons";
import creditAmount from "./values";
import { useState } from "react";
import { useRequest, useTitle } from "ahooks";
import { addCredit } from "../../../requests/user-wallet";
import { handleWxpay, isDev } from "../../../utils/util";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { walletRemainAtom } from "../../../store/atoms";

export default function Credit(): JSX.Element {
  useTitle("钱包充值");
  const { WxPay } = svgIcon;
  const navigate = useNavigate();
  const [walletRemain, setWalletRemain] = useRecoilState(walletRemainAtom);
  const [active, setActive] = useState<number>(0);
  const addCreditRequest = useRequest(addCredit, {
    manual: true,
    onSuccess: async (data) => {
      if (data.success) {
        const payload = data.data;
        const redirect = () => {
          navigate(`/user/wallet/credit/${payload.order_no}`);
        };
        await handleWxpay(payload.wxpay_config)
          .then(() => {
            setWalletRemain(() => {
              return (
                (Number(walletRemain) * 100 +
                  (addCreditRequest.params[0] as number) * 100) /
                100
              );
            });
            redirect();
          })
          .catch((error) => {
            Toast.info({
              message: error,
              position: "bottom",
            });
          });
        Toast.clear();
        if (isDev()) redirect();
      } else {
        Toast.info({
          message: "支付发起失败",
          position: "bottom",
        });
      }
    },
  });
  const handleAddCredit = async () => {
    const creditValue = creditAmount[active];
    Toast.loading("");
    if (creditValue) {
      await addCreditRequest.run(creditValue.value);
    } else {
      Toast.fail("支付参数不正确");
    }
  };
  return (
    <>
      <Flex direction="column">
        <FlexItem>
          <Typography.Text className="p-3 pb-0">充值金额</Typography.Text>
        </FlexItem>
        <FlexItem>
          <div className="p-5">
            <Flex
              justify="between"
              wrap="wrap"
              align="center"
              gutter={[20, 20]}
            >
              {creditAmount.map((credit, index) => (
                <FlexItem key={credit.id} span={12}>
                  <Card
                    border
                    round
                    className={active === index ? "rv-active-credit-card" : ""}
                    onClick={() => setActive(index)}
                  >
                    <CardBody>
                      <Flex direction="column" align="center" className="py-2">
                        <FlexItem>
                          <Typography.Text strong size="xl">
                            {credit.value}元
                          </Typography.Text>
                        </FlexItem>
                        <FlexItem>
                          <Typography.Text type="danger" strong>
                            到账{credit.value + credit.rewardValue}元
                          </Typography.Text>
                        </FlexItem>
                      </Flex>
                    </CardBody>
                  </Card>
                </FlexItem>
              ))}
            </Flex>
          </div>
        </FlexItem>
      </Flex>
      <Flex direction="column">
        <FlexItem>
          <Typography.Text className="p-3">支付方式</Typography.Text>
        </FlexItem>
        <FlexItem>
          <Cell
            center
            className="rv-paytype-cell"
            icon={<Image src={WxPay} width={26} height={26} />}
            clickable
            title={
              <>
                <Typography.Text className="mr-1" size="lg">
                  微信支付
                </Typography.Text>
                <Tag type="danger" plain>
                  推荐
                </Tag>
              </>
            }
            titleClass="ml-3"
            rightIcon={<Success className="text-green-500" fontSize={24} />}
          />
        </FlexItem>
      </Flex>
      <div className="rv-credit-button-wrap">
        <Button
          disabled={addCreditRequest.loading}
          block
          type="primary"
          size="large"
          onClick={() => handleAddCredit()}
        >
          立即充值
        </Button>
      </div>
    </>
  );
}
