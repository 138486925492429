import {
  Button,
  Card,
  Cell,
  Flex,
  Image,
  NoticeBar,
  Space,
  Tag,
  Toast,
  Typography,
} from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { useParams } from "react-router-dom";
import { useRequest, useTitle } from "ahooks";
import { getOne, receivePresent } from "../../../requests/user-present";
import { useEffect, useState } from "react";
import { WeekendPresentEntity, UserWeekendPresent } from "../../../entity";
import { CellGroup } from "react-vant/es/cell";
import svgIcon from "../../../assets/svg";
import FlexItem from "react-vant/es/flex/FlexItem";
import "./index.css";
import moment from "moment";
import { InfoO, UnderwayO } from "@react-vant/icons";

export default function PresentView(): JSX.Element {
  useTitle("福利详情");
  const params = useParams();
  const [userPresent, setUserWeekendPresent] = useState<UserWeekendPresent>({
    code: "",
    entities: [] as WeekendPresentEntity[],
    used_at: null,
    valid_to: "",
    valid_from: "",
  } as UserWeekendPresent);
  const { PresentCheckDecagram } = svgIcon;
  const presentViewRequest = useRequest(() => getOne(Number(params.id) || 0), {
    manual: true,
    onSuccess: (data) => {
      setUserWeekendPresent(data);
      Toast.clear();
    },
  });
  const receivePresentRequest = useRequest(receivePresent, {
    manual: true,
    onSuccess: (data) => {
      if (data.success) {
        Toast.success(data.message);
        presentViewRequest.run();
      } else {
        Toast.clear();
      }
    },
  });
  const beforeDate = moment().isBefore(userPresent.valid_from);
  const overDate = moment().subtract(1, "day").isAfter(userPresent.valid_to);
  const receiveState = (): JSX.Element | string => {
    if (userPresent?.used_at) {
      return "已领取";
    }
    if (overDate) {
      return "失效";
    }
    return (
      <Tag plain type="warning">
        未领取
      </Tag>
    );
  };
  const receiveButton = (): JSX.Element => {
    if (overDate || userPresent.used_at) return <span />;
    if (beforeDate)
      return (
        <Button
          type="primary"
          size="large"
          disabled
          plain
          icon={<UnderwayO />}
          className="rv-use-present-button"
        >
          未到活动时间
        </Button>
      );
    return (
      <Button
        loading={receivePresentRequest.loading}
        disabled={receivePresentRequest.loading}
        type="primary"
        size="large"
        onClick={() => receivePresentRequest.run(userPresent.id)}
        className="rv-use-present-button"
      >
        已到店，立即领取
      </Button>
    );
  };
  useEffect(() => {
    Toast.loading("");
    presentViewRequest.run();
  }, [params.id]);
  return (
    <>
      <div>
        {userPresent && userPresent?.remark && (
          <NoticeBar leftIcon={<InfoO />} text={userPresent?.remark} />
        )}
      </div>
      <Space direction="vertical" block gap={10} className="p-3">
        <Card style={{ minHeight: 115 }} round>
          <CardHeader>{userPresent?.title}</CardHeader>
          <CardBody>
            {userPresent?.entities?.map((item: WeekendPresentEntity) => (
              <Cell
                key={item.id}
                icon={
                  <Image src={item?.thumbnail_url} width={35} height={35} />
                }
                titleClass="ml-2"
                title={item.name}
                extra="x1"
              />
            ))}
          </CardBody>
        </Card>

        <Card round>
          <CardHeader>礼品信息</CardHeader>
          <CardBody>
            <CellGroup border={false}>
              <Cell
                title="提供商户"
                border={false}
                extra={
                  <Flex gutter={10}>
                    <FlexItem>
                      <Image
                        round
                        width={25}
                        height={25}
                        src={userPresent.logo_url}
                      />
                    </FlexItem>
                    <FlexItem>{userPresent.merchant_name}</FlexItem>
                  </Flex>
                }
              />
              <Cell title="领取地址" border={false} extra="1F" />
              {userPresent?.code && (
                <Cell title="礼品码" border={false} extra={userPresent.code} />
              )}
              <Cell
                title="礼品有效期"
                border={false}
                extra={`${userPresent?.valid_from} 至 ${userPresent?.valid_to}`}
              />
              {userPresent?.used_at && (
                <Cell
                  title="领取时间"
                  border={false}
                  extra={userPresent.used_at}
                />
              )}
              <Cell title="状态" border={false} extra={receiveState()} />
            </CellGroup>
          </CardBody>
        </Card>
        {userPresent.used_at ? (
          <Card round className="mb-20">
            <CardBody>
              <Flex align="center">
                <FlexItem className="mr-5">
                  <Image src={PresentCheckDecagram} width={40} height={40} />
                </FlexItem>
                <FlexItem>
                  <Typography.Text>编号：#</Typography.Text>
                </FlexItem>
                <FlexItem>
                  <span className="text-3xl font-bold">
                    {userPresent?.version}
                  </span>
                </FlexItem>
              </Flex>
            </CardBody>
          </Card>
        ) : (
          <div className="mb-20" />
        )}
      </Space>

      {presentViewRequest.loading ? (
        <span />
      ) : (
        <Flex justify="center">
          <FlexItem>{receiveButton()}</FlexItem>
        </Flex>
      )}
    </>
  );
}
