import { Input, Flex, Typography } from "react-vant";
import FlexItem from "react-vant/es/flex/FlexItem";
import RemarkInput from "./RemarkInput";
import { useState } from "react";
import { isEmpty } from "lodash";
import { Clear } from "@react-vant/icons";
import FormItem from "react-vant/es/form/FormItem";

export default function CheckstandInput({
  payMoney,
  remark,
  setRemark,
}: {
  payMoney: string;
  remark: string;
  setRemark: (value: string) => void;
}): JSX.Element {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <div className="mx-2 bg-white pt-3 pb-2 px-3 rounded">
        <Flex justify="between" align="center">
          <FlexItem>
            <Typography.Text>支付金额</Typography.Text>
          </FlexItem>
          <FlexItem>
            <Typography.Link onClick={() => setOpenDialog(true)}>
              添加备注
            </Typography.Link>
          </FlexItem>
        </Flex>
        <FormItem
          className="money-field"
          label={<Typography.Text size={"xl"}>¥</Typography.Text>}
        >
          <Input autoFocus readOnly value={payMoney} />
        </FormItem>
        <RemarkInput
          open={openDialog}
          defaultRemark={remark}
          onClose={() => setOpenDialog(false)}
          handleConfirm={(value) => {
            setRemark(value);
            setOpenDialog(false);
          }}
        />
      </div>
      {!isEmpty(remark) && (
        <Typography.Text type="secondary" className="p-3 rv-multi-ellipsis--l3">
          备注: {remark}
          <Clear
            style={{ width: 16, height: 16, marginBottom: 5 }}
            className="inline-block ml-2"
            onClick={() => setRemark("")}
          />
        </Typography.Text>
      )}
    </>
  );
}
